import React from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@material-ui/core';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  setSidebarToggle,
  setSidebarToggleMobile
} from 'reducers/ThemeOptions';

import projectLogo from 'assets/images/react.svg';

const SidebarHeader = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const {
    userInfo,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    sidebarToggle,
    setSidebarToggle
  } = props;

  return (
    <>
      <div className="app-sidebar--header">
        <div className="app-sidebar-logo">
          <NavLink
            to="/dashboard"
            title={
              userInfo && userInfo.company
                ? userInfo.company.companyName
                : 'company'
            }
            className="app-sidebar-logo">
            <div className="app-sidebar-logo--icon">
              <img
                alt={
                  userInfo && userInfo.company
                    ? userInfo.company.companyName
                    : 'company logo'
                }
                src={
                  userInfo && userInfo.company
                    ? userInfo.company.cmpyLogo.url
                    : projectLogo
                }
              />
            </div>
            {userInfo && userInfo.company && userInfo.company.companyName && (
              <div className="app-sidebar-logo--text">
                <span>Company</span>

                <b>{userInfo.company.companyName}</b>
              </div>
            )}
          </NavLink>
        </div>
        <Tooltip
          title="Collapse sidebar"
          placement="right"
          arrow
          classes={{ tooltip: 'tooltip-kargo-blue' }}>
          <Button
            onClick={toggleSidebar}
            className="btn btn-sm collapse-sidebar-btn">
            <FontAwesomeIcon icon={['far', 'dot-circle']} size="lg" />
          </Button>
        </Tooltip>
        <Button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
        <Tooltip
          title="Expand sidebar"
          placement="right"
          arrow
          classes={{ tooltip: 'tooltip-kargo-blue' }}>
          <Button
            onClick={toggleSidebar}
            className="expand-sidebar-btn btn btn-sm">
            <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user.info,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
