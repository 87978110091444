import { getBaseUrl } from '../config/config';
import { HttpMethods, HttpService } from './index';

export default class ForwarderService {









    static createForwarder(data) {
        const url = 'user/forwarder-registration';
        return HttpService.request(HttpMethods.POST, url, data)
            .then((res) => {
                // console.log('createUser SRVICE resp', res);
                return res;
            })
            .catch((err) => console.error(err));
    }

    static verifyForwarderEmail(data) {
        const url = 'user/verify-forwarder-registration';
        return HttpService.request(HttpMethods.POST, url, data)
            .then((res) => {
                // console.log('createUser SRVICE resp', res);
                return res;
            })
            .catch((err) => console.error(err));
    }

    static async submit_kyc_documents(id,formData) {
        const baseURL = getBaseUrl()

        const url = `${baseURL}user/upload-documents-for-kyc?id=${id}`;
        try {
            const response = await fetch(`${url}`, {
              method: 'PUT',
              body: formData,
            });
            return response;
          } catch (error) {
            console.error('Error fetching data:', error);
            return error;
          }


        // return HttpService.requestFormData(HttpMethods.PUT, url, data)
        //     .then((res) => {
        //         // console.log('createUser SRVICE resp', res);
        //         return res;
        //     })
        //     .catch((err) => console.error(err));
    }



















    static createRateSheet(id,data) {
        const url = `forwarder/create-rate-sheet?forwarder_id=${id}`;
        return HttpService.request(HttpMethods.POST, url, data)
            .then((res) => {
                return res;
            })
            .catch((err) => console.error(err));
    }

    static fetchRateSheet(id) {
        const url = `forwarder/fetch-rate-sheet?id=${id}`;
        return HttpService.request(HttpMethods.GET, url)
            .then((res) => {
                return res;
            })
            .catch((err) => console.error(err));
    }

    static createPriceBreakDown(data) {
        const url = `forwarder/upload-rate-sheet`;
        return HttpService.request(HttpMethods.POST, url,data)
            .then((res) => {
                return res;
            })
            .catch((err) => console.error(err));
    }

    static deleteRateSheet(id) {
        const url = `forwarder/delete-rate-sheet?id=${id}`;
        return HttpService.request(HttpMethods.DELETE, url,)
            .then((res) => {
                return res;
            })
            .catch((err) => console.error(err));
    }

    static editRateSheet(id,data) {
        const url = `forwarder/edit-rate-sheet?id=${id}`;
        return HttpService.request(HttpMethods.PUT, url,data)
            .then((res) => {
                return res;
            })
            .catch((err) => console.error(err));
    }

    static updatePriceBreakDown(data) {
        const url = `forwarder/update-price-breakdown`;
        return HttpService.request(HttpMethods.PUT, url,data)
            .then((res) => {
                return res;
            })
            .catch((err) => console.error(err));
    }

















    static  async fetch_custom_shipping_lead(id,page,token,data) {
          const baseURL = getBaseUrl();
        const url = `${baseURL}tool/fetch-custom-shipping-lead?id=${id}&page=${page}`;
         const response = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type":"application/json"
            },
            body:JSON.stringify(data)
        })

         const result = await response.json();
         return result;
        // return HttpService.request(HttpMethods.GET, url)
        //     .then((res) => {
        //         return res;
        //     })
        //     .catch((err) => console.error(err));
    }


    static async make_offer(data,token) {
        // const url = `forwarder/send-proposal`;
        // return HttpService.request(HttpMethods.POST, url, data)
        //     .then((res) => {
        //         return res;
        //     })
        //     .catch((err) => console.error(err));
        const url = `${getBaseUrl()}tool/send-proposal`
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type":"application/json"
            },
            body:JSON.stringify(data)
        })
        const result = await response.json()
        return result
    }

    static fetch_proposal(id) {
        const url = `forwarder/fetch-proposal?id=${id}`;
        return HttpService.request(HttpMethods.GET, url)
            .then((res) => {
                return res;
            })
            .catch((err) => console.error(err));
    }

    static fetch_proposal_by_id(id) {
        const url = `forwarder/fetch-single-proposal?id=${id}`;
        return HttpService.request(HttpMethods.GET, url)
            .then((res) => {
                window.alert("hello",res)
                return res;
            })
            .catch((err) => console.error(err));
    }







    static fetch_shipement_forwarder(id, status,shipment_id) {
        const url = `shippment/fetch-shipment-forwarder?id=${id}&status=${status}&shipment_id=${shipment_id}`;
        return HttpService.request(HttpMethods.GET, url)
            .then((res) => {
                // window.alert("hello",res)
                return res;
            })
            .catch((err) => console.error(err));
    }



    static create_document(data) {
        const url = `forwarder/create-document`;
        return HttpService.request(HttpMethods.POST, url,data)
            .then((res) => {
                // window.alert("hello",res)
                return res;
            })
            .catch((err) => console.error(err));
    }

    static fetch_documents(id) {
        const url = `forwarder/fetch-document?forwarder_id=${id}`;
        return HttpService.request(HttpMethods.GET, url)
            .then((res) => {
                // window.alert("hello",res)
                return res;
            })
            .catch((err) => console.error(err));
    }



    static saveService(data) {
        const url = `forwarder/service/create`;
        return HttpService.request(HttpMethods.POST, url,data)
            .then((res) => {
                // window.alert("hello",res)
                return res;
            })
            .catch((err) => console.error(err));
    }

    static async fetchService(id) {
        const url = `${getBaseUrl()}forwarder/service/fetch?id=${id}`;
        const res = await fetch(url)
        const result = await res.json()
        return result
        // return HttpService.request(HttpMethods.GET, url)
        //     .then((res) => {
        //         // window.alert("hello",res)
        //         return res;
        //     })
        //     .catch((err) => console.error(err));
    }
   
    static async fetchNotes(id, type) {
        const url = `${getBaseUrl()}shippment/fetch-shippment-note?id=${id}&type=${type}`;
        const res = await fetch(url)
        const result = await res.json()
        return result
    }
    static async fetchShipmentStatus(id) {
        const url = `${getBaseUrl()}shippment/fetch-shippment-status?id=${id}`;
        const res = await fetch(url)
        const result = await res.json()
        return result
    }
    static async fetch_shipment_documents(id, token) {
        const url = `${getBaseUrl()}shippment/fetch-shippment-documents?id=${id}`;
        const res = await fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        const result = await res.json()
        return result
    }

    static async checkReviewGiven(id,token) {
        const url = `${getBaseUrl()}shippment/rating/check?shipment_id=${id}`;
        const res = await fetch(url, {
            method: "GET",
            headers: {
                "Authorization":"Bearer " + token
            },
        })
        const result = await res.json()
        return result
    }


}
