const screenHeight = window.screen.height;
const screenWidth = window.screen.width;
const windowWidth = (screenWidth * 80) / 100;
const windowHeight = (screenHeight * 80) / 100;


export const handlePreview = (url, type) => {
  // const newTab = window.open(`/preview/?url=${url}?type=${type}`, '_blank');
  const newTab = window.open(url, '_blank')
  if (newTab) {
    newTab.focus();
  } else {
    alert('Pop-ups are blocked by your browser. Please allow pop-ups for this site.');
  }
}

export const handlePreviewImage = (url, title) => {
  const newWindow = window.open(url, '_blank', 'width=600, height=400, left=200, top=200, location=no, toolbar=no, status=no, menubar=no');

  if (newWindow) {
    newWindow.document.write(
      `<html>
        <head>
          <title>${title}</title>
          <style>
            body {
              background-color: #f0f0f0;
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
            }
            img {
              max-width: 100%;
              max-height: 100%;
              min-height: 100px;
              min-width: 100px;
            }
          </style>
        </head>
        <body>
          <img src="${url}" alt="Preview Image">
        </body>
      </html>`
    )
  } else { window.alert("Unable to open preview") }
}


export const handleDocumentPreview = (url) => {
  window.open(url, '_blank');
  // const newWindow = window.open(url, '_blank', `width=${windowWidth}, height=${windowHeight}, left=50, top=50, location=no, toolbar=no, status=no, menubar=no`);
  // if (!newWindow) {
  //     window.alert("Unable to open preview")
  // }
}


export const showPreview = (file) => {
  if (typeof file === 'string') {
      return file;
  } else {
      return URL.createObjectURL(file);
  }
};