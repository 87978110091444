import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';


export default class AdminServices {


  static async saveDetailsForwarder(id, data, token) {
    const url = `admin/forwarder/save-forwarder-details?id=${id}`

     return httpService(HttpMethods.POST, url, data, {
       token,
       type: 'forwarder',
       method: 'edit',
       platform: 'Admin'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
  
  }




  static async get_all_forwarders_kyc_verification_status(data, token,page) {
    const url = `admin/forwarder/getall?page=${page}`

       return httpService(HttpMethods.POST, url, data, {
         token,
         type: 'forwarder',
         method: 'read',
         platform: 'Admin'
       })
         .then((res) => {
           return res;
         })
         .catch((err) => console.log(err));

  }
  static get_single_forwarders_kyc_verification_status(id, token) {
    const url = `admin/forwarder/get-forwarder-with-kyc-status?id=${id}`
     return httpService(HttpMethods.GET, url, null, {
       token,
       type: 'forwarder_managment',
       method: 'read',
       platform: 'Admin'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
  
  }

  static approve_kyc(data, token) {
    const url = `admin/forwarder/kyc-approve`
     return httpService(HttpMethods.PUT, url, data, {
       token,
       type: 'forwarder',
       method: 'edit',
       platform: 'Admin'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
   
  }

  static reject_kyc(data, token) {
    const url = `admin/forwarder/kyc-reject`
      return httpService(HttpMethods.PUT, url, data, {
        token,
        type: 'forwarder',
        method: 'edit',
        platform: 'Admin'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
 
  }

  static suspend_account(id, data,token) {
    const url = `admin/forwarder/suspend-account`
      return httpService(HttpMethods.PUT, url, data, {
        token,
        type: 'forwarder',
        method: 'edit',
        platform: 'Admin'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
   
  }

  static reject_and_delete_account(data, token) {
    const url = `admin/forwarder/reject-delete-account`
      return httpService(HttpMethods.PUT, url, data, {
        token,
        type: 'forwarder',
        method: 'edit',
        platform: 'Admin'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
   
  }

  static update_forwarder_account_status(data, token) {
    const url = `admin/forwarder/update-forwarder-account-status`

      return httpService(HttpMethods.PUT, url, data, {
        token,
        type: 'forwarder',
        method: 'edit',
        platform: 'Admin'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
   
  }

  static fetchForwarderByAccountStatus(status, token) {
    const url = `admin/forwarder/fetch-by-account-status?status=${status}`
    
    return HttpService.request(HttpMethods.GET, url, {}, token).then(
      (res) => res
    );
  }

  static async fetchForwarderShipments(data, token) {
    const url = `admin/forwarder/fetch-shipments`

     return httpService(HttpMethods.POST, url, data, {
       token,
       type: 'forwarder_managment',
       method: 'shipments',
       platform: 'Admin'
     }).then((res) => {
       return res;
     });
   
   
  }

  static fetchForwarderTeam(id,cmpyId, token) {
    const url = `admin/forwarder/fetch-team-members?id=${id}&cmpyId=${cmpyId}`

      return httpService(HttpMethods.GET, url, null, {
        token,
        type: 'forwarder_managment',
        method: 'team',
        platform: 'Admin'
      }).then((res) => {
        return res;
      });
  
  }



  static async fetchForwarderTransactions(data, token) {
    const url = `admin/forwarder/fetch-transactions`

      return httpService(HttpMethods.POST, url, data, {
        token,
        type: 'forwarder_managment',
        method: 'transactions',
        platform: 'Admin'
      }).then((res) => {
        return res;
      });
   
   
  }
}

