import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  MenuItem,
  Tooltip,
  ButtonGroup
} from '@material-ui/core';
import CachedIcon from '@mui/icons-material/Cached';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import GridOnTwoToneIcon from '@material-ui/icons/GridOnTwoTone';
import clsx from 'clsx';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PageContent from 'sites/shipper/components/layout/PageContent';
import { TrackingSourcesService, CarrierUnitNamesService } from 'service';
import { notify } from 'sites/shipper/components/Toast/toast';
import CreateTransportUnitName from './CreateCarrierVessels/CreateCarrierVessels';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import ConfirmDialog from '../../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import CreateTrackingSources from './CreateTrackingSources/CreateTrackingSources';

/* eslint-disable */
const TrackingSources = ({ tab, carrier_id }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.session.auth);
  const transportLineInfo = useSelector(
    (state) => state.transportUnitNames.transport_lines_info
  );
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [run, setRun] = useState(false);

  const [searchKeyWord, setSearchKeyWord] = useState('');

  useEffect(() => {
    if (!transportLineInfo || transportLineInfo?.id !== id) {
      history.replace('/transport-lines');
    }
  }, [transportLineInfo]);

  //   1. transportation_line : TranportationLines
  // 2. source : String
  // 3. track_container : Boolean
  // 4. track_mbl : Boolean
  // 5. tracking_booking : Boolean
  // 6. created_by : kargoAdmin
  // 7. last_updated_by : kargoAdmin
  const [details, setDetails] = useState({
    source: '',
    track_container: false,
    track_mbl: false,
    tracking_booking: false
  });

  const [CarrierTrackingSources, setCarrierTrackingSources] = useState([]);

  const [carrierTrackingCount, setCarrierTrackingCount] = useState(0);

  const searchParams = new URLSearchParams(location.search);
  const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(parseInt(pageValue));
  const itemsPerPage = 15;

  const [check, setCheck] = useState(true);

  // useEffect(() => {
  //   if (loading) return;

  //   const element = document.getElementById('top');
  //   element.scrollIntoView({ behavior: 'smooth' });
  // }, [check]);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/carriers/${carrier_id}?tab=${tab}&page=${value}`);
  }

  useEffect(() => {
    setLoading(true);

    TrackingSourcesService.fetchCarrierTrackingSourceData(
      id,
      auth.token,
      searchKeyWord,
      page
    ).then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setCarrierTrackingSources(res.data);
        setCarrierTrackingCount(res?.unitNamesCount)
      }
      setLoading(false);
    });
  }, [run, searchKeyWord]);

  const [error, setError] = useState();

  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [moreOptionClicked, setMoreOptionClicked] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const handleCreateCarrierTrackingSource = () => {
    const data_to_send = {
      carrier_id: id,
      source: details.source,
      track_container: details.track_container,
      track_mbl: details.track_mbl,
      tracking_booking: details.tracking_booking,

      accountId: auth.accountId
    };
    TrackingSourcesService.createCarrierTrackingSource(
      data_to_send,
      auth.token
    ).then((res) => {
      if (res.error) {
        setError(res.error);
      } else {
        notify(dispatch, 'success', res.message);
        setOpen(false);
        setDetails({
          source: '',
          track_container: false,
          track_mbl: false,
          tracking_booking: false
        });
        setRun(!run);
      }
    });
  };

  const handleEditCarrierTrackingSource = () => {
    const data_to_send = {
      id: moreOptionClicked._id,

      source: details.source,
      track_container: details.track_container,
      track_mbl: details.track_mbl,
      tracking_booking: details.tracking_booking,

      accountId: auth.accountId
    };
    TrackingSourcesService.updateCarrierTrackingSource(
      data_to_send,
      auth.token
    ).then((res) => {
      if (res.error) {
        setError(res.error);
      } else {
        notify(dispatch, 'success', res.message);
        setOpen(false);
        setDetails({
          source: '',
          track_container: false,
          track_mbl: false,
          tracking_booking: false
        });

        setMoreOptionClicked(null);
        setRun(!run);
      }
    });
  };

  const handleDeleteCarrierTrackingSource = () => {
    if (!moreOptionClicked._id) {
      return;
    }
    TrackingSourcesService.deleteCarrierTrackingSource(
      moreOptionClicked._id,
      auth.token,
      auth.accountId
    ).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.message);
      } else {
        notify(dispatch, 'success', res.message);
        setMoreOptionClicked(null);
        setOpenMoreOptions(null);
        setRun(!run);
      }
    });
  };

  const TableHeading = [
    'S.No.',
    'Source',
    'Track Container',
    'Track MBL',
    'Tracking Booking',
    'Last Updated By',
    'Action'
  ];

  const Keys = [
    'index',
    'source',
    'track_container',
    'track_mbl',
    'tracking_booking',
    'last_updated_by',
    '_id'
  ];

  const TableData = CarrierTrackingSources?.map((item, index) =>
    Keys.map((Key) => {
      if (Key === 'index') {
        return index + 1;
      }

      if (Key === 'source') {
        return item[Key].name;
      }

      if (
        Key === 'track_container' ||
        Key === 'track_mbl' ||
        Key === 'tracking_booking'
      ) {
        return item[Key] ? 'Yes' : 'No';
      }
      if (Key === '_id') {
        return (
          <>
            <div className="position-relative">
              <center>
                <Tooltip title="More" arrow>
                  <Button
                    variant="text"
                    className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
                    onClick={(e) => {
                      setOpenMoreOptions(e.currentTarget);
                      setMoreOptionClicked(item);
                    }}>
                    <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                  </Button>
                </Tooltip>
              </center>
              {moreOptionClicked?._id === item?._id && (
                <SmallPopupWrapper
                  open={openMoreOptions}
                  onClose={() => {
                    setMoreOptionClicked(null);
                    setOpenMoreOptions(null);
                  }}>
                  <MenuItem
                    onClick={() => {
                      setOpen(true);
                      setOpenMoreOptions(null);
                    }}>
                    Edit
                  </MenuItem>
                  {/* <MenuItem onClick={() => { toggleRightDrawer(true); setOpenMoreOptions(null); }}>Edit Route</MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      setOpenMoreOptions(null);

                      setConfirmDialogOpen(true);
                    }}>
                    Delete
                  </MenuItem>
                </SmallPopupWrapper>
              )}
            </div>
          </>
        );
      } else {
        return item[Key] || '';
      }
    })
  );
  const [open, setOpen] = useState(false);
  return (
    <div>
      {/* {loading && <LoaderComponent loading={loading} />} */}
      {/* <PageContent> */}
      <TableWithSearchBar
        // animatedSearchbar={{
        //   show: true,
        //   value: searchKeyWord,
        //   onChange: (e) => setSearchKeyWord(e.target.value)
        // }}
        tableMainHeading={"Tracking Source"}
        loading={loading}
        TableHeading={TableHeading}
        TableBody={TableData}
        TableGrid={'repeat(7,1fr)'}
        actionButtons={[
          <Button
            variant="text"
            onClick={() => setRun(!run)}
            className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
            <CachedIcon className="w-50" />
          </Button>,
          <Button
            className={`btn-primary`}
            onClick={() => {
              setOpen(true);
            }}>
            Add
          </Button>
        ]}
        // pagination={{
        //   count: Math.ceil(carrierTrackingCount / itemsPerPage),
        //   page: page,
        //   onChange: handlePageChange
        // }}
      />
      {/* </PageContent> */}

      <CreateTrackingSources
        editData={moreOptionClicked}
        setEditData={setMoreOptionClicked}
        error={error}
        data={details}
        setData={setDetails}
        open={open}
        run={run}
        // edit={false}
        setRun={setRun}
        onClose={() => setOpen(false)}
        handleEdit={handleEditCarrierTrackingSource}
        handleAdd={handleCreateCarrierTrackingSource}
      />

      <ConfirmDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        confirmDialogFunction={handleDeleteCarrierTrackingSource}
        question={'Are you sure you want to delete?'}
      />
    </div>
  );
};
/* eslint-enable */
export default TrackingSources;
