import { Button, Divider } from '@material-ui/core';
import StyledSelect from 'components/StyledSelect';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from 'sites/shipper/components/Toast/toast';

import Style from "./ProfileRoleManagement.module.css"
import AdminTeam from 'service/AdminTeam';
import { AccountCompanyShortName,allowedUerRoleArray, userRoleArray } from 'util/adminPermissionRoles';

const ProfileRoleManagement = ({ refresh, setRefresh, profileData }) => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.session.auth);
  const [teamRoles, setTeamRoles] = useState([]);


  // useEffect(() => {
  //   AdminTeam.fetchTeamRoles(auth.token).then((result) => {
  //     if (result.error) {
  //       notify(dispatch, 'error', result.error);
  //     } else {
  //       setTeamRoles(result?.data);
  //     }
  //   });
  // }, []);

  const [role, setRole] = useState(AccountCompanyShortName[profileData.role]);

  // useEffect(() => {
  //   if (profileData ) {
  //     const roleData = userRoleArray.find(
  //       (item) => item.label === profileData.role
  //     );
  //     setRole(roleData?.value);
  //   }
  // }, [profileData]);

  const handleRoleType = (e) => {
    setRole(e.target.value);
  };

  const handleUpdateRole = () => {
    

    const data_to_send = {
      memberId: profileData?.id,
      company_id: auth.cmpyId,
      account_id: auth.accountId,
      role: role,
      role_type: role
    };
    AdminTeam.update_role(auth.token, data_to_send).then((result) => {
      if (result.error) {
        notify(dispatch, 'error', result.error);
      } else {
        setRefresh(!refresh);
        notify(dispatch, 'success', result.message);
      }
    });
  };

  return (
    <>
      <div className={Style.container}>
        <div className="app-page-title--heading">
          <h1>Manage Role</h1>
        </div>
        <Divider />
        <StyledSelect
          label="Role Type*"
          name="role"
          margin="0"
          value={role}
          onChange={handleRoleType}
          size="small"
          options={allowedUerRoleArray(auth.accountCmpyLinkType)}
        />

        <Button
          onClick={handleUpdateRole}
          size="small"
          variant="text"
          // className="bg-white-10 text-black mr-3 shadow-none"
          className="btn-outline-dark ">
          Update
        </Button>

        <Divider />

        {/* {teamRoles &&
            teamRoles?.length > 0 &&
            teamRoles?.map((item, index) => (
              <div key={index} className="d-flex flex-column ">
                <p className={Style.detailContainerHeading}>{item?.label}: </p>
                <p className={`${Style.detailContainerDesc} text-truncate`}>
                  {item?.description}
                </p>
              </div>
            ))} */}
      </div>
    </>
  );
};

export default ProfileRoleManagement;
