import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';

export default class DocumentListService {
  static async fetchDocuments(token,search,page) {
    const url = `${getBaseUrl()}admin/fetch-documents?search=${search}&page=${page}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    });
    const response = await result.json();
    return response;
  }



  static async createDcoument(data, token) {
    const url = `${getBaseUrl()}admin/create-document`;
    const result = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const response = await result.json();
    return response;
  }




  static async editDcoument(data, token) {
    const url = `${getBaseUrl()}admin/edit-document`;
    const result = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const response = await result.json();
    return response;
  }


}
