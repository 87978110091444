import React, { useEffect } from 'react'
import ViewBreakdown from '../ViewBreakdown/ViewBreakdown'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const CreateRateSheet = () => {
    const history = useHistory()
    const routeInfo = useSelector((state) => state.rateSheet.route_info)
    useEffect(() => {
        if (!routeInfo._id) {
            history.replace("/rate-sheet")
        }
    }, [routeInfo])

    return (
        <>

            <ViewBreakdown
                data={routeInfo}
                route_id={routeInfo?._id}
                open={true}
            />
        </>
    )
}

export default CreateRateSheet