import React from 'react'
import PageContent from 'sites/shipper/components/layout/PageContent';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import Document from './Document/Document';

const DocumentList = () => {
  return (
    <>
      <PageTitle titleHeading={`Document Managment`}></PageTitle>
      <PageContent>
        <Document />
      </PageContent>
    </>
  );
}

export default DocumentList;