import React, { useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import Style from "./PageTitle.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs, Button, Tooltip, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { ChevronLeft, NavigateNext } from '@material-ui/icons';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter
} from 'react-router';
import { NavLink } from 'react-router-dom';
import RouteMaps from 'sites/shipper/pages/Home/Routes';
import { West } from '@mui/icons-material';
const PageTitle = (props) => {
  // const location = useLocation();
  // const history = useHistory();
  // const match = useRouteMatch('settings/company-info');
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleIconBox,
    pageTitleDescription,
    titleHeading,
    titleDescription,
    children,
    link,
    location,
    match,
    history,
    noMargin,
    fixed,
    goBack
  } = props;
  console.log('Inside PageTitle routes', location, history, match);

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const [anchorEl, setAnchorEl] = useState(null);

  function openUserMenu(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <>
      <div
        className={clsx(
          'app-page-title d-block w-100 ',
          pageTitleStyle,
          pageTitleBackground,
          {
            'app-page-title--shadow': pageTitleShadow,
            'm-0': noMargin,
            'app-page-title--fixed': fixed
          }
        )}>
        <div>
          {/* {location && location.pathname !== '/dashboard' && (
            <div className="d-flex align-items-center mb-4">
              
              <Route>
                {({ location }) => {
                  const pathnames = location.pathname
                    .split('/')
                    .filter((x) => x);

                  return (
                    <Breadcrumbs
                      separator={
                        <NavigateNext fontSize="small" className="opacity-3" />
                      }
                      aria-label="breadcrumb">
                      {pathnames &&
                        pathnames.length > 0 &&
                        pathnames[0] !== 'dashboard' && (
                          <NavLink
                            className="font-size-xs text-first"
                            to="/dashboard">
                            Dashboard
                          </NavLink>
                        )}
                      {pathnames.map((value, index) => {
                        const last = index === pathnames.length - 1;
                        const to = `/${pathnames
                          .slice(0, index + 1)
                          .join('/')}`;
                        console.log('last route', last, pathnames, to);

                        return last ? (
                          <Typography
                            className="font-size-xs text-black-50"
                            key={to}>
                            {RouteMaps[to]?.label}
                          </Typography>
                        ) : (
                          <NavLink
                            className="font-size-xs text-first"
                            to={to}
                            key={to}>
                            {RouteMaps[to].label}
                          </NavLink>
                        );
                      })}
                    </Breadcrumbs>
                  );
                }}
              </Route>
            </div>
          )} */}
        </div>
        <div className="d-flex align-items-center justify-content-between">
        {goBack && (
            <div className="mr-4">
              <Tooltip title="Go Back">
                <West onClick={goBack} style={{ cursor: "pointer" }} />
              </Tooltip>
            </div>
          )}
          <div className="app-page-title--first">
            <div className="app-page-title--heading">
              <h1>{titleHeading}</h1>
              {pageTitleDescription && titleDescription && (
                <div className={`${Style.page_title_desc} app-page-title--description text-black-50`}>
                  {titleDescription}
                </div>
              )}
            </div>
          </div>

          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(withRouter(PageTitle));
