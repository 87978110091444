import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { AmexIcon, MasterCardIcon, VisaIcon } from 'assets/svg/paymentIcons';
import Style from "./ShipmentPayment.module.css"

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  highlightBox: {
    backgroundColor: '#ecf1f9', //'#f4f7fc', //#edf3fd
    width: '100%',
    marginBottom: '10px',
    borderRadius: '5px',
    position: 'relative',
    overflow: 'hidden'
  }
});

const ShipmentPayment = ({ classes, booking }) => {
  console.log('Inside Bk Details');
  const [openRoute, setOpenRoute] = useState(true);
  const [anchorElMenu2, setAnchorElMenu2] = useState(null);

  const toggleRouteDetail = () => {
    setOpenRoute(!openRoute);
  };

  const handleClickMenu2 = (event) => {
    setAnchorElMenu2(event.currentTarget);
  };

  const handleCloseMenu2 = () => {
    setAnchorElMenu2(null);
  };


  // console.log("Payment", booking)

  return (
    <div className={`${classes.root} ${Style.container}`}>









      {/* Invoice Summary Block */}
      {/* <div className="mb-3 px-2 d-flex justify-content-between">
        <span className={`font-size-md font-weight-bold mb-0`}>
          INV #97710037
        </span>
        <small className="text-black-50">Due: 07/12/2022</small>
      </div> */}











      {/* top card  */}
      <div className="bg-serious-blue rounded d-flex justify-content-between p-4">
        <div className="p-2">
          <div>
            <span className=" text-white-50 font-size-xs text-uppercase mb-2">
              Amount Paid
            </span>
          </div>
          <span className={`${Style.amount_heading} font-weight-bold mb-0 display-3`}>
            <small className="pr-1 text-white">$</small>
            <span className="text-white">{booking.amount_paid}</span>
          </span>

        </div>
        <div className="p-2">
          <div>
            <span className=" text-white-50 font-size-xs text-uppercase mb-2">
              Amount Due
            </span>
          </div>
          <span className={`${Style.amount_heading} font-weight-bold mb-0 display-3`}>
            <small className="pr-1 text-white">$</small>
            <span className="text-white">{booking.due_amount}</span>
          </span>
        </div>
        {/* <div className={`${Style.badge} badge badge-success px-3`}>Completed</div> */}
      </div>

















      {/* Shipment Charges Details Block */}
      <div className="text-black-50 font-weight-bold mb-2 mt-4 px-2">
        <span className="" style={{ fontSize: '0.875rem' }}>Shipment Charges</span>
      </div>



      <div className={clsx('my-3 p-3', classes.highlightBox)}>


        {booking.shipment_charges.shipment_charges.map((data) => (
          <>
            <div className="d-flex justify-content-between mb-3">
              <div>
                <div className="font-size-sm">
                  <span>{data.label}</span>
                </div>
                <div className={clsx('ml-2 text-black-50 font-size-xs')}>
                  <small>Qty {data.quantity} x ${data.unit_price} ({data.cargoType})</small>
                </div>
              </div>
              <div className="ml-auto text-right">
                <div className="text-black d-block font-size-sm">
                  <span>${data.total_amount}</span>
                </div>
              </div>
            </div>
          </>
        ))}


        <div className="divider my-3 bg-dark " />


        <div className="d-flex justify-content-between mb-3">
          <div>
            <div className="font-size-sm font-weight-bold">
              <span>Subtotal</span>
            </div>
          </div>
          <div className="ml-auto text-right">
            <div className="font-weight-bold text-black d-block font-size-sm">
              <span>${booking.shipment_charges.sub_total}</span>
            </div>
          </div>
        </div>


        <div className="d-flex justify-content-between mb-3">
          <div>
            <div className="font-size-sm">
              <span>Tax</span>
              <small className="ml-2 text-black-50">(18%)</small>
            </div>
          </div>
          <div className="ml-auto text-right">
            <div className="text-black d-block font-size-sm">
              <span>${booking.shipment_charges.tax}</span>
            </div>
          </div>
        </div>


        <div className="divider my-3 bg-dark " />

        <div className="d-flex justify-content-between mb-3">
          <div>
            <div className="font-size-sm font-weight-bold">
              <span>Total</span>
            </div>
          </div>
          <div className="ml-auto text-right">
            <div className="font-weight-bold text-black d-block font-size-sm">
              <span>${booking.shipment_charges.total}</span>
            </div>
          </div>
        </div>

      </div>










        {/* Additional Service Charges */}

      <div className="text-black-50 font-weight-bold mb-2 mt-4 px-2">
        <span className="" style={{ fontSize: '0.875rem' }}>Additional Service Charges</span>
      </div>



      <div className={clsx('my-3 p-3', classes.highlightBox)}>


        {booking.services.array.map((data) => (
          <>
            <div className="d-flex justify-content-between mb-3">
              <div>
                <div className="font-size-sm">
                  <span>{data.name}</span>
                </div>
              </div>
              <div className="ml-auto text-right">
                <div className="text-black d-block font-size-sm">
                  <span>${data.amount}</span>
                </div>
              </div>
            </div>
          </>
        ))}

        <div className="divider my-3 bg-dark " />
        <div className="d-flex justify-content-between mb-3">
          <div>
            <div className="font-size-sm font-weight-bold">
              <span>Subtotal</span>
            </div>
          </div>
          <div className="ml-auto text-right">
            <div className="font-weight-bold text-black d-block font-size-sm">
              <span>${booking.services.sub_total}</span>
            </div>
          </div>
        </div>


        <div className="d-flex justify-content-between mb-3">
          <div>
            <div className="font-size-sm">
              <span>Tax</span>
              <small className="ml-2 text-black-50">(18%)</small>
            </div>
          </div>
          <div className="ml-auto text-right">
            <div className="text-black d-block font-size-sm">
              <span>${booking.services.tax}</span>
            </div>
          </div>
        </div>


        <div className="divider my-3 bg-dark " />



        <div className="d-flex justify-content-between mb-3">
          <div>
            <div className="font-size-sm font-weight-bold">
              <span>Total</span>
            </div>
          </div>
          <div className="ml-auto text-right">
            <div className="font-weight-bold text-black d-block font-size-sm">
              <span>${booking.services.total}</span>
            </div>
          </div>
        </div>

      </div>



    </div>
  );
};

ShipmentPayment.defaultProps = {};

ShipmentPayment.propTypes = {
  classes: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default withStyles(styles)(ShipmentPayment);
