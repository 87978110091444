import * as React from 'react';
import Avatar from '@mui/material/Avatar';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name, src) {
    return {
        sx: {
            // bgcolor:stringToColor(name),
            bgcolor: !src ? stringToColor(name) : 'transparent',
        },
        // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        children: `${name[0]?.toUpperCase()}`,
    };
}

const CustomAvatar = ({ className, src, name, sx, width = 50, height = 50 }) => {
    return (
        <Avatar
            className={className}
            style={{ width: width, height: height, ...sx }}
            src={src}
            {...stringAvatar(name, src)}
            imgProps={{
                sx: {
                    objectFit: "fill"
                }
            }}
        />
    );
}

export default CustomAvatar