import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import {
  MIN_RIGHT_DRAWER_WIDTH,
  RIGHT_DRAWER_WIDTH,
  SIDEBAR_DRAWER_WIDTH
} from 'util/constants';
import { withStyles } from '@material-ui/styles';
import { Button, Tooltip } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

const styles = (theme) => ({
  root: {
    display: 'flex'
  },
  drawerPaper: {
    width: '90%',
    overflow: 'visible',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '45%'
    }
    // backgroundColor: '#06113C'
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  content: {
    overflow: 'hidden'
  }
});

const RightDrawer = ({
  classes,
  children,
  open,
  onClose,
  setHeaderDrawerToggle,
  widthClasses,
  closeBtnClasses
}) => {
  return (
    <div
    //   className={clsx('app-sidebar d-flex', sidebarStyle, {
    //     'app-sidebar--shadow': sidebarShadow
    //   })}
    >
      <Drawer
        style={{ zIndex: '11' }}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={(e) => {
          e.preventDefault();
        }}
        className={clsx(classes.drawer, 'right-drawer')}
        classes={{
          paper: clsx(classes.drawerPaper, widthClasses)
        }}>
        <Tooltip arrow title="Close Shipment Details" placement="left">
          <Button
            size="small"
            onClick={onClose}
            className={`${closeBtnClasses} close-drawer-btn bg-kargo-blue-light p-0 d-40`}
            id="CloseDrawerTooltip">
            <div
              className={clsx('navbar-toggler hamburger hamburger--elastic', {
                'is-active': open
              })}>
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </div>
          </Button>
        </Tooltip>
        <PerfectScrollbar
          options={{
            suppressScrollX: true
          }}>
          <div className="position-relative">{children}</div>
        </PerfectScrollbar>
      </Drawer>
    </div>
  );
};

export default withStyles(styles)(RightDrawer);
