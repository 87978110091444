import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class PlatformFeeAndTaxServices {
  static async fetchPlatformFeeAndTaxes(accountId, token, status,method) {
    const url = `admin/fetch-platform-fee-taxes?accountId=${accountId}&status=${status}`;

      return httpService(HttpMethods.GET, url, null, {
        token,
        type: 'billing_settings_platform_fee_and_taxes',
        method: method,
        platform: 'Admin'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  }

  static async createPlatformFeeAndTax(data, token) {
    const url = `admin/create-platform-fee-tax`;

      return httpService(HttpMethods.POST, url, data, {
        token,
        type: 'billing_settings_platform_fee_and_taxes',
        method: 'create',
        platform: 'Admin'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  
  }
  static async deactivatePlatformFeeAndTax(id, accountId, token) {
    const url = `admin/deactivate-platform-fee-tax/${id}?accountId=${accountId}`;

      return httpService(HttpMethods.PUT, url, {}, {
        token,
        type: 'billing_settings_platform_fee_and_taxes',
        method: 'delete',
        platform: 'Admin'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  }
  
}
