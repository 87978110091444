import ModalSimple from 'components/ModalSimple';
import StyledInput from 'components/StyledInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubscriberService from 'service/SubscriberService';
import { notify } from 'sites/shipper/components/Toast/toast';
import ConfirmDialog from '../../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

const AddSubscriber = ({ open, setOpen, subscriberData, run, setRun }) => {
  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    account_type: ''
  });
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.session.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => {
    if (subscriberData) {
      setData({
        ...data,
        first_name: subscriberData.first_name,
        last_name: subscriberData.last_name,
        email: subscriberData.email,
        account_type: subscriberData.type
      });
    }
  }, []);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    const data_to_send = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      type: data.account_type
    };
    SubscriberService.createSubscriber(
      auth.accountId,
      data_to_send,
      auth.token
    ).then((res) => {
      if (res.error) {
        setError(res.error);
      } else {
        notify(dispatch, 'success', res.message);
        setRun(!run);
        setOpen(false);
      }
      setLoading(false);
    });
  };

  const handleEdit = () => {
    setLoading(true);
    const data_to_send = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      type: data.account_type,
      subscriber_id: subscriberData.id
    };
    SubscriberService.editSubscriber(
      auth.accountId,
      data_to_send,
      auth.token
    ).then((res) => {
      if (res.error) {
        setError(res.error);
      } else {
        notify(dispatch, 'success', res.message);
        setRun(!run);
        setOpen(false);
      }
      setLoading(false);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value
    });
  };

  return (
    <>
      <ModalSimple
        modalTitle={'Add Subscriber'}
        open={open}
        action={() => setConfirmSubmit(true)}
        modalDescription={''}
        maxWidth={'xs'}
        fullWidth={true}
        handleClose={() => setOpen(false)}
        actionButtonText={subscriberData ? 'Edit' : 'Create'}>

        <StyledInput
          name="first_name"
          label="First Name"
          value={data.first_name}
          onChange={handleChange}
        />
        <StyledInput
          name="last_name"
          label="Last Name"
          value={data.last_name}
          onChange={handleChange}
        />
        <StyledInput
          name="email"
          label="Email"
          value={data.email}
          onChange={handleChange}
        />
        <div>
          <span>Select Account Type :</span>
          <div style={{display:"flex",flexDirection:"column"}}>
            <label style={{padding:"0 0 0 10px",display:"flex",alignItems:"center",gap:"10px"}}><input type='radio' value="Shipper" name="account_type" onChange={handleChange} checked={data.account_type === "Shipper"} />Shipper</label>
            <label style={{padding:"0 0 0 10px",display:"flex",alignItems:"center",gap:"10px"}}><input type='radio' value="Forwarder" name="account_type" onChange={handleChange} checked={data.account_type === "Forwarder"} />Forwarder</label>
            <label style={{padding:"0 0 0 10px",display:"flex",alignItems:"center",gap:"10px"}}><input type='radio' value="Other" name="account_type" onChange={handleChange} checked={data.account_type === "Other"} />Other</label>
          </div>
        </div>
        {error && (
          <span style={{ color: 'red', fontSize: '12px' }}>{error}</span>
        )}
      </ModalSimple>

      <ConfirmDialog
        confirmDialogFunction={subscriberData ? handleEdit : handleSubmit}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure to save`}
      />
    </>
  );
};

export default AddSubscriber;
