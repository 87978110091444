import CountrySelectNew from 'components/CountrySelect/CountrySelectNew'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ServiceManagementService from 'service/ServiceManagementService'
import { notify } from 'sites/shipper/components/Toast/toast'
import ConfirmDialog from 'sites/shipper/pages/shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog'
import StyledInput from 'components/StyledInput'
import ModalSimple from 'components/ModalSimple'
import LoaderComponent from '../../../../components/LoaderComponent'
const AddServicePricePopup = ({ service_id, run, setRun, handleClose, edit, editData, handleEdit, open }) => {

  const [loading, setLoading] = useState(false)
  const auth = useSelector((state) => state.session.auth)
  const dispatch = useDispatch()
  const [data, setData] = useState({
    country: "",
    price_amount: "",
    price_currency: "usd",
    service_id: service_id
  })
  const [error, setError] = useState()

  const handleCountry = (e, opt) => {
    if (opt) {
      setData({
        ...data,
        country: opt
      })
    }
  }


  useEffect(() => {
    if (edit) {
      setData({
        ...data,
        country: editData.country,
        price_amount: editData.price.value,
        price_currency: editData.price.currency,
      })
    } else {
      setData({
        ...data,
        country: "",
        price_amount: "",
        price_currency: "usd",
      })
    }
  }, [edit])

  const handleSubmit = () => {
    if (
      !data.country ||
      !data.price_amount ||
      !data.price_currency
    ) {
      setError("All fields are required")
      return
    }
    if (edit) {
      setLoading(true)
      ServiceManagementService.editServicePrice(auth.accountId, editData._id, data, auth.token).then(res => {
        if (res.error) {
          setError(res.error)
        } else {
          setRun(!run)
          notify(dispatch, "success", res.message)
          setError("")
          setData({
            ...data,
            country: null,
            price_amount: ""
          })
          handleClose()
        }
        setLoading(false)
      })
    } else {
      setLoading(true)
      ServiceManagementService.createServicePrice(auth.accountId, data, auth.token).then(res => {
        if (res.error) {
          setError(res.error)
        } else {
          setRun(!run)
          notify(dispatch, "success", res.message)
          setError("")
          setData({
            ...data,
            country: null,
            price_amount: ""
          })
          handleClose()
        }
        setLoading(false)
      })
    }

  }


  const [confirmSubmit, setConfirmSubmit] = useState(false);


  return (
    <>
      {/* <Button
        className={`btn-neutral-primary icon-btn ${Style.close_btn}`}
        onClick={() => { handleClose(); handleEdit(null) }}
      >
        <Close fontSize='small' />
      </Button>
      <h3>Create Price</h3> */}
      {loading && <LoaderComponent loading={loading} />}
      <ModalSimple
        modalTitle={edit ? 'Edit Price' : 'Create Price'}
        open={open}
        action={() => setConfirmSubmit(true)}
        modalDescription={''}
        maxWidth={'xs'}
        fullWidth={true}
        handleClose={(e) => { handleClose(); handleEdit(null); }}
        actionButtonText={edit ? 'Edit' : 'Create'}
      >
        <div style={{ display: "flex", flexDirection: "column", }}>

          <CountrySelectNew
            value={data.country}
            useDefault
            isClearable={false}
            isMulti={false}
            onChange={handleCountry}
          />
          <StyledInput type='number' value={data.price_amount} placeholder='Enter Price in USD' min={0} onChange={(e) => setData({ ...data, price_amount: e.target.value })} />
          {/* <input type='number' value={data.price_amount} placeholder='Enter Price in USD' min={0} onChange={(e) => setData({ ...data, price_amount: e.target.value })} /> */}
          {error && <span style={{ fontSize: "12px", color: "red" }}>{error}</span>}
          {/* <button onClick={() => { setConfirmSubmit(true) }}>Submit</button> */}
        </div>
      </ModalSimple>

      <ConfirmDialog
        confirmDialogFunction={handleSubmit}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure to add this price for the selected country`}
      />
    </>
  )
}

export default AddServicePricePopup