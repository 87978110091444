import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import HeaderDots from './HeaderDots';
import HeaderDrawer from './HeaderDrawer';
import HeaderUserbox from './HeaderUserbox';
import { Button, Tooltip, IconButton } from '@material-ui/core';
import { HomeOutlined, Menu } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HeaderSearch from 'layout-components/HeaderSearch';
import HeaderMenu from './HeaderMenu';
import { SIDEBAR_DRAWER_WIDTH, MIN_SIDEBAR_DRAWER_WIDTH } from 'util/constants';
import HeaderTitle from './HeaderTitle';
import HeaderActions from './HeaderActions';
import Style from "./Header.module.css";
import { useHistory } from 'react-router-dom';
import Logo from 'assets/logos/kargo-04-dark-icon.svg';

export const styles = (theme) => ({
  appBar: {
    width: `calc(100% - ${MIN_SIDEBAR_DRAWER_WIDTH}px)`,
    // minWidth: `calc(1100px - ${MIN_DRAWER_WIDTH}px)`,
    marginLeft: MIN_SIDEBAR_DRAWER_WIDTH,
    zIndex: 10,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen
    // })
  },
  appBarShift: {
    marginLeft: SIDEBAR_DRAWER_WIDTH,
    width: `calc(100% - ${SIDEBAR_DRAWER_WIDTH}px)`,
    // minWidth: `calc(1100px - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  hide: {
    display: 'none'
  },
  menuButton: {
    position: 'absolute',
    left: -12,
    border: '3px solid #fafafa',
    transition: 'all 0.2s ease-in-out',
    margin: 'auto',
    top: 0,
    bottom: 0,
    color: '#fff',
    zIndex: 10,
    marginRight: 36
  }
});

const Header = ({
  classes,
  fullWidth,
  headerShadow,
  headerBgTransparent,
  sidebarToggle,
  setSidebarToggle,
  windowSize
}) => {

  const history = useHistory();

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <>
      <div
        className={clsx('app-header', {
          [classes.appBar]: !fullWidth && windowSize > 767,
          [classes.appBarShift]: !fullWidth && (sidebarToggle && windowSize > 767),
          'app-header--shadow': headerShadow
        })}>
        <div className={`${Style.app_header_pane} app-header--pane`}>
          <div className="mr-4">
            {!fullWidth && (
              <Tooltip
                arrow
                title={sidebarToggle ? 'Collapse Menu' : 'Expand Menu'}
                placement="right">
                <Button
                  size="small"
                  onClick={toggleSidebar}
                  className={clsx(
                    classes.menuButton,
                    Style.slider_btn,
                    'btn-kargo-blue d-flex align-items-center justify-content-center d-24 p-2 rounded-pill shadow-none'
                  )}
                  id="CloseDrawerTooltip">
                  {sidebarToggle ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </Button>
              </Tooltip>
            )}
          </div>

          {/* <Button
            href="/"
            className="btn-transition-none bg-neutral-primary text-primary font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative">
            <Tooltip title="Home Page">
              <span>
                <HomeOutlined />
              </span>
            </Tooltip>
          </Button> */}
          <img
            src={Logo} alt="KargoPlex"
            className={Style.mobile_logo}
            onClick={() => history.push('/')}
          />
          <HeaderTitle />
          <HeaderMenu />
          {/* <HeaderSearch /> */}
        </div>
        <div className="app-header--pane">
          {/* <HeaderActions /> */}
          {/* <HeaderDots /> */}
          <HeaderUserbox />
          {/* <HeaderDrawer /> */}
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(Header);
