import React, { useState } from 'react';
import Style from "./MobileChatListPane.module.css";
import clsx from 'clsx';

import {
    List,
    ListItem,
} from '@material-ui/core';

import avatar1 from 'assets/images/avatars/avatar1.jpg';
// import { ACTIVE_SHIPMENTS } from '../../Shipments/ActiveShipments/MockData';
import { ACTIVE_SHIPMENTS } from '../../../Shipments/ActiveShipments/MockData';
import RouteIcon from 'assets/svg/RouteIcon.svg';
import { withStyles } from '@material-ui/styles';
import { formatDate } from 'util/Utils';

const styles = (theme) => ({
    routeIcon: {
        height: '0.5rem'
    }
});

const MobileChatListPane = ({ classes, selectedMessage, handlesMessageSelect,chatData }) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const [inputBg, setInputBg] = useState(false);
    const toggleInputBg = () => setInputBg(!inputBg);

    console.log("chatData",chatData)
    return (
        <>
            <div className="w-100">
                <div>
                    {/* <List
                        component="div"
                        className="nav-pills nav-neutral-second flex-column"> */}
                      
                                    <ListItem
                                        component="a"
                                        className={`d-block m-0`}
                                        button
                                        onClick={() => handlesMessageSelect(chatData.room_id)}
                                        selected={
                                            chatData.room_id === selectedMessage
                                        }
                                    >
                                        <div className="d-flex">
                                            <div className={`w-20`}>
                                                <div className="text-gray-700 d-flex c-justify-content-center mr-1">
                                                    <div className="avatar-icon-wrapper avatar-icon-md">
                                                        <div className="badge badge-success badge-circle">
                                                            Online
                                                        </div>
                                                        <div className="avatar-icon rounded-circle">
                                                            <img alt="..." src={chatData?.shipper_logo} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* show this only when the drawer is expanded  */}
                                            <div className="w-80">
                                                <div className="font-size-sm mb-1 d-flex justify-content-between">
                                                    <span className="font-weight-bold pr-2 mr-2">
                                                        { chatData?.shipper_name}
                                                    </span>
                                                    <div className="text-black-50 font-size-xs">
                                                        3 hours ago
                                                    </div>
                                                </div>

                                <div className="font-size-xs mb-1 mr-2 text-truncate">
                                    { chatData.last_message.type === "text" ? <>{chatData.last_message.content }</> : "Offer Card"}
                                    
                                                </div>

                                                <span className="text-black opacity-5 d-block mb-1">
                                                    <div className={'font-size-xs text-truncate'}>
                                                        <span>
                                                            <span>{`${chatData?.shipmentInfo?.origin?.port}, ${chatData?.shipmentInfo?.origin?.country?.value}`}</span>
                                                            <img
                                                                className={clsx(classes.routeIcon, 'mx-2')}
                                                                src={RouteIcon}
                                                                alt="route icon"
                                                            />
                                                            <span>{`${chatData?.shipmentInfo?.destination?.port}, ${chatData?.shipmentInfo?.destination?.country?.value}`}</span>
                                                        </span>
                                                    </div>
                                                </span>
                                                <div className="font-size-xs mb-1 text-truncate d-flex justify-content-between">
                                                    {/* <span className="">
                                                        {formatDate(data.createdTimestamp)}
                                                    </span> */}
                                                    <span className="text-black-50 ">
                                                        <b className="mx-2">•</b>
                                                        {/* <em>{data.status.label}</em> */}
                                                    </span>
                                                    {/* show this when the number is > 0 only max 99 */}
                                                    <div className={Style.notification_status}>
                                                        99
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListItem>
                                    <div className="divider mx-3" />
                              
                    {/* </List> */}
                </div>
                {/* <div className="text-black-50 text-center p-3 d-block mb-3">
                    <span className="btn-wrapper--label">- End of messages -</span>
                </div> */}
            </div>
        </>
    );
};

export default withStyles(styles)(MobileChatListPane);
