import React from 'react'
import Style from "./ActivityCard.module.css";
import avatar from "assets/images/avatars/avatar1.jpg";
import { Button } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';

const ActivityCard = ({ data }) => {
    return (
      <>
        <div className={ Style.mainContainer}>
          <div className={ Style.purpose}>{ data?.purpose}</div>
          <div className={ Style.content}>{data?.content}</div>
          <div className={ Style.createdAt}>{data?.createdAt}</div>
          <div className="divider" />
        </div>
     </>
    );
}

export default ActivityCard

// <div className="d-flex flex-column">
// <div>
//   <div className="font-weight-bold d-flex justify-content-between">
   
//     <span className={`${Style.activity_title} text-black`}>
//       {data?.content}
//     </span>
//     <small>
//       <span className={`${Style.date_time} pl-2 text-muted`}>
      
//         {data?.createdAt}
//       </span>
//     </small>
//   </div>
//   <small className="d-flex pt-2 w-100 align-items-center justify-content-between">
//     <div className="d-flex pt-2 align-items-center ">
//       <div className="avatar-icon-wrapper avatar-icon-xs mr-2">
//         <div className="avatar-icon">
//           <img alt="..." src={data?.image} className={Style.avatar} />
//         </div>
//       </div>

//       <div className={Style.user_name}>{data?.name}</div>
//     </div>
    
//   </small>
// </div>

// <div className="divider my-3" />
// </div>