import { useState, useEffect } from "react";
import Style from "./filter.module.css";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton, Checkbox } from "@mui/material";

/* eslint-disable */

const DropdownOptions = ({
  header,
  options,
  iconSrc,
  id,
  filters,
  setFilters
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const elem = document.getElementById(id);
    if (!filters.transportation_mode.includes(header)) {
      elem.classList.add(Style.hidden);
    } else {
      elem.classList.remove(Style.hidden);
    }
  }, [filters.transportation_mode]);

  const handleTransportMethod = (e) => {
    const value = e.target.value;

    console.log(value, 'dusudhusd');

    if (filters.transportation_mode.includes(value)) {
      let newLoads = filters.loads;
      if (value === 'SEA') {
        newLoads = filters.loads.filter(
          (item) => !['FCL', 'LCL', 'BULK'].includes(item)
        );
      }

      if (value === 'AIR') {
        newLoads = filters.loads.filter((item) => !['PKGS'].includes(item));
      }

      const newModes = filters.transportation_mode.filter(
        (item) => item !== value
      );
      setFilters({
        ...filters,
        transportation_mode: newModes,
        loads: newLoads
      });
    } else {
      const newModes = [...filters.transportation_mode, value];
      setFilters({ ...filters, transportation_mode: newModes });
    }
  };

  const handleLoadsChange = (e) => {
    const value = e.target.value;
    if (filters.loads.includes(value)) {
      const newLoads = filters.loads.filter((item) => item !== value);
      setFilters({ ...filters, loads: newLoads });
    } else {
      const newLoads = [...filters.loads, value];
      setFilters({ ...filters, loads: newLoads });
    }
  };

  return (
    <>
      <div className="c-flex-col c-gap-half" style={{ overflow: 'hidden' }}>
        <div
          className={`${Style.option_container} c-flex c-justify-content-between c-items-center px-1`}>
          <div
            className="c-flex c-gap-1 c-font-title c-font-dark align-items-center"
            style={{ padding: '0.3rem 0.5rem' }}>
            {/*            
                <FontAwesomeIcon
                  className="mb-1 mt-1  d-24"
                  icon={['fas', `${iconSrc}`]}
                /> */}
            <Checkbox
              value={header}
              checked={filters.transportation_mode.includes(header)}
              onChange={(e) => {
                handleTransportMethod(e);
                setIsOpen(filters.transportation_mode.includes(header));
              }}
            />
            <img
              src={`/assets/forwarder/shipping_leads/${iconSrc}.svg`}
              alt=""
            />
            <span>{header}</span>
          </div>
          {/* <IconButton
              onClick={() => setIsOpen((state) => !state)}
              size="small">
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton> */}
        </div>
        <div
          id={id}
          className={`${Style.filter_by} ${Style.hidden} c-flex-col`}>
          {options &&
            options.map((option, index) => (
              <div key={index}>
                <Checkbox
                  value={option.value}
                  checked={filters.loads.includes(option.value)}
                  onChange={handleLoadsChange}
                  id={option.value}
                />
                <label
                  htmlFor={option.label}
                  className="c-font-title c-font-light">
                  {option.label}
                </label>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
/* eslint-enable */
export default DropdownOptions;