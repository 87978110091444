import { SAVE_TRANSPORT_LINES_INFO } from '../actions';

const initialState = {
  transport_lines_info: null
};

export default function transportUnitNames(state = initialState, action) {
  switch (action.type) {
    case SAVE_TRANSPORT_LINES_INFO:
      return {
        ...state,
        transport_lines_info: action.payload
      };
    default:
      return state;
  }
}
