import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Button, Dialog, DialogContent } from '@material-ui/core';
import Style from "./ShipmentActivity.module.css";

import PerfectScrollbar from 'react-perfect-scrollbar';

import avatar1 from 'assets/images/avatars/avatar1.jpg';
import avatar2 from 'assets/images/avatars/avatar2.jpg';
import avatar3 from 'assets/images/avatars/avatar3.jpg';
import avatar4 from 'assets/images/avatars/avatar4.jpg';
import avatar5 from 'assets/images/avatars/avatar5.jpg';
import avatar6 from 'assets/images/avatars/avatar6.jpg';
import avatarAdmin from 'assets/images/avatars/admin-icon-light.svg';
import { withStyles } from '@material-ui/styles';
import ActivityCard from './ActivityCard/ActivityCard';
import { Pagination } from '@mui/material';
import { Empty } from 'antd';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import { ForwarderService } from 'service';
import ModalPopupWrapper from 'components/Common/ModalPopup/ModalPopup';
import { useSelector } from 'react-redux';
import Rating from '@mui/material/Rating';
import EmptyContainer from 'sites/shipper/components/EmptyContainer/EmptyContainer';
const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%'
  }
});


const ShipmentActivity = ({ classes, booking, isMobile = false, shipmentId, refresh, setRefresh }) => {

  const auth = useSelector((state) => state.session.auth)
  const [shipmentStatus, setShipmentStatus] = useState()

  const [run, setRun] = useState(false)

  const [isReviewGiven, setIsReviewGiven] = useState(false)
  const [exstingReview, setExistingReview] = useState()


  useEffect(() => {
    ForwarderService.fetchShipmentStatus(shipmentId).then(data => {
      if (data.error) { console.log(data.error) }
      else { setShipmentStatus(data.status) }
    })

    ForwarderService.checkReviewGiven(shipmentId, auth.token).then(data => {
      setIsReviewGiven(data.success)
      if (data.success) {
        setExistingReview(data.result)
      }
    })
  }, [run])




  const [openModal, setOpenModal] = useState(false)
  return (
    <div className={classes.root}>

      {shipmentStatus === "Incomplete" &&
        <div className='d-flex justify-content-end position-relative'>
          <Button
            className={`${Style.request_change_btn} btn-primary px-3 py-2 font-size-xs`}
            // onClick={toggleRequestpopup}
            onClick={() => setOpenModal(true)}
          >
            Cancel Shipment
          </Button>
        </div>
      }





      {isReviewGiven && exstingReview && <>
        <div className=''>
          <div className={`bg-neutral-dark d-flex justify-content-between mb-4 p-3`}>
            <div>
              <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                Shipment Rating & Review
              </h6>
            </div>
            <div className="d-flex align-items-center"></div>
          </div>

          <div className={Style.rating}>

            <span className={Style.rating_label}>Over all rating:</span>
            <span className={Style.rating_value}>
              {exstingReview?.rating?.over_all}
              <Rating readOnly size='small' value={exstingReview?.rating?.over_all} />
            </span>

            <span className={Style.rating_label}>Handling Cargo:</span>
            <span className={Style.rating_value}>
              {exstingReview?.rating?.handling_cargo}
              <Rating readOnly size='small' value={exstingReview?.rating?.handling_cargo} />
            </span>

            <span className={Style.rating_label}>Would Recommanded:</span>
            <span className={Style.rating_value}>
              {exstingReview?.rating?.would_recommended}
              <Rating readOnly size='small' value={exstingReview?.rating?.would_recommended} />
            </span>

            <span className={Style.rating_label}>Communication:</span>
            <span className={Style.rating_value}>
              {exstingReview?.rating?.communication}
              <Rating readOnly size='small' value={exstingReview?.rating?.communication} />
            </span>

          </div>
          <div className={`d-flex flex-column mt-3`}>
            <div className={Style.review_box}>
              {exstingReview?.review}
            </div>
          </div>
        </div>
      </>
      }









      <div className={`bg-neutral-dark d-flex justify-content-between p-3 ${isMobile ? 'mt-2' : 'mt-4'}`}>
        <div>
          <h6 className="font-weight-bold font-size-lg mb-1 text-black">
            Shipment Activity Feed
          </h6>
        </div>
        <div className="d-flex align-items-center"></div>
      </div>

      <div className="divider my-4" />

      <div className="bg-white ">
        {booking.length > 0 ?
          <>
            {booking.map((data) => (
              <ActivityCard data={data} />
            ))}
          </>
          :
          // <Empty />
          <EmptyContainer title='No activities found' />
          }
      </div>


    









    </div>
  );
};

ShipmentActivity.defaultProps = {};

ShipmentActivity.propTypes = {
  classes: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default withStyles(styles)(ShipmentActivity);
