import { getBaseUrl } from 'config/config';
import { HttpMethods, httpService } from './global';

export default class SecurityService {
  static async fetchCookiesData(id, token) {
    const url = `admin/fetch-cookies-data/${id}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'security_cookies_settings',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchPrivacyData(id, token) {
    const url = `admin/fetch-privacy-data/${id}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'security_privacy_settings',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async manageCookies(data, token) {
    const url = `admin/update-cookies`;

    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'security_cookies_settings',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async managePrivacy(data, token) {
    const url = `admin/update-privacy`;

    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'security_privacy_settings',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchLoginHistory(id, token) {
    const url = `admin/fetch-login-history?id=${id}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'security_account_settings',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
}
