import React from 'react'
import Style from "./PriceCard.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@material-ui/core'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment';
import { transitTimeCalculator } from 'util/RateSheet/rateSheet';
const PriceCard = ({ item, handleViewPrices }) => {
    return (
        <>
            <div className={Style.card}>
                <div className={Style.cardheadersection}>
                    <p>Starting From ${item?.price?.toLocaleString()}</p>
                    <p className={Style.status_chip}>{item.status}</p>
                </div>
                <div className={Style.transitTimeContainer}>
                    <FontAwesomeIcon
                        className="d-20"
                        icon={['fas', 'ship']}
                    />
                    <p>{transitTimeCalculator(item.transit_time.port_to_port)}</p>
                    <span>(Port to port transit time)</span>
                </div>
                <div className='divider mx-2' />
                <div className={Style.dateContainer}>
                    <p>Valid From : {moment(item.effective_date).format('ll')}</p>
                    <p>Valid Till : {item.expiry_date !== "NA" ? moment(item.expiry_date).format('ll') : "NA"}</p>
                </div>

                <Button className={Style.clickBtn} onClick={handleViewPrices}>
                    <ArrowForwardIcon style={{ color: "#fff", fontSize: "14px" }} />
                </Button>
            </div>
        </>
    )
}

export default PriceCard