import React, { useEffect, useState } from 'react';
import Style from './company_team.module.css';
import clsx from 'clsx';
import {
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip
} from '@material-ui/core';
import AnimatedSearchbar from 'components/Common/AnimatedSearchbar/AnimatedSearchbar';
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import MemberRow from './MemberRow/MemberRow';
import avatar1 from 'assets/images/avatars/avatar1.jpg';
import { useSelector, useDispatch } from 'react-redux';
import AdminTeam from 'service/AdminTeam';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import AddMemberForm from './AddMemberForm/AddMemberForm';
import ModalPopupWrapper from 'components/Common/ModalPopup/ModalPopup';
import { Add } from '@material-ui/icons';
import { notify } from 'sites/shipper/components/Toast/toast';
import PageContent from 'sites/shipper/components/layout/PageContent';
import ProfilePopup from './ProfilePopup/ProfilePopup';
import ConfirmDialog from '../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import CachedIcon from '@mui/icons-material/Cached';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { userAccessControlMatrix } from 'util/adminPermissionRoles';
import CustomAvatar from 'sites/shipper/components/CustomAvatar/CustomAvatar';


/* eslint-disable */
const AdminTeamPage = () => {
  const dispatch = useDispatch();
  const [openAddMember, setOpenAddMember] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [run, setRun] = useState(true);
  const auth = useSelector((state) => state.session.auth);

  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [moreOptionClicked, setMoreOptionClicked] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [profileData, setProfileData] = useState(null);

  const [confirmRemove, setConfirmRemove] = useState(false);

  const history = useHistory();

  const [search, setSearch] = useState('');

  const searchParams = new URLSearchParams(location.search);

  const [memberCount, setMemberCount] = useState(0);

  const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(parseInt(pageValue));
  const itemsPerPage = 15;


  const [check, setCheck] = useState(true);

  // useEffect(() => {
  //   if (loading) return;

  //   const element = document.getElementById('top');
  //   element.scrollIntoView({ behavior: 'smooth' });
  // }, [check]);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/team?page=${value}`);
  }


  useEffect(() => {
    AdminTeam.fetch_team(auth.accountId, auth.token, page, search).then((res) => {
      if (res.error) {
        console.log(res.error);
        setLoading(false)
      } else {
        setData(res?.data);
        setMemberCount(res?.memberCount)
        setLoading(false)
      }
    });
  }, [auth, run, page, search]);

  const handleStatusChange = (index, status, id) => {
    const newData = [...data];
    newData[index].status = status;
    setData(newData);
    const data_to_send = {
      id: id,
      status: status
    };
    AdminTeam.update_team_member_status(data_to_send, auth.token).then((data) => {
      if (data.error) {
        // window.alert(data.error)
        notify(dispatch, 'error', data.error);
      } else {
        // window.alert(data.message)
        notify(dispatch, 'success', data.message);
      }
    });
  };

  const removeProfile = () => {
    AdminTeam.removeTeamMember(profileData.id, auth.accountId, auth.token).then(
      (response) => {
        if (response.error) {
          console.log(response.error);
        } else {
          // window.alert(response.message)
          notify(dispatch, 'success', response.message);
          setRun(!run);
        }
      }
    );
  };

  const fetchTeamMember = (row_data) => {
    AdminTeam.get_team_member_by_id(row_data.id, auth.token).then((response) => {
      if (response.error) {
        console.log(response.error);
      } else {
        setProfileData(response);
      }
    });
  };

  const handleOpenProfile = (row_data) => {
    fetchTeamMember(row_data);
    setOpenProfile(true);
  };

  const handleCloseProfile = () => {
    setOpenProfile(false);
    setProfileData(null);
  };

  useEffect(() => {
    if (profileData) fetchTeamMember(profileData);
  }, [run]);

  const TableHeading = [
    'S.No.',
    'Name',
    'Role',
    'Date Created',
    'Status',

    'Action'
  ];

  const Keys = ['index', 'user_name', 'user_role', 'date', 'status', '_id'];

  const TableData = data?.map((item, index) =>
    Keys.map((Key) => {
      if (Key === 'index') {
        return index + 1;
      }

      if (Key === 'user_name') {
        return (
          <div className={Style.user_column}>
            {/* <img src={item.avatar} alt={'Img'} className={Style.avatar} /> */}
            <CustomAvatar
              height={35} width={35}
              name={item.user_name}
              src={item.avatar}
            />
            <span>{item.user_name}</span>
          </div>
        );
      }
      if (Key === 'date') {
        return moment(item.date).format("ll");
      }
      if (Key === 'status') {
        return (
          <>
            <div className="position-relative">
              <div className={`${Style.status} ${Style.active_status}`}>
                {item.status}
              </div>
            </div>
          </>
        );
      }

      if (Key === '_id') {
        const disabled = !userAccessControlMatrix[auth.accountCmpyLinkType].includes(item.user_role)
        return (
          <>
            <div className="position-relative">
              <center>
                <Tooltip title="More" arrow>
                  <Button
                    style={{ cursor: disabled && "not-allowed" }}
                    disabled={disabled}
                    variant="text"
                    className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
                    onClick={(e) => {
                      setOpenMoreOptions(e.currentTarget);
                      setMoreOptionClicked(item);
                    }}>
                    <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                  </Button>
                </Tooltip>
              </center>
              {moreOptionClicked?.id === item?.id && (
                <SmallPopupWrapper
                  open={openMoreOptions}
                  onClose={() => {
                    setMoreOptionClicked(null);
                    setOpenMoreOptions(null);
                  }}>
                  <MenuItem
                    onClick={() => {
                      setOpenMoreOptions(null);
                      handleOpenProfile(moreOptionClicked);
                      // setProfileData(moreOptionClicked);
                      // setOpenProfile(true)
                    }}>
                    Profile
                  </MenuItem>
                  {/* <MenuItem onClick={() => { toggleRightDrawer(true); setOpenMoreOptions(null); }}>Edit Route</MenuItem> */}
                  {
                    <MenuItem
                      onClick={() => {
                        setOpenMoreOptions(null);
                        setConfirmRemove(true);
                        setProfileData(moreOptionClicked);

                        // setConfirmDialogOpen(true);
                      }}>
                      Remove
                    </MenuItem>
                  }
                </SmallPopupWrapper>
              )}
            </div>
          </>
        );
      } else {
        return item[Key] || '';
      }
    })
  );

  return (
    <>
      <PageTitle titleHeading="Team Managment" >
        {/* <Button
          className={`btn-primary`}
          startIcon={<Add />}
          onClick={() => setOpenAddMember((state) => !state)}>
          Add Member
        </Button> */}
      </PageTitle>
      {openAddMember &&
        <AddMemberForm
          open={openAddMember}
          onClose={() => setOpenAddMember(false)}
        />
      }
      {/* <ModalPopupWrapper noStyle maxWidth="md" open={openAddMember}>
        <AddMemberForm onClose={() => setOpenAddMember(false)} />
      </ModalPopupWrapper> */}

      <PageContent>
        <div className={Style.container}>
          {/* <AnimatedSearchbar

          >
            <Tooltip arrow title='Filter list'>
              <Button
                variant="text"
                className={`${Style.icon_btn} btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
                <FilterListTwoToneIcon className="w-50" />
              </Button>
            </Tooltip>
            <Tooltip arrow title='Settings'>
              <Button
                variant="text"
                className={`${Style.icon_btn} btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
                <SettingsTwoToneIcon className="w-50" />
              </Button>
            </Tooltip>
          </AnimatedSearchbar>

          <Divider />
          <div className={Style.table_header}>
            <div className={`${Style.label} ${Style.small_col}`}>#</div>
            <div className={`${Style.label} ${Style.big_col}`}>Name </div>
            <div className={`${Style.label} ${Style.normal_col}`}>Role </div>
            <div className={`${Style.label} ${Style.normal_col}`}>Date Created </div>
            <div className={`${Style.label} ${Style.normal_col}`}>Status </div>
            <div className={`${Style.label} ${Style.normal_col}`}>Action</div>
          </div>
          <Divider />

          <div className={Style.table_rows_container}>
            {data.map((member, index) => (
              <MemberRow run={run} setRun={setRun} data={member} index={index} key={member.id} handleStatusChange={handleStatusChange} />
            ))}
          </div> */}

          <TableWithSearchBar
            loading={loading}
            animatedSearchbar={{
              show: false,

            }}
            tableMainHeading={'Team Members'}
            TableHeading={TableHeading}
            TableBody={TableData}
            TableGrid={'0.5fr 1.5fr repeat(4,1fr)'}
            actionButtons={[
              <Button
                variant="text"
                onClick={() => setRun(!run)}
                className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
                <CachedIcon className="w-50" />
              </Button>,
              <Tooltip arrow title="Add Member">
                <Button
                  className={`btn-primary`}
                  startIcon={<Add />}
                  onClick={() => setOpenAddMember((state) => !state)}>
                  Add Member
                </Button>
              </Tooltip>
            ]}
            pagination={{
              count: Math.ceil(memberCount / itemsPerPage),
              page: page,
              onChange: handlePageChange
            }}
          />
        </div>
        {/* profileData, onClose, open, setRefresh, refresh */}

        {profileData && (
          <ProfilePopup
            open={openProfile}
            profileData={profileData}
            onClose={handleCloseProfile}
            refresh={run}
            setRefresh={setRun}
          />
        )}
        <div className={`position-relative ${Style.action_container}`}>
          {/* <ModalPopupWrapper
            className={Style.popup_class}
            maxWidth="lg"
            noStyle
            open={openProfile}>
            <ProfilePopup
              profileData={profileData}
              onClose={handleCloseProfile}
              refresh={run}
              setRefresh={setRun}
            />
          </ModalPopupWrapper> */}

          <ConfirmDialog
            question={'Are you sure you want to remove form team?'}
            heading="Remove from team"
            confirmDialogFunction={removeProfile}
            confirmDialogOpen={confirmRemove}
            setConfirmDialogOpen={setConfirmRemove}
          />
        </div>
      </PageContent>
    </>
  );
};

// export default compose(withStyles(styles), withWidth())(AdminTeamPage);
export default AdminTeamPage;
/* eslint-enable */
