import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import DomainIcon from '@mui/icons-material/Domain';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Tooltip } from '@material-ui/core';
import Style from "./FromTo.module.css";
import classNames from 'classnames';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: '15px',
        left: '-50%',
        right: '50%',
        '@media (max-width: 700px)': {
            top: '7.5px'
        },
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(90deg, rgba(52,168,85,1) 0%, rgba(59,201,99,1) 64%);',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(90deg, rgba(52,168,85,1) 0%, rgba(59,201,99,1) 64%);'
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
        '@media (max-width: 700px)': {
            height: 1
        },
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: '30px',
    height: '30px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',

    '@media (max-width: 700px)': {
        width: '15px',
        height: '15px',
    },

    ...(ownerState.error && {
        backgroundImage:
            'linear-gradient(90deg, rgba(248,50,69,1) 0%, rgba(248,50,69,1) 64%);',
    }),
    ...(ownerState.active && !ownerState.error && {
        backgroundImage:
            'linear-gradient(90deg, rgba(52,168,85,1) 0%, rgba(52,168,85,1) 64%);',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && !ownerState.active && !ownerState.error && {
        backgroundImage:
            'linear-gradient(90deg, rgba(52,168,85,1) 0%, rgba(52,168,85,1) 64%);',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className, error, label, tooltip, stepLength } = props;
    const icons = {
        1: <DomainIcon style={{ fontSize: 'clamp(6px, 1.5vw, 15px)' }} />,
        [stepLength]: <HomeOutlinedIcon style={{ fontSize: 'clamp(6px, 1.5vw, 15px)' }} />,
    };

    const dotClass = classNames(Style.dot_step, {
        [Style.dot_step_error]: error,
        [Style.dot_step_active]: active && !error,
        [Style.dot_step_complete]: completed && !active && !error,
    })


    console.log(label)

    return (
        <>
            {label !== '' ?
                <ColorlibStepIconRoot ownerState={{ completed, active, error }} className={className}>
                    {icons[String(props.icon)]}
                </ColorlibStepIconRoot>
                :
                <Tooltip title={tooltip} arrow >
                    <div className={dotClass}></div>
                </Tooltip>
            }
        </>
    );
}



ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * Mark the step as error. Is passed to child components.
     * @default false
     */
    error: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};


export default function FromTo({ currentStep, isError = false, from = '', to = '', classes }) {

    const steps = [
        { tooltip: from, label: from },
        { tooltip: 'Heyyy', label: '' },
        { tooltip: to, label: to },
    ];

    return (
        <div className={`${Style.stepper} ${classes}`}>
            <Stepper alternativeLabel activeStep={currentStep} connector={<ColorlibConnector />}>
                {steps.map((item, index) => {
                    const stepProps = {}
                    if (index === currentStep && isError) {
                        stepProps.error = true;
                    }
                    return (
                        <Step key={index} >
                            <StepLabel
                                {...stepProps}
                                StepIconComponent={(stepIconProps) => (
                                    <ColorlibStepIcon
                                        {...stepIconProps}
                                        label={item.label}
                                        tooltip={item.tooltip}
                                        stepLength={steps.length}
                                    />
                                )}

                            >
                                <span style={{ color: 'var(--primary-text)', fontSize: 'clamp(8px, 1.5vw, 10px)', lineHeight: '1.1' }} >{item.label}</span>
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </div>

    );
}
