import Tabs from 'components/Tabs';
import React from 'react';
import Category from './Category/Category';
import Blog from './Blog/Blog';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import PageContent from 'sites/shipper/components/layout/PageContent';
import { useHistory, useParams } from "react-router-dom"

/* eslint-disable */
const Blogs = () => {
  const history = useHistory()
  // const { tab } = useParams()
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get('tab');
  const content = [
    {
      label: 'Blog',
      value: "blog",
      View: <Blog tab={"blog"} />
    },
    {
      label: 'Category',
      value: "category",
      View: <Category tab={"category"} />
    }
  ];
  return (
    <>
      <PageTitle
        titleHeading={`Blog Managment`}
      >
      </PageTitle>
      <PageContent>
        <Tabs content={content} active={tab || 'blog'} onClick={(e) => history.push(`/articles?tab=${e}`)} />
      </PageContent>

    </>
  );
};
/* eslint-enable */

export default Blogs;
