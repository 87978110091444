export const transitTimeCalculator = (time) => {
    let result = ''
    if (time?.min === time?.max) {
        result = `${time?.min} ${time?.unit}`
    } else {
        result = `${time?.min} - ${time?.max} ${time?.unit}`
    }
    return result
}

// export const numberToLocaleString = (num) => {
//     const x = parseFloat(123456).toFixed(2); // Convert to float with two decimal places
//     const newNum = x.toLocaleString(undefined, { maximumFractionDigits: 2 })
//     return newNum
// }
export const numberToLocaleString = (num) => {
    // Convert to number with two decimal places
    const x = Number(num).toFixed(2);
    // Format using toLocaleString
    const newNum = parseFloat(x).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return newNum;
}

export const localeStringToNumber = (formattedNum) => {
    if (formattedNum) {
        const unformattedNum = parseFloat(formattedNum.replace(/,/g, ''));
        return unformattedNum;
    } else {
        return 0
    }
}



export const getCurrencyIcon = (currency) => {
    return currencyIconList[currency?.toLowerCase()] ? currencyIconList[currency?.toLowerCase()] : currency
}


const currencyIconList = {
    "usd": "$"
} 