import { getBaseUrl } from 'config/config';
import { HttpMethods, httpService } from './global';

export default class BlogService {
  static async fetchCategories(data, token) {
    const url = `admin/fetch-categories`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'articles_category',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchBlogs(data, token) {
    const url = `admin/fetch-blogs`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'articles_blog',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async createCategory(data, token) {
    const url = `admin/create-category`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'articles_category',
      method: 'create',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async createBlog(data, token) {
    const url = `admin/create-blog`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'articles_blog',
      method: 'create',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async removeBlog(data, token) {
    const url = `admin/remove-blog`;
    return httpService(HttpMethods.DELETE, url, data, {
      token,
      type: 'articles_blog',
      method: 'delete',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async removeCategory(data, token) {
    const url = `admin/remove-blog-category`;
    return httpService(HttpMethods.DELETE, url, data, {
      token,
      type: 'articles_category',
      method: 'delete',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchBlogDetails(data, token) {
    const url = `admin/fetch-blog-details`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'articles_blog',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchBlogCategoryDetails(data, token) {
    const url = `admin/fetch-blog-category-details`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'articles_category',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async editBlogDetails(data, token) {
    const url = `admin/edit-blog-details`;
    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'articles_blog',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async editBlogCategoryDetails(data, token) {
    const url = `admin/edit-blog-category-details`;
    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'articles_category',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async checkCategorySlugAvailability(data, token) {
    const url = `admin/check-blog-category-slug-availability`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'articles_category',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async checkBlogSlugAvailability(data, token) {
    const url = `admin/check-blog-slug-availability`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'articles_blog',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
}
