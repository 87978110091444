import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { CARGO_TYPES } from '../../../../../../util/constants';
import { IconButton, OutlinedInput } from '@mui/material';
import { Check, Edit } from '@material-ui/icons';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  iconWrapper: {
    '& > p': {
      fontSize: '12px',
      textTransform: 'uppercase'
    }
  },
  icon: {
    height: '32px',
    width: 'auto',
    marginBottom: 4
  },
  smallText: {
    fontSize: '8px',
    margin: '0 4px'
  }
});

// const animatedComponents = makeAnimated();

const ShipmentIcon = ({ classes, type, count, hideLabel, handleCategoryChange, editable = false }) => {

  const [isEditOn, setIsEditOn] = useState(false);

  return (
    <div className={classes.iconWrapper}>
      {!hideLabel && (
        <div className='d-flex align-items-center c-gap-1 mb-1'>
          {!isEditOn
            ?
            <span style={{ fontSize: '12px' }} >{type.category.label}</span>
            :
            <div>
              <OutlinedInput
                placeholder='FCL'
                style={{ width: '80px' }}
                size='small'
                inputProps={{ style: { padding: '4px 9px', fontSize: '11px' } }}
              />
            </div>
          }
          {editable &&
            <IconButton
              className='btn-neutral-primary icon-btn'
              style={{ width: '11px', height: '11px' }}
              onClick={() => setIsEditOn(state => !state)}
            >
              {isEditOn ? <Check style={{ fontSize: '10px' }} /> : <Edit style={{ fontSize: '10px' }} />}
            </IconButton>
          }

        </div>
      )}
      <img className={classes.icon} src={type.icon} alt={type.label} />
      {!hideLabel && (
        <Typography gutterBottom>
          {count}
          <span className={classes.smallText}> x </span>
          {type.label}
        </Typography>
      )}
    </div>
  );
};

ShipmentIcon.defaultProps = {
  type: CARGO_TYPES.FCL_20_ST,
  hideLabel: false
};

ShipmentIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
  count: PropTypes.number,
  hideLabel: PropTypes.bool
};

export default withStyles(styles)(ShipmentIcon);
