import { getBaseUrl } from 'config/config';
import { HttpMethods, httpService } from './global';

export default class TransactionService {
  static async fetchTransaction(forwarder_id, shipper_id, token) {
    const url = `${getBaseUrl()}transaction/forwarder/fetch?forwarder_id=${forwarder_id}&shipper_id=${shipper_id}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchTransactionAdmin(data, token, page, type, method) {
    const url = `transaction/admin/fetch?page=${page}`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: type,
      method: method,
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }
}
