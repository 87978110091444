import Header from 'sites/shipper/components/layout/Header/Header';
import Style from './ShippingLeads.module.css';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import Card from './Card/Card';
import Filters from './Filter/Filters';
import { useEffect, useState } from 'react';
import { Button, IconButton } from '@material-ui/core';

import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from '@mui/material';
import { useHistory } from 'react-router-dom';
import PageError403 from '../../../../components/PageError403';
import RightDrawer from 'sites/shipper/components/layout/RightDrawer';
import FilterSlider from './Filter/FilterSlider/FilterSlider';
import { setFilterDataInRedux } from 'actions';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import { Empty } from 'antd';
import { ShipmentRequestService } from '../../../../service';
import SkeletonComponent from 'sites/shipper/components/Skeleton/Skeleton';
import EmptyContainer from 'sites/shipper/components/EmptyContainer/EmptyContainer';

/* eslint-disable */
const ShippingLeads = () => {
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(pageValue);
  const [loading, setLoading] = useState(false);

  const [shippingLeads, setShippingLeads] = useState([]);
  const [filterSlider, setFilterSlider] = useState(false);

  const [totalShipmentRequest, setTotalShipmentRequest] = useState();
  const itemsPerPage = 10;
  const FilterDataFromRedux = useSelector(
    (state) => state.filterDataRedux.data
  );
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.session.auth);
  const [filters, setFilters] = useState({
    ready_date_from: '',
    ready_date_to: '',
    origin: {
      country: '',
      state: '',
      city: '',
      port: ''
    },
    destination: {
      country: '',
      state: '',
      city: '',
      port: ''
    },

    request_id: '',
    loads: [],
    transportation_mode: [],
    services: [],

    carriers: []
  });

  useEffect(() => {
    if (FilterDataFromRedux && FilterDataFromRedux.page === 'shippingLeads') {
      setFilters({
        origin: {
          country: FilterDataFromRedux?.origin?.country,
          state: FilterDataFromRedux?.origin?.state,
          city: FilterDataFromRedux?.origin?.city,
          port: FilterDataFromRedux?.origin?.port
        },
        destination: {
          country: FilterDataFromRedux?.destination?.country,
          state: FilterDataFromRedux?.destination?.state,
          city: FilterDataFromRedux?.destination?.city,
          port: FilterDataFromRedux?.destination?.port
        },
        ready_date_from: FilterDataFromRedux?.ready_date_from,
        ready_date_to: FilterDataFromRedux?.ready_date_to,
        // origin: {
        //   from: FilterDataFromRedux?.origin?.from,
        //   to: FilterDataFromRedux?.origin?.to
        // },
        request_id: FilterDataFromRedux?.request_id,
        loads: FilterDataFromRedux?.loads,
        transportation_mode: FilterDataFromRedux?.transportation_mode,
        services: FilterDataFromRedux.services,

        carriers: FilterDataFromRedux.carriers
      });
    } else {
      setFilters({
        ready_date_from: '',
        ready_date_to: '',
        origin: {
          country: '',
          state: '',
          city: '',
          port: ''
        },
        destination: {
          country: '',
          state: '',
          city: '',
          port: ''
        },
        request_id: '',
        loads: [],
        transportation_mode: [],
        services: [],
        carriers: []
      });
    }
  }, [FilterDataFromRedux]);
  const init = () => {
    let data_to_send = {};

    if (FilterDataFromRedux && FilterDataFromRedux.page === 'shippingLeads') {
      data_to_send = {
        ...FilterDataFromRedux
        // origin: FilterDataFromRedux.origin?.address?.addressLocality?._id
      };
    }

    data_to_send = {
      ...data_to_send,
      adminId: auth.accountId,
      page: page
    }
    setLoading(true);
    ShipmentRequestService.fetchShipmentRequest(
      data_to_send,
      auth.token
    ).then((data) => {
      if (data.error) {
        console.log(data.error);
        setShippingLeads([]);
      } else {
        setShippingLeads(data.data);
        setTotalShipmentRequest(data.totalShipmentRequest);
      }

      setLoading(false);
    });
  };

  useEffect(() => {
    init();
  }, [page, FilterDataFromRedux]);

  // this toggle filter on small screens
  const shouldToggleFilter = () => {
    const filterElem = document.getElementById('shipping-leads-filter');
    filterElem.classList.toggle(Style.show_filter);
  };
  const [check, setCheck] = useState(true);

  const [allow, setAllow] = useState(true);

  useEffect(() => {
    if (loading) return;
    if (!allow) return;
    if (shippingLeads.length === 0) return;
    const element = document.getElementById('top');
    element.scrollIntoView({ behavior: 'smooth' });
  }, [check]);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/shipment-request?page=${value}`);
  }


  const handleFilter = () => {
    const temData = {
      ...filters,
      page: 'shippingLeads'
    };
    dispatch(setFilterDataInRedux(temData));
    handlePageChange('', 1);
    setFilterSlider(false);
  };

  return (
    <>
      {/* {loading && <LoaderComponent loading={loading} />} */}


      <>
  
          <div id="top">
            <PageTitle titleHeading="Shipment Requests">
              <Button
                className={`${Style.btn} btn-primary shadow-none`}
                variant="contained"
                // className={Style.resetButton}
                onClick={() => setFilterSlider(true)}>
                Advance Filters
              </Button>
            </PageTitle>
            <div className={Style.container}>
              <div className={Style.main}>
                {/* search bar  */}
                {/* <div className={Style.top_bar}>
                    <div>
                      <SearchIcon fontSize="small" />
                      <input
                        type="text"
                        className={Style.search_input}
                        placeholder="Search"
                      />
                    </div>
                    <IconButton
                      onClick={shouldToggleFilter}
                      className={Style.filter_btn}>
                      <TuneIcon fontSize="small" />
                    </IconButton>
                  </div> */}

                <div className={Style.content}>
                  <div className={Style.filter} id="shipping-leads-filter">
                    {/* close button  */}
                    <div className={Style.close_filter_btn}>
                      <IconButton
                        onClick={shouldToggleFilter}
                        className={`btn-neutral-primary btn-icon btn-animated-icon btn-transition-none d-25`}>
                        <CloseIcon sx={{ fontSize: '15px' }} />
                      </IconButton>
                    </div>
                    {/* filter  */}
                    <Filters
                      handlePageChange={handlePageChange}
                      filters={filters}
                      setFilters={setFilters}
                      handleFilter={handleFilter}
                    />
                  </div>
                  <div className={Style.list_container}>
                    
                    {loading && <SkeletonComponent />}
                    {loading === false && (shippingLeads && shippingLeads.length > 0 ? (
                      <>
                        {shippingLeads.map((data, index) => (
                          <Card
                            data={data}
                            key={index}
                            idx={index}
                            make={true}
                          />
                        ))}

                        <div className="d-flex justify-content-center my-5">
                          <Pagination
                            className="pagination-primary"
                            count={Math.ceil(totalShipmentRequest / itemsPerPage)}
                            page={page}
                            onChange={handlePageChange}
                          />
                        </div>
                      </>
                    ) : (
                      <center>
                         {/* <Empty /> */}
                         <EmptyContainer title='No shippings leads found' />
                      </center>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </>

      <RightDrawer
        onClose={() => setFilterSlider(false)}
        open={filterSlider}
        widthClasses={Style.res_right_drawer}
        closeBtnClasses={Style.right_drawer_close_btn}>
        <FilterSlider
          filterData={filters}
          setFilterData={setFilters}
          onClose={() => setFilterSlider(false)}
          handleFilter={handleFilter}
        />
      </RightDrawer>
    </>
  );
};
/* eslint-enable */
export default ShippingLeads;
