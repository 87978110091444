import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = (theme) => ({
  locationFlag: {
    fontSize: (props) => props.size,
    border: '`1px solid'
  }
});
// outline
const CountryFlag = ({ classes, countryCode, size, squared }) => {
  return (
    <span className={classes.locationFlag}>
      <span
        className={clsx(
          `flag-icon flag-icon-${String(countryCode).toLowerCase()} `,
          {
            'flag-icon-squared': squared
          }
        )}
      />
    </span>
  );
};

CountryFlag.defaultProps = {
  countryCode: '',
  squared: false,
  size: '12px'
};

CountryFlag.propTypes = {
  classes: PropTypes.object.isRequired,
  /**
   * 2-letter {@link https://www.iso.org/obp/ui/#search|ISO 3166-1-alpha-2 code} of a country
   */
  countryCode: PropTypes.string.isRequired,
  /**
   * size of flag. Defaults to 12px.
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Set to true if you want flag to appear as a square. Defaults to rectangle (false).
   */
  squared: PropTypes.bool
};

export default withStyles(styles)(CountryFlag);
