import { getBaseUrl } from 'config/config';
import { HttpMethods, httpService } from './global';

export default class TagManagmentService {
    static async createTag(data, token) {
        const url = `admin/tag-managment/create`;
        return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'tag_management',
            method: 'create',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }


    static async fetchTag(data, token, type, method) {
        const url = `admin/tag-managment/fetch`;
        return httpService(HttpMethods.POST, url, data, {
            token,
            type: type,
            method: method,
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }

    static async editTag(data, token) {
        const url = `admin/tag-managment/edit`;
        return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'tag_management',
            method: 'edit',
            platform: 'Admin'
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }
}
