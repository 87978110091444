
import { Divider } from "@material-ui/core";
import Style from "./Card.module.css";
import Status from "./Status/Status";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { makeOfferInfo } from "actions";
import { useDispatch } from "react-redux";
import FromTo from 'components/Common/from_to/FromToVert';
import { useState, useEffect } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomAvatar from 'sites/shipper/components/CustomAvatar/CustomAvatar';
import { loadDisplay, renderShipmentAddress } from "util/Shipment/shipmentLoads";

/* eslint-disable */

const Card = ({ data, idx, make }) => {

    const dispatch = useDispatch();
    const history = useHistory();


    const handleMakeoffer = () => {
        if (make) {
            const data_to_send_on_next_page = {
                request_id: data?.request_id,
                transport_mode: data?.transport_mode,
                ready_on: data?.ready_date,
                services: data?.services,
                origin: data?.origin,
                destination: data?.destination,
                load: data?.load,
                shipper: data?.shipper,
                load_type: data?.load_type,
                cargo_type: data?.cargo_type,
                commodity: data?.commodity,
                additional_info: data?.additional_info
            }
            dispatch(makeOfferInfo(data_to_send_on_next_page))
            history.push(`/tools/shipment-request/make-offer/${data._id}`)
        }
        else {
            const data_to_send_on_next_page = {
                request_id: data?.request_id,
                transport_mode: data?.transport_mode,
                ready_on: data?.ready_date,
                services: data?.services,
                origin: data?.origin,
                destination: data?.destination,
                load: data?.load,
                load_type: data?.load_type,
                cargo_type: data?.cargo_type,
                commodity: data?.commodity,
                additional_info: data?.additional_info
            }
            dispatch(makeOfferInfo(data_to_send_on_next_page))
            history.push(`/shippment/my-proposal/view-offer/${data._id}`)
        }
    }




    const [services, setServices] = useState([])
    useEffect(() => {
        const array = []

        data.services.map((item) => {
            // if (item.checked) {
            array.push(item.serviceName)
            // }
        })
        setServices(array)
    }, [data?.services])




    return (
        <div className={Style.container}>
            <div className={Style.top_container}>
                <div className={Style.user_detail}>
                    <div>
                        <CustomAvatar
                            height={35} width={35}
                            name={data?.shipper?.name}
                            src={data?.shipper?.logo}
                        />
                        {/* <img src={data?.shipper?.logo} alt="img" style={{ width: '30px' }} /> */}
                    </div>
                    <div className={Style.flex_col}>
                        <span className={Style.bold}>{data?.shipper?.name}</span>
                        <Status label="Verified Shipper" />
                    </div>
                </div>
                <div className={Style.flex_col}>
                    <span className={Style.top_bar_label}>Request Id</span>
                    <span className={Style.top_bar_value}>{data?.request_id}</span>
                </div>
                {/* <div className={Style.flex_col}>
                    <span className={Style.top_bar_label}>Mode of Transport</span>
                    <span className={Style.top_bar_value}>{data?.transport_mode}</span>
                </div> */}
            </div>

            <Divider />

            <div className={Style.middle_container}>
                <div
                    // className={`c-p-1 c-rounded c-flex-col`}
                    style={{
                        // gap: '0.3em',
                        // height: 'fit-content',
                        minWidth: window.innerWidth > 600 ? "300px" : "100%",
                        padding: "0px"
                        // background: '#F8F9FF'
                    }}
                >
                    <FromTo
                        origin={renderShipmentAddress(data?.origin)}
                        origin_country_code={data?.origin?.country?.code}
                        destination={renderShipmentAddress(data?.destination)}
                        destination_country_code={data?.destination?.country?.code}
                    />
                </div>

                <div className={`w-50 ${Style.middle_details}`}>
                    <span className={Style.middle_details_label}>Ready for collection</span>
                    <span className={Style.middle_details_value}>{data?.ready_date}</span>
                    <span className={Style.middle_details_label}>Mode of Transport</span>
                    <span className={Style.middle_details_value}>{data?.transport_mode}</span>
                    <span className={Style.middle_details_label}>Load Type</span>
                    <span className={Style.middle_details_value}>{loadDisplay(data?.load)}</span>
                    <span className={Style.middle_details_label}>Proposal Sent</span>
                    <span className={Style.middle_details_value}>{data?.proposal_sent}</span>
                </div>
            </div>

            <Divider />

            <div className={Style.bottom_container}>
                <div className={Style.flex_col}>
                    <span className={Style.bold}>Services Requested</span>

                    <div className={Style.flex} style={{ gap: '0.2rem 1rem', flexWrap: 'wrap' }}>

                        {services.length > 0 ?
                            <>
                                {services.map((item, index) => (
                                    <Status label={item} key={index} />
                                ))}
                            </>
                            :
                            <span style={{ fontSize: "12px" }}>
                                No Services Selected
                            </span>}
                    </div>
                </div>
                <div className={Style.flex} style={{ gap: '1rem', alignItems: 'flex-end' }}>
                    <span className="d-flex c-gap-half font-size-xs text-dark align-items-center">
                        <FontAwesomeIcon icon={['fa', `clock`]} />
                        {data?.createdAt}
                    </span>
                    <Button
                        variant="outlined"
                        className={Style.make_offer_btn}
                        onClick={handleMakeoffer}

                    >
                        {make ? "Make Offer" : "View Offer"}
                    </Button>

                    {/* chat button  */}
                    {/* <div className={Style.chat_btn}>
                        <img
                            style={{ cursor: 'pointer' }}
                            src="/assets/forwarder/shipping_leads/message_icon.svg"
                            alt="Chat"
                        />
                    </div> */}

                </div>
            </div>
        </div>
    )
}

/* eslint-enable */
export default Card