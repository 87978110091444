import React from 'react'
import Style from './UpcomingPricesCard.module.css'
import { Button } from '@material-ui/core'
const UpcomingPricesCard = ({ handleViewPrices }) => {
  return (
    <>
      <div className={Style.card}>
        <p>View Upcoming Price</p>
        <Button className={Style.clickBtn} onClick={handleViewPrices}>
          View
        </Button>
      </div>
    </>
  )
}

export default UpcomingPricesCard