import {
  InputAdornment,
  // MenuItem,
  Select,
  Typography
} from "@mui/material";
// import {
//   InputAdornment,
//   MenuItem,
//   Select,
//   Typography
// } from '@material-ui/core';
import { TextField, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput
} from "react-international-phone";
import ConstantValuesService from "service/ConstantValuesService";
const CustomPhoneNumberInput = ({
  countryuseState,
  number,
  handleCountry,
  handleNumber,
  variant = "outlined",
  disabled = false
}) => {
  const {
    phone,
    inputValue,
    handlePhoneValueChange,
    inputRef,
    country,
    setCountry
  } = usePhoneInput({
    defaultCountry: countryuseState || "us",
    value: number,
    countries: defaultCountries,
    onChange: (data) => {
      handleNumber(data.inputValue);
    }
  });

  // const [countryList, setCountryList] = useState([])
  // useEffect(() => {
  //   ConstantValuesService.fetchCountryList().then(res => {
  //     if (res.error) {
  //       console.log(res.error)
  //     } else {
  //       setCountryList(res)
  //     }
  //   })
  // }, [])

  useEffect(() => {
    // const selectedCountry = countryList.filter((item) => item.code.toLowerCase() === country.iso2)[0]
    // console.log(country)
    handleCountry({
      country: country.iso2,
      phone: country.dialCode
    })
  }, [country])


  return (
    <TextField
      fullWidth={true}
      variant={variant}
      label="Phone number"
      color="primary"
      placeholder="Phone number"
      disabled={disabled}
      value={inputValue}
      size="small"
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      sx={{
        ".css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
          color: "#3c44b1"
        },
        // ".MuiOutlinedInput-notchedOutline": {
        //   border: "1px solid #dcdbdd !important"
        // },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: "2px", }}
          >
            <Select
              // autoWidth={true}
              MenuProps={{
                style: {
                  height: "300px",
                  top: "10px",
                  left: window.innerWidth > 750 ? "-34px" : "0px"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                }
              }}
              sx={{
                width: "100% !important",
                boxShadow: "none !important",
                fieldset: {
                  display: "none"
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: "block"
                  }
                },
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none !important",
                  boxShadow: "none !important",
                },
                ".MuiInputBase-root:not(.MuiInput-underline).Mui-focused": {
                  boxShadow: "none !important",
                },
                ".MuiSelect-select": {
                  padding: "8px",
                  paddingRight: "24px !important",
                },
                svg: {
                  right: 0
                }
              }}
              value={country.iso2}
              disabled={disabled}
              onChange={(e) => setCountry(e.target.value)}
              renderValue={(value) => (
                <FlagImage iso2={value} style={{ display: "flex", width: "25px" }} />
              )}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagImage
                      iso2={country.iso2}
                      style={{ marginRight: "8px", width: "25px" }}
                    />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        )
      }}
    />
  )
}

export default CustomPhoneNumberInput
