import React from 'react'
import { useLocation } from 'react-router-dom';
import Style from "./DocumentPreviewPage.module.css";

const DocumentPreviewPage = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const urlParam = queryParams.get('url');

    return (
        <>
            <iframe className={Style.iframe} src={urlParam} title="Preview"></iframe>
        </>
    )
}

export default DocumentPreviewPage