import { httpService, HttpMethods } from "./global";


export default class ShipmentRequestService {

    static fetchShipmentRequest(data, token) {
        const url = `shipment-request/a/fetch`;
        return httpService(HttpMethods.POST, url, data, {
          token,
          type: 'shipment_request',
          method: 'read',
          platform: 'Admin'
        })
          .then((res) => {
            return res;
          })
          .catch((err) => console.log(err));
    }

    static addProposal(data, token) {
        const url = `shipment-request/a/proposals/add`;
        return httpService(HttpMethods.POST, url, data, {
          token,
          type: 'shipment_request',
          method: 'create',
          platform: 'Admin'
        })
          .then((res) => {
            return res;
          })
          .catch((err) => console.log(err));
    }


    static fetchProposals(data, token) {
        const url = `shipment-request/a/proposals/fetch`;
        return httpService(HttpMethods.POST, url, data, {
          token,
          type: 'shipment_request',
          method: 'read',
          platform: 'Admin'
        })
          .then((res) => {
            return res;
          })
          .catch((err) => console.log(err));
    }

    static deleteProposal(data, token) {
        const url = `shipment-request/a/proposal/delete/by-id`;
        return httpService(HttpMethods.POST, url, data, {
          token,
          type: 'shipment_request',
          method: 'delete',
          platform: 'Admin'
        })
          .then((res) => {
            return res;
          })
          .catch((err) => console.log(err));
    }


    static sendProposal(data, token) {
        const url = `shipment-request/a/proposal/send`;
        return httpService(HttpMethods.POST, url, data, {
          token,
          type: 'shipment_request',
          method: 'create',
          platform: 'Admin'
        })
          .then((res) => {
            return res;
          })
          .catch((err) => console.log(err));
    }

    static addToRateSheet(data, token) {
        const url = `shipment-request/a/proposal/add-to-rate-sheet`;
        return httpService(HttpMethods.POST, url, data, {
          token,
          type: 'shipment_request',
          method: 'create',
          platform: 'Admin'
        })
          .then((res) => {
            return res;
          })
          .catch((err) => console.log(err));
    }

}