import React from 'react'
import Style from "./SectionSubHeading.module.css";

const SectionSubHeading = ({ title, titleClass }) => {
    return (
        <div className={`${Style.heading} app-page-title--heading`}>
            <h1 className={`${Style.heading} ${titleClass}`}>{title}</h1>
        </div>
    )
}

export default SectionSubHeading