import React, { useEffect, useState } from 'react';
import AddServicePopup from './AddServicePopup/AddServicePopup';
import { useDispatch, useSelector } from 'react-redux';
import { Empty } from 'antd';
import { useHistory } from 'react-router-dom';
import ServiceManagementService from 'service/ServiceManagementService';
import ServiceTableWrapper from './ServiceTableWrapper/ServiceTableWrapper';
import Style from './ServiceManagement.module.css';
import ServiceTableRow from './ServiceTableWrapper/ServiceTableRow/ServiceTableRow';
import PageContent from 'sites/shipper/components/layout/PageContent';
import { Button, MenuItem, Tooltip } from '@material-ui/core';
import ModalPopupWrapper from 'components/Common/ModalPopup/ModalPopup';
import ConfirmDialog from '../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import { notify } from 'sites/shipper/components/Toast/toast';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import SkeletonComponent from 'sites/shipper/components/Skeleton/Skeleton';
import moment from 'moment';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import CachedIcon from '@mui/icons-material/Cached';
import { getCurrencyIcon, numberToLocaleString } from 'util/Shipment/shipment';

/* eslint-disable */
const ServiceManagement = () => {
  const auth = useSelector((state) => state.session.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const [services, setServices] = useState([]);
  const [run, setRun] = useState(false);
  const [loading, setLoading] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState("")
  const [servicesCount, setServicesCount] = useState(0);

  const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(parseInt(pageValue));
  const itemsPerPage = 15;

  const [check, setCheck] = useState(true);

  // useEffect(() => {
  //   if (loading) return;

  //   const element = document.getElementById('top');
  //   element.scrollIntoView({ behavior: 'smooth' });
  // }, [check]);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/service-management?page=${value}`);
  }

  useEffect(() => {
    setLoading(true);
    ServiceManagementService.fetchService(
      auth.accountId,
      '',
      auth.token,
      page,
      search
    ).then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setServices(res?.data);
        setServicesCount(res?.servicesCount);
      }
      setLoading(false);
    });
  }, [run, page, search]);

  const handleSelect = (id) => {
    history.push(`/service-management/${id}`);
  };

  const [edit, setEdit] = useState({
    edit: false,
    editData: null
  });

  const handleEdit = (e, data) => {
    // e.stopPropagation()
    if (data) {
      setEdit({
        ...edit,
        edit: true,
        editData: data
      });
      setOpen(true);
    } else {
      setEdit({
        ...edit,
        edit: false,
        editData: data
      });
      setOpen(false);
    }
  };

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [deleteId, setDeleteId] = useState();

  const handleDeleteClick = (id) => {
    // e.stopPropagation()
    setDeleteId(id);
    setConfirmSubmit(true);
  };

  const handleDelete = () => {
    ServiceManagementService.deleteService(
      auth.accountId,
      deleteId,
      auth.token
    ).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.error);
      } else {
        notify(dispatch, 'success', res.message);
        setRun(!run);
      }
    });
  };

  const [open, setOpen] = useState(false);

  ////////////////////////////////////////////////////////////////////////////////////////////
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [moreOptionClicked, setMoreOptionClicked] = useState(null);

  const TableHeading = [
    'Icon',
    'Service Name',
    'Service Description',
    'Default Price',
    'Created by',
    'Created at',
    'Action'
  ];

  const Keys = [
    'icon',
    'serviceName',
    'serviceDescription',
    'default_price',
    'created_by',
    'created_at',

    '_id'
  ];

  const TableData = services?.map((item, index) =>
    Keys.map((Key) => {
      if (Key === 'index') {
        return index + 1;
      }

      if (Key === 'created_at') {
        return moment(item.created_at).format('ll');
      }

      if (Key === 'default_price') {
        return (
          <>
            {getCurrencyIcon(item.default_price.currency)}{numberToLocaleString(item.default_price.value)} {item.default_price.currency.toUpperCase()}
          </>
        );
      }

      if (Key === 'icon') {
        return (
          <div style={{
            backgroundColor: '#4431BB',
            width: 'max-content',
            borderRadius: '10px'
          }}>
            {item?.icon ? (
              <img

                src={item.icon}
              />
            ) : (
              'no image'
            )}
          </div>
        );
      }

      if (Key === '_id') {
        return (
          <>
            <div className="position-relative">
              <center>
                <Tooltip title="More" arrow>
                  <Button
                    variant="text"
                    className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
                    onClick={(e) => {
                      setOpenMoreOptions(e.currentTarget);
                      setMoreOptionClicked(item);
                    }}>
                    <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                  </Button>
                </Tooltip>
              </center>
              {moreOptionClicked?._id === item?._id && (
                <SmallPopupWrapper
                  open={openMoreOptions}
                  onClose={() => {
                    setMoreOptionClicked(null);
                    setOpenMoreOptions(null);
                  }}>
                  <MenuItem
                    onClick={() => {
                      handleSelect(moreOptionClicked?._id);
                      setOpenMoreOptions(null);
                    }}>
                    View
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      handleEdit(e, item);

                      setOpenMoreOptions(null);
                    }}>
                    Edit
                  </MenuItem>
                  {/* <MenuItem onClick={() => { toggleRightDrawer(true); setOpenMoreOptions(null); }}>Edit Route</MenuItem> */}
                  <MenuItem
                    onClick={() => {
                      setOpenMoreOptions(null);
                      handleDeleteClick(moreOptionClicked?._id);
                      // setConfirmDialogOpen(true);
                    }}>
                    Delete
                  </MenuItem>
                </SmallPopupWrapper>
              )}
            </div>
          </>
        );
      } else {
        return item[Key] || '';
      }
    })
  );

  return (
    <>
      {/* {loading && <LoaderComponent loading={loading} />} */}

      {/* <AddServicePopup run={run} setRun={setRun} /> */}
      <PageTitle titleHeading={`Service Management`}></PageTitle>

      <PageContent>
        {/* 
                    <ServiceTableWrapper
                        onAdd={() => { setOpen(true); }}
                        tableHeaders={[
                            "Icon",
                            "Service Name",
                            "Service Description",
                            "Default Price",
                            "Created by",
                            'Created at',
                            'Action']}
                    >
                        {loading && <SkeletonComponent height='60px' />}
                        {loading === false && (services.length > 0 ? services.map((service, index) => (
                            <ServiceTableRow data={service} key={index} handleSelect={handleSelect} handleDelete={handleDeleteClick} handleEdit={handleEdit} />
                        )): <Empty />)}
                    </ServiceTableWrapper> */}

        <TableWithSearchBar
          animatedSearchbar={{
            show: true,
            value: search,
            onChange: (e) => setSearch(e.target.value)
          }}
          loading={loading}
          TableHeading={TableHeading}
          TableBody={TableData}
          TableGrid={'repeat(7,1fr)'}
          actionButtons={[
            <Button
              variant="text"
              onClick={() => setRun(!run)}
              className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
              <CachedIcon className="w-50" />
            </Button>,
            <Button
              className={`btn-primary`}
              onClick={() => {
                setOpen(true);
              }}>
              Add Service
            </Button>
          ]}
          pagination={{
            count: Math.ceil(servicesCount / itemsPerPage),
            page: page,
            onChange: handlePageChange
          }}
        />
      </PageContent>

      <AddServicePopup
        open={open}
        run={run}
        edit={edit.edit}
        editData={edit.editData}
        handleEdit={handleEdit}
        setRun={setRun}
        onClose={() => setOpen(false)}
      />

      <ConfirmDialog
        confirmDialogFunction={handleDelete}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure to delete service`}
      />
    </>
  );
};
/* eslint-enable */
export default ServiceManagement;

{
  /* <table border={1}>
                        <thead>
                            <tr>
                                <th>Icon</th>
                                <th>Service Name</th>
                                <th>Service Description</th>
                                <th>Default Price (in usd)</th>
                                <th>Created by</th>
                                <th>Created at</th>
                            </tr>
                        </thead>
                        <tbody>
                             <tr key={index} onClick={() => handleSelect(service._id)}>
                            <td><img src={service.icon} width={100} height={100} /></td>
                            <td>{service.serviceName}</td>
                            <td>{service.serviceDescription}</td>
                            <td>{service.default_price}</td>
                            <td>{service.created_by}</td>
                            <td>{service.created_at.split("T")[0]}</td>
                        </tr>
                        </tbody>
                    </table> */
}
