import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class MyShipperService {
  static async fetchMyShippers(data, token, page) {
    const url = `admin/shipper/fetch?&status&page=${page}`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipper',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async addMyShipper(id, data, token) {
    const url = `admin/shipper/create?id=${id}`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipper',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async upload_kyc_documents(id, data, token) {
    const url = `admin/shipper/upload-kyc-doc?id=${id}`;

    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'shipper',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchMyShipperById(id, token) {
    const url = `admin/shipper/fetch-by-id?id=${id}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'shipper_managment',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async verifyMyShipperById(data, token) {
    const url = `admin/shipper/verify-shipper`;

    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'shipper',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async saveShipperDetails(id, data, token, type) {
    const url = `admin/shipper/save-shipper-details?id=${id}`;

    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'shipper',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async inviteShipper(data, token) {
    const url = `admin/shipper/invite-shipper`;

    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'shipper',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async rejectShipper(data, token) {
    const url = `admin/shipper/reject-shipper`;

    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'shipper',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchShipperByAccountStatus(status, token) {
    const url = `admin/shipper/fetch-by-account-status?status=${status}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'shipper',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchShipments(data, token) {
    const url = `admin/shipper/fetch-shipments`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipper_managment',
      method: 'shipments',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  // static update_shipper_account_status(data,token) {
  //     const url =`admin/shipper/update-shipper-account-status`
  //     return HttpService.request(HttpMethods.PUT, url,data , token).then(
  //       (res) => res
  //     );
  // }

  static async update_shipper_account_status(data, token, type) {
    const url = `admin/shipper/update-shipper-account-status`;

    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'shipper',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
  static async fetchTransactions(data, token) {
    const url = `admin/shipper/fetch-transactions`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipper_managment',
      method: 'transactions',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchShipperTeam(id,cmpyId, token) {
    const url = `admin/shipper/fetch-team-members?id=${id}&cmpyId=${cmpyId}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'shipper_managment',
      method: 'team',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
}
