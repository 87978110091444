import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Menu, Button, List, ListItem } from '@material-ui/core';

// import avatar3 from 'assets/images/avatars/avatar3.jpg';
import CountUp from 'react-countup';

// import BusinessCenterTwoToneIcon from '@material-ui/icons/BusinessCenterTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import placeholderAvatar from 'assets/images/avatars/placeholder-avatar.jpeg';
import { withStyles } from '@material-ui/core/styles';
import {
  AccountBalanceWalletTwoTone,
  AccountCircleTwoTone,
  BusinessTwoTone
} from '@material-ui/icons';
import AccountCmpyLinkType from 'model/enum/AccountCmpyLinkType';
import { CommonModel } from 'model';
import { userPermission } from 'util/adminPermissionRoles';
import CustomAvatar from 'sites/shipper/components/CustomAvatar/CustomAvatar';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
})(Badge);

const styles = () => ({});

const HeaderUserbox = ({ classes, auth, logout, userInfo }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const doLogout = () => {
    const token = CommonModel.createUserTokenObj(auth).token;
    logout(token);
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        className="btn-transition-none text-left ml-2 mr-4 p-0 bg-transparent d-flex align-items-center"
        disableRipple>
        <div className="d-none d-xl-block mr-2" style={{ textAlign: "right" }}>
          {userInfo && (
            <div className="font-weight-bold">
              {userInfo.firstName}
            </div>
          )}
          <span className="text-black-50">
            <small>
              {auth && auth.accountCmpyLinkType
                ? auth.accountCmpyLinkType
                : 'Shipper'}
            </small>
          </span>
        </div>
        <div className="d-block p-0 avatar-icon-wrapper">
          {/* <StyledBadge
            // overlap="circle"
            // anchorOrigin={{
            //   vertical: 'bottom',
            //   horizontal: 'right'
            // }}
            badgeContent=" "
            classes={{ badge: 'bg-success badge-circle border-0' }}
            variant="dot"> */}
          {/* <div className="avatar-icon rounded" style={{ width: "35px", height: "35px" }}>
            <img
              src={
                userInfo && userInfo.avatar
                  ? userInfo.avatar.url
                  : placeholderAvatar
              }
              alt="..."
              width="50px"
              height="50px"
            />
          </div> */}
          <CustomAvatar
            className="avatar-icon rounded"
            height={35} width={35}
            name={auth?.firstName}
            src={userInfo?.avatar?.url}
          />
          {/* </StyledBadge> */}
        </div>

        {/* {userInfo && (
          <div className="d-none d-xl-block pl-2">
            <div className="font-weight-bold pt-2 line-height-1">
              {userInfo.firstName} {userInfo.lastName}
            </div>
            <span className="text-black-50">
              {userInfo.role ? userInfo.role : 'Shipper'}
            </span>
          </div>
        )} */}
        {/* pl-xl-3 */}
        <span className="pl-1">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={handleClose}>
        <div className="dropdown-menu-xl overflow-hidden p-0">
          <div className="d-flex p-4 bg-secondary">
            {/* <div className="avatar-icon flex-shrink-0 rounded mr-3">
              <img
                src={
                  userInfo && userInfo.avatar
                    ? userInfo.avatar.url
                    : placeholderAvatar
                }
                alt="..."
              />
            </div> */}
            <CustomAvatar
                className="avatar-icon flex-shrink-0 rounded mr-3"
                height={35} width={35}
                name={auth?.firstName}
                src={userInfo?.avatar?.url}
              />
            {userInfo && (
              <div>
                <h6 className="font-weight-bold mb-1 text-black">
                  {userInfo.firstName} {userInfo.lastName}
                </h6>
                <p className="text-gray-700 mb-0">{userInfo.email}</p>
              </div>
            )}
          </div>
          {/* <div className="divider" />
          <div className="divider" /> */}
          {/* {auth && auth.accountCmpyLinkType === AccountCmpyLinkType.ADMIN && (
            <div className="bg-kargo-blue d-flex align-items-center flex-column py-4">
              <div className="display-3 mb-0 text-center font-weight-bold text-white">
                <small className="opacity-6">$</small>
                <span className="pl-1">
                  <CountUp
                    start={0}
                    end={0}
                    duration={2}
                    separator=""
                    delay={1}
                    decimals={0}
                    decimal=","
                    prefix=""
                    suffix=""
                  />
                  <small>
                    <sup>.00</sup>
                  </small>
                </span>
              </div>
              <small className="text-center font-weight-bold opacity-6 text-uppercase">
                Kargoplex Cash
              </small>
            </div>
          )} */}
          <div className="divider" />
          <List
            component="div"
            className="nav-neutral-first nav-pills-rounded flex-column p-3">
            <ListItem component="a" button href="/settings/account">
              <div className="mr-2">
                <AccountCircleTwoTone />
              </div>
              <span className="font-size-md">My Profile</span>
            </ListItem>
            {/* {userPermission.company_settings.read.includes(auth.accountCmpyLinkType) && (
              <>
                <ListItem component="a" button href="/settings/company-info">
                  <div className="mr-2">
                    <BusinessTwoTone />
                  </div>
                  <span className="font-size-md">Company</span>
                </ListItem>
             <ListItem component="a" button href="/wallet">
                  <div className="mr-2">
                    <AccountBalanceWalletTwoTone />
                  </div>
                  <span className="font-size-md">My Wallet</span>
                </ListItem>
              </>
            )} */}
            <ListItem component="a" button href="/settings">
              <div className="mr-2">
                <SettingsTwoToneIcon />
              </div>
              <span className="font-size-md">Settings</span>
            </ListItem>
          </List>
          <div className="divider" />
          <List
            component="div"
            className="nav-neutral-danger nav-pills-rounded flex-column p-3">
            <ListItem component="a" button onClick={doLogout}>
              <div className="mr-2">
                <ExitToAppTwoToneIcon />
              </div>
              <span>Log out</span>
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
};

HeaderUserbox.propTypes = {
  auth: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
};

export default withStyles(styles)(HeaderUserbox);
