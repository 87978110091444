const AccountType = Object.freeze({
  ADMIN: "ADMIN"
  // SYSTEM: 'SYSTEM',
  // APP_ADMIN: 'APP_ADMIN',
  // FORWARDER: 'FORWARDER',
  // SHIPPER: 'SHIPPER',
  // BROKER: 'BROKER'
});

export default AccountType;
