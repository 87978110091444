import { Input, Select } from "antd";
import Style from "./AddRateSheetForm.module.css";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ForwarderService } from "service";
import { notify } from "sites/shipper/components/Toast/toast";
import { useDispatch } from 'react-redux'
import RateSheetService from "service/RateSheetService";
import CountrySelectNew from "components/CountrySelect/CountrySelectNew";
import StateSelect from "components/CountrySelect/StateSelect";
import CitySelect from "components/CountrySelect/CitySelect";
import PortCodeSelect from "components/CountrySelect/PortCodeSelect";
import { TRANSPORT_MODES_LIST } from 'util/constants';
import StyledSelect from "components/StyledSelect";
/* eslint-disable */
const AddRateSheetForm = ({ editData, run, setRun, toggleRightDrawer }) => {

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.session.auth)


    const [data, setData] = useState({
        origin: {
            state: "",
            port: "",
            city: "",
            country: ""
        },
        destination: {
            state: "",
            port: "",
            city: "",
            country: ""
        },
        transport_mode: ""
    })

    const handleValue = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (editData) {
            setData({
                ...data,
                origin: {
                    ...data.origin,
                    state: editData.origin.state,
                    port: editData.origin.port,
                    city: editData.origin.city,
                    country: editData.origin.country,
                },
                destination: {
                    ...data.destination,
                    state: editData.destination.state,
                    port: editData.destination.port,
                    city: editData.destination.city,
                    country: editData.destination.country,
                },
                transport_mode: editData.transportMode
            })
        } else {
            setData({
                ...data,
                origin: {
                    ...data.origin,
                    state: "",
                    port: "",
                    city: "",
                    country: "",
                },
                destination: {
                    ...data.destination,
                    state: "",
                    port: "",
                    city: "",
                    country: "",
                },
                transport_mode: ""
            })
        }
    }, [editData])
    const [error, setError] = useState("")
    const handleSubmit = (e) => {
        e.preventDefault()
        if (
            data.origin.state === "" ||
            data.origin.port === "" ||
            data.origin.city === "" ||
            data.origin.country === "" ||
            data.destination.state === "" ||
            data.destination.port === "" ||
            data.destination.city === "" ||
            data.destination.country === "" ||
            data.transport_mode === ""
        ) {
            setError("All Fields are mandatory")
            return
        }
        if (editData) {
            RateSheetService.editRateSheet(auth.accountId, editData._id, data, auth.token).then(data => {
                if (data.message) {
                    // window.alert(data.message)
                    notify(dispatch, "success", data.message)
                    setRun(!run)
                    toggleRightDrawer(false)
                }
                else {
                    // window.alert(data.error)
                    notify(dispatch, "error", data.error)
                }
            })
        } else {
            RateSheetService.createRateSheet(auth.accountId, data, auth.token).then(data => {
                if (data.message) {
                    // window.alert(data.message)
                    notify(dispatch, "success", data.message)
                    setRun(!run)
                    toggleRightDrawer(false)
                }
                else {
                    // window.alert(data.error)
                    notify(dispatch, "error", data.error)
                }
            })
        }
    }


    const transportModeList = [
        {
            value: 'AIR',
            label: 'AIR',
        },
        {
            value: 'SEA',
            label: 'SEA',
        },
    ]

    const handleTranportMode = (value) => {
        setData({
            ...data,
            transport_mode: value
        })
    }
    const handleCountry = (opt, name) => {
        console.log(opt)
        if (opt) {
            setData({
                ...data,
                [name]: {
                    ...data[name],
                    country: opt,
                    state: "",
                    city: "",
                    port: ""
                }
            })
        } else {
            setData({
                ...data,
                [name]: {
                    ...data[name],
                    country: "",
                    state: "",
                    city: "",
                    port: ""
                }
            })
        }
    }


    const handleState = (opt, name) => {
        if (opt) {
            setData({
                ...data,
                [name]: {
                    ...data[name],
                    state: opt,
                    city: "",
                    port: ""
                }
            })
        } else {
            setData({
                ...data,
                [name]: {
                    ...data[name],
                    state: "",
                    city: "",
                    port: ""
                }
            })
        }
    }

    const handleCity = (opt, name) => {
        if (opt) {
            setData({
                ...data,
                [name]: {
                    ...data[name],
                    city: opt,
                    port: ""
                }
            })
        } else {
            setData({
                ...data,
                [name]: {
                    ...data[name],
                    city: "",
                    port: ""
                }
            })
        }
    }

    const handlePort = (opt, name) => {
        if (opt) {
            setData({
                ...data,
                [name]: {
                    ...data[name],
                    port: opt
                }
            })
        } else {
            setData({
                ...data,
                [name]: {
                    ...data[name],
                    port: ""
                }
            })
        }
    }

    return (
        <div className={Style.container}>
            <div className={`d-flex align-items-center my-2`}>
                <span className="font-weight-bold font-size-lg">{editData ? "Edit" : "Create"} Route</span>
            </div>
            <div className="divider my-3" />
            <div>
                <span className="font-size-md font-weight-bold">Select Transport Mode</span>
            </div>
            <div className={Style.OneFieldContainer}>
                <StyledSelect
                    label="Select Transport Mode"
                    name="transport_mode"
                    margin="0"
                    value={data.transport_mode}
                    onChange={(e) =>
                        setData({ ...data, transport_mode: e.target.value })
                    }
                    size="small"
                    options={TRANSPORT_MODES_LIST &&
                        TRANSPORT_MODES_LIST.map((item, index) => {
                            return {
                                label: item.label,
                                value: item.value
                            }
                        })}
                />
            </div>
            <div>
                <span className="font-size-md font-weight-bold">Select Origin Port</span>
            </div>
            <div className={Style.TwoFieldContainer}>
                <CountrySelectNew
                    useDefault
                    isClearable={true}
                    isMulti={false}
                    onChange={(e, opt) => handleCountry(opt, 'origin')}
                    showError={false}
                    value={data.origin.country}
                />
                <StateSelect
                    country={data.origin.country?.code}
                    value={data.origin.state}
                    showError={false}
                    handleChange={(e, opt) => handleState(opt, 'origin')}
                />
                <CitySelect
                    value={data.origin.city}
                    state={data.origin.state?.code}
                    showError={false}
                    handleChange={(e, opt) => handleCity(opt, 'origin')}
                />
                <PortCodeSelect
                    value={data.origin.port}
                    city={data.origin.state?.code}
                    transportMode={data.transport_mode}
                    showError={false}
                    handleChange={(e, opt) => handlePort(opt, 'origin')}
                />
            </div>
            <div>
                <span className="font-size-md font-weight-bold">Select Destination Port</span>
            </div>
            <div className={Style.TwoFieldContainer}>
                <CountrySelectNew
                    useDefault
                    isClearable={true}
                    isMulti={false}
                    onChange={(e, opt) => handleCountry(opt, 'destination')}
                    showError={false}
                    value={data.destination.country}
                />
                <StateSelect
                    country={data.destination.country?.code}
                    value={data.destination.state}
                    showError={false}
                    handleChange={(e, opt) => handleState(opt, 'destination')}
                />
                <CitySelect
                    value={data.destination.city}
                    state={data.destination.state?.code}
                    showError={false}
                    handleChange={(e, opt) => handleCity(opt, 'destination')}
                />
                <PortCodeSelect
                    value={data.destination.port}
                    city={data.destination.state?.code}
                    transportMode={data.transport_mode}
                    showError={false}
                    handleChange={(e, opt) => handlePort(opt, 'destination')}
                />
            </div>

            {error && <p style={{ fontSize: "12px", color: "red" }}>{error}</p>}
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                    style={{ textTransform: 'none', backgroundColor: '#4431bb' }}
                    variant="contained"
                    size="large"
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </div>
            {/* <form
                className={Style.form_container}
            >
                <legend>Rate Sheet</legend>

                <div className={Style.input_container}>
                    <label htmlFor="origin-port-id" className={Style.label} >Origin Port Id</label>
                    <Input
                        required
                        value={data.origin_port_id}
                        id="origin-port-id"
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="origin_port_id"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="origin-port" className={Style.label} >Origin Port</label>
                    <Input
                        required
                        value={data.origin_port}
                        id="origin-port"
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="origin_port"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="origin-city" className={Style.label} >Origin City</label>
                    <Input
                        required
                        id="origin-city"
                        value={data.origin_city}
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="origin_city"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="origin-country-code" className={Style.label} >Origin Contry Code</label>
                    <Input
                        required
                        id="origin-country-code"
                        value={data.origin_country_code}
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="origin_country_code"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="destination-port-id" className={Style.label} >Destination Port Id</label>
                    <Input
                        value={data.destination_port_id}
                        required
                        id="destination-port-id"
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="destination_port_id"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="destination-port" className={Style.label} >Destination Port</label>
                    <Input
                        value={data.destination_port}
                        required
                        id="destination-port"
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="destination_port"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="destination-city" className={Style.label} >Destination City</label>
                    <Input
                        required
                        value={data.destination_city}
                        id="destination-city"
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="destination_city"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="destination-country-code" className={Style.label}>Destination Country Code</label>
                    <Input
                        required
                        value={data.destination_country_code}
                        id="destination-country-code"
                        className={Style.input}
                        placeholder="Bottles ltd."
                        onChange={handleValue}
                        name="destination_country_code"
                    />
                </div>
                <div className={Style.input_container}>
                    <label htmlFor="destination-country-code" className={Style.label}>Transport Mode</label>
                    <Select
                        value={data.transport_mode ? data.transport_mode : "Select Transport Mode"}
                        options={transportModeList}
                        onChange={handleTranportMode}
                        placeholder={"Select Transport Mode"}
                    />
                </div>

                {error && <p style={{ fontSize: "12px", color: "red" }}>{error}</p>}
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        style={{ textTransform: 'none', backgroundColor: '#4431bb' }}
                        variant="contained"
                        size="large"
                        onClick={handleSubmit}
                    >
                        Save
                    </Button>
                </div>



            </form> */}
        </div>
    )
}
/* eslint-enable */
export default AddRateSheetForm