import Style from "./TopBar.module.css";
import { Button, FormControl, MenuItem, Select } from '@material-ui/core';
import { useState } from "react";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import { getSectionName } from "../../../RateSheetContent";

/* eslint-disable */

const title_options = [
  { value: 'jack', label: 'Jack' },
  { value: 'lucy', label: 'Lucy' },
  { value: 'Yiminghe', label: 'yiminghe' },
]


const TopBar = ({ breakdownData, setBreakdownData, addSection, handleReset }) => {

  const [title, setTitle] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);


  return (
    <div className={Style.container}>
      <label htmlFor="title" className={Style.title}>Title</label>

      <div className={Style.main}>
        {/* <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className={Style.input}
                /> */}

        <FormControl fullWidth variant="standard">
          <Select
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          >
            {getSectionName(breakdownData.price_breakdown).map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
            {/* <MenuItem value={20}>Twenty</MenuItem> */}
            {/* <MenuItem value={30}>Thirty</MenuItem> */}
          </Select>
        </FormControl>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "1rem" }}>
          <Button
            variant="contained"
            className={Style.btns}
            disabled={!title}
            onClick={() => { setTitle(""); addSection(title)}}
          >
            Add Section
          </Button>
          {/* 
        <Button
          variant="contained"
          className={Style.btns}
        >
          Use Previous Template
        </Button> */}

          <Button
            variant="contained"
            className={Style.reset_btn}
            onClick={() => setConfirmDialogOpen(true)}
          >
            Reset
          </Button>
        </div>
      </div>

      <ConfirmDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        confirmDialogFunction={handleReset}
        question={"Are you sure you want to reset this rate-sheet?"}
      />



    </div>
  )
}
/* eslint-disable */
export default TopBar




