import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Header from './Header';
import { setSidebarToggle } from '../../../../../reducers/ThemeOptions';
import { logout } from '../../../../../actions';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
      setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable))
    },
    dispatch
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  setSidebarToggle: state.ThemeOptions.sidebarToggleMobile
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
