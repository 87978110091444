import React from 'react'
import Style from "./UserProfileBox.module.css";
import avatar from 'assets/images/avatars/avatar3.jpg';
import DirectionsBoatFilledTwoToneIcon from '@mui/icons-material/DirectionsBoatFilledTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { addressToString } from 'util/Utils';
import CustomAvatar from 'sites/shipper/components/CustomAvatar/CustomAvatar';

const UserProfileBox = ({ profileData }) => {
    return (
        <div className={Style.container}>
            <div className={Style.left}>
                <div className={Style.user_box}>
                    {/* <div className={Style.user_avatar}>
                        <img src={profileData?.avatar} alt="avatar" />
                        <div className={Style.avatar_check}>
                            <Check style={{ color: 'white', fontSize: '15px' }} />
                        </div>
                    </div> */}
                    <CustomAvatar
                        className={Style.user_avatar}
                        height={100} width={100}
                        sx={{ fontSize: "40px" }}
                        name={profileData?.name}
                        src={profileData?.avatar}
                    />
                    <div className={Style.user_name_box}>
                        <span className={Style.user_name}>{profileData?.name}</span>
                        <span className={Style.gray_text}>{profileData?.role}</span>
                    </div>
                </div>
                {/* <div className={`d-flex flex-column justify-content-between c-gap-1`}>
                    <div className={`${Style.data_box} ${Style.shipment_data}`}>
                        <DirectionsBoatFilledTwoToneIcon className='text-primary' />
                        <span>Shipments</span>
                        <strong>30</strong>

                    </div>
                    <div className={`${Style.data_box} ${Style.shippers_data}`}>
                        <PeopleAltTwoToneIcon style={{ color: '#4E98FE' }} />
                        <span>Shippers</span>
                        <strong>26</strong>
                    </div>
                </div> */}
            </div>
            <div className={Style.right}>
                {/* <div className={Style.rightInner} style={{width:"30%"}}>
                    <p className={Style.rightInner_heading}>Email</p>
                    <p className={Style.rightInner_heading}>Phone Number</p>
                    <p className={Style.rightInner_heading}>Date of Birth</p>
                    <p className={Style.rightInner_heading}>Joining Date</p>
                    <p className={Style.rightInner_heading}>Gender</p>
                </div>
                <div className={Style.rightInner} style={{width:"70%"}}>
                    <span className={Style.rightInner_description}>{profileData?.email}</span>
                    <span className={Style.rightInner_description}>{profileData?.phoneNumber}</span>
                    <span className={Style.rightInner_description}>{profileData?.date_of_birth}</span>
                    <span className={Style.rightInner_description}>{profileData?.joining_date}</span>
                    <span className={Style.rightInner_description}>{profileData?.gender}</span>
                </div> */}
                <div className={Style.rightInner}>
                    <span className={Style.rightInner_heading}>Email</span>
                    <span className={Style.rightInner_description}>{profileData?.email}</span>
                </div>
                <div className={Style.rightInner}>
                    <span className={Style.rightInner_heading}>Phone Number</span>
                    <span className={Style.rightInner_description}>{profileData?.phoneNumber}</span>
                </div>
                <div className={Style.rightInner}>
                    <span className={Style.rightInner_heading}>Date of Birth</span>
                    <span className={Style.rightInner_description}>{profileData?.date_of_birth}</span>
                </div>
                <div className={Style.rightInner}>
                    <span className={Style.rightInner_heading}>Joining Date</span>
                    <span className={Style.rightInner_description}>{profileData?.joining_date}</span>
                </div>
                {/* <div className={Style.rightInner}>
                    <span className={Style.rightInner_heading}>Gender</span>
                    <span className={Style.rightInner_description}>{profileData?.gender}</span>
                </div> */}
                {/* <div className='d-flex justify-content-end'>
                    <Button
                        className='btn-outline-primary'
                        startIcon={<EmailOutlinedIcon />}
                        size='small'
                    >
                        Send Mail
                    </Button>
                </div> */}
            </div>
        </div>
    )
}

export default UserProfileBox