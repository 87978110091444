import Style from "./ViewBreakdown.module.css";
import React, { useEffect, useState } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EastIcon from '@mui/icons-material/East';
import TopBar from "./TopBar/TopBar";
import Preview from "./Preview/Preview";
import { IconButton } from "@mui/material";
import { Divider } from "@material-ui/core";
import Summary from "./Summary/Summary";
// import { CreateShipment, ForwarderService } from "service";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ImportantNote from "./ImportantNote/ImportantNote";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { notify } from "sites/shipper/components/Toast/toast";
import { useDispatch } from 'react-redux';
import RateSheetService from "service/RateSheetService";
import { ForwarderService, ShipmentRequestService } from "service";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import LoaderComponent from "sites/shipper/components/LoaderComponent";
import moment from "moment";
import { chargeDetails, initialPriceBreakDown, sectionName } from "../../RateSheetContent";
import { loadDisplay } from "util/Shipment/shipmentLoads";

/* eslint-disable */

const ViewBreakdown = ({
    pageData,
    run,
    setRun,
    method,
    open,
    handleClose,
    data,
    data_id,
    isMakeOffer,
    chatEditOffer = false,
    editData,
    handleEditOfferCard,
    load_type = null,
    route_id = ""
}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [additionalData, setAdditionalData] = useState({
        loadType: null,
        containerType: null,
        carrier_id: null,
        carrier_vessels: null,
        tag: [],
        arrival_date: null,
        departureDate: null,
        source_id: null
    })
    const handleAdditionalData = (name, value) => {
        // const { name, value } = e.target
        setError("")
        setAdditionalData({
            ...additionalData,
            [name]: value
        })
    }

    // console.log("dmhbvjh", additionalData)

    const [breakdownData, setBreakdownData] = useState({
        transit_time: {
            estimate: {
                min: null,
                max: null,
                unit: "Days"
            },
            port_to_port: {
                min: null,
                max: null,
                unit: "Days"
            },
            are_both_equal: false
        },
        effective_date: null,
        expiry_date: null,
        price_breakdown: initialPriceBreakDown(isMakeOffer ? pageData?.transport_mode : data.transportMode, pageData),
        minAmount: {
            value: 0,
            currency: "usd"
        },
        maxAmount: {
            value: 0,
            currency: "usd"
        }
    })


    // this function resets the rate sheet to default 
    const handleReset = () => {
        setBreakdownData({
            ...breakdownData,
            price_breakdown: initialPriceBreakDown(isMakeOffer ? pageData?.transport_mode : data.transportMode, pageData)
        })
    }

    const auth = useSelector((state) => state.session.auth)


    const [createPriceResponse, setCreatePriceResponse] = useState({
        isNewPrice: false,
        msg: ""
    })
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")




































    // create new rate sheet


    const createRateSheet = () => {
        setLoading(true)
        const data = {
            route_id: route_id,
            load_type: additionalData.loadType,
            carrier: additionalData.carrier_id,
            container_type: additionalData.containerType,
            carrier_vessels: additionalData.carrier_vessels,
            rate_sheet_data: breakdownData,
            tag: additionalData.tag,
            arrival_date: additionalData.arrival_date,
            departureDate: additionalData.departureDate,
            source_id: additionalData.source_id

            // isNewPrice: createPriceResponse.isNewPrice
        }
        RateSheetService.saveRateSheetPriceInfo(auth.accountId, data, auth.token).then(data => {
            if (data.message) {
                notify(dispatch, "success", data.message)
                history.replace(`/rate-sheet/${route_id}`)
            } else {
                notify(dispatch, "error", data.error)
            }
            setLoading(false)
        })
    }



















    // // this handles the submit of whole rate sheet


    const handleSubmit = () => {
        setLoading(true)
        if (isMakeOffer && pageData) {

            if (
                !additionalData.carrier_id ||
                !breakdownData.effective_date ||
                !breakdownData.transit_time.estimate.min ||
                !breakdownData.transit_time.estimate.max ||
                !breakdownData.transit_time.port_to_port.min ||
                !breakdownData.transit_time.port_to_port.max
            ) {
                setError("Please fill in all the required fields marked with *")
                // notify(dispatch, "error", "Please Select Required Fields")
                setLoading(false)
                return
            }


            const data = {
                carrier: additionalData.carrier_id,
                carrier_vessels: additionalData.carrier_vessels,
                shipper_id: pageData.shipper._id,
                created_by: auth.accountId,
                shipment_request_id: data_id,
                // transit_time: breakdownData.transit_time,
                charges: breakdownData,
                tag: additionalData.tag,
                arrival_date: additionalData.arrival_date,
                departureDate: additionalData.departureDate,
                source_id: additionalData.source_id,

                // expiry_date: breakdownData.expiry_date,
                // effective_date: breakdownData.effective_date,
            }
            // console.log(data);
            ShipmentRequestService.addProposal(data, auth.token).then(data => {
                if (data.message) {
                    notify(dispatch, "success", data.message)
                    // history.replace('/shipment-request')
                    handleClose()
                } else {
                    notify(dispatch, "error", data.error)
                }
                setLoading(false)
            })
        }
        else {
            // const data = {
            //     route_id: route_id,
            //     load_type: additionalData.loadType,
            //     shipping_line: additionalData.carrier_id,
            //     container_type: additionalData.containerType,
            //     carrier_vessels: additionalData.carrier_vessels,
            //     rate_sheet_data: breakdownData
            // }
            if (
                !additionalData.loadType ||
                !additionalData.carrier_id ||
                !additionalData.containerType ||
                // !additionalData.type ||
                !breakdownData.effective_date ||
                !breakdownData.transit_time.estimate.min ||
                !breakdownData.transit_time.estimate.max ||
                !breakdownData.transit_time.port_to_port.min ||
                !breakdownData.transit_time.port_to_port.max
            ) {
                setError("Please fill in all the required fields marked with *")
                // notify(dispatch, "error", "Please Select Required Fields")
                setLoading(false)
                return
            }
            if (method === "edit") {
                const data_to_edit = {
                    admin_id: auth.accountId,
                    price_id: editData._id,
                    // price_breakdown: breakdownData.price_breakdown
                    load_type: additionalData.loadType,
                    carrier: additionalData.carrier_id,
                    container_type: additionalData.containerType,
                    carrier_vessels: additionalData.carrier_vessels,
                    rate_sheet_data: breakdownData,
                    tag: additionalData.tag,
                    arrival_date: additionalData.arrival_date,
                    departureDate: additionalData.departureDate,
                    source_id: additionalData.source_id,

                }
                RateSheetService.editRateSheetPriceInfo(data_to_edit, auth.token).then(data => {
                    if (data.message) {
                        // window.alert(data.message)
                        notify(dispatch, "success", data.message)
                        handleReset();
                        handleClose();
                        setRun(!run);
                    } else {
                        notify(dispatch, "error", data.error)
                        // window.alert(data.error)
                    }
                    setLoading(false)
                })
            } else {
                createRateSheet()
                // console.log("ldjhvjhb", data)
                // RateSheetService.checkIsNewPriceforType(auth.accountId, data, auth.token).then(data => {
                //     if (data.msg) {
                //         setCreatePriceResponse({
                //             ...createPriceResponse,
                //             isNewPrice: data.isNewPrice,
                //             msg: data.msg
                //         })
                //         setConfirmDialogOpen(true)
                //         // window.alert(data.message)
                //     } else {
                //         // window.alert(data.error)
                //         notify(dispatch, "error", data.error)

                //     }
                //     setLoading(false)
                // })
            }
        }
    }


    // console.log("dfjbkj", editData)
    // console.log("dfjbkj", additionalData)

    React.useEffect(() => {

        // console.log("abcs", editData)
        if (method === "edit" && editData) {
            setAdditionalData({
                ...additionalData,
                loadType: editData?.load_type,
                containerType: editData?.container_type,
                carrier_id: editData?.carrier_id?._id,
                carrier_vessels: editData?.carrier_vessels?._id,
                tag: editData?.tag,
                arrival_date: editData?.schedule_dates?.arrival_date,
                departureDate: editData?.schedule_dates?.departureDate,
                source_id: editData?.source_id,
            })

            setBreakdownData({
                ...breakdownData,
                transit_time: {
                    ...breakdownData.transit_time,
                    estimate: {
                        min: editData?.transit_time?.estimate?.min,
                        max: editData?.transit_time?.estimate?.max,
                        unit: editData?.transit_time?.estimate?.unit
                    },
                    port_to_port: {
                        min: editData?.transit_time?.port_to_port?.min,
                        max: editData?.transit_time?.port_to_port?.max,
                        unit: editData?.transit_time?.port_to_port?.unit
                    },
                    are_both_equal: editData?.transit_time?.are_both_equal ? editData?.transit_time?.are_both_equal : false
                },
                effective_date: editData?.load?.effectiveDate ? moment(editData?.load?.effectiveDate).format("YYYY-MM-DD") : null,
                expiry_date: editData?.load?.expiryDate ? moment(editData?.load?.expiryDate).format("YYYY-MM-DD") : null,
                price_breakdown: editData?.load?.priceBreakdown
            })
        }
        // console.log(data)
        // console.log(data.load.fcl['20FT'][(data.load.fcl['20FT']).length-1])
        // if (method === "edit" && data !== undefined) {
        //     if (data === undefined) {
        //         return
        //     }
        //     let type
        //     if (containerType === "20FT") {
        //         type = data.load.fcl['20FT'][(data.load.fcl['20FT']).length - 1]
        //     }
        //     if (containerType === "40FT") {
        //         type = data.load.fcl['40FT'][(data.load.fcl['40FT']).length - 1]
        //     }
        //     if (containerType === "40FT HC") {
        //         type = data.load.fcl['40FT HC'][(data.load.fcl['40FT HC']).length - 1]
        //     }
        //     if (containerType === "45FT HC") {
        //         type = data.load.fcl['45FT HC'][(data.load.fcl['45FT HC']).length - 1]
        //     }
        //     if (!type) {
        //         return
        //     }
        //     console.log(type);
        //     // setBreakdownData(type)
        //     setBreakdownData({
        //         ...breakdownData,
        //         effective_date: type?.effective_date?.split("T")[0],
        //         expiry_date: type?.expiry_date?.split("T")[0],
        //         price_breakdown: type?.price_breakdown
        //     })
        // }

    }, [editData])






    //   this adds a table section to preview 
    const addSection = (title) => {

        const obj = { title: title, data: [] }

        setBreakdownData((previousData) => ({
            ...previousData,
            price_breakdown: [...previousData.price_breakdown, obj]
        }))
    }






    useEffect(() => {
        if (pageData?.rate_sheet) {
            setBreakdownData({
                ...breakdownData,
                price_breakdown: pageData?.rate_sheet
            })
        }
    }, [pageData])
















































    // isMakeOffer ? Style.normal_container :

    const [isSubmitDisable, setIsSubmitDisable] = useState([])

    return (
        <>
            {loading && <LoaderComponent loading={loading} />}
            {open &&
                <div className={Style.popup_container}>
                    <div className={Style.overflow_container}>
                        <div className={Style.container}>

                            <div className={Style.nav_and_detail}>


                                <IconButton
                                    onClick={() => {
                                        if (handleClose) {
                                            handleClose()
                                        } else {
                                            history.replace(`/rate-sheet/${route_id}`)
                                        }
                                    }}
                                    className={Style.back_btn}>
                                    <KeyboardBackspaceIcon sx={{ fontSize: '15px' }} />
                                </IconButton>

                                <span className={Style.detail_text}>{data?.origin?.state?.name}, {data?.origin?.city?.name}, {data?.origin?.port?.code}, {data?.origin?.country?.code}</span>
                                <EastIcon sx={{ fontSize: '20px' }} />
                                <span className={Style.detail_text}>{data?.destination?.state?.name}, {data?.destination?.city?.name}, {data?.destination?.port?.code}, {data?.destination?.country?.code}</span>
                                {isMakeOffer && <>
                                    <Divider orientation="vertical" flexItem />
                                    {/* <span className={Style.detail_text}>{load_type}</span> */}
                                    {loadDisplay(pageData?.load)}
                                </>
                                }
                            </div>

                            {/* top bar for title  */}
                            <div className={`${Style.main} ${Style.top_bar_container}`}>
                                <TopBar breakdownData={breakdownData} setBreakdownData={setBreakdownData} addSection={addSection}
                                    handleReset={handleReset}
                                />
                                {/* <ImportantNote note="Important Note: The quotation you are about to send is a custom quote tailored specifically for this shipment and shipper. It will not be included in your standard rates unless manually specified." /> */}
                            </div>


                            <div className={Style.main}>

                                {/* preview  */}
                                <Preview isSubmitDisable={isSubmitDisable} setIsSubmitDisable={setIsSubmitDisable} transportMode={isMakeOffer ? pageData?.transport_mode : data.transportMode} pageData={pageData} method={method} breakdownData={breakdownData} setBreakdownData={setBreakdownData} />


                                {/* summary  */}
                                <Summary
                                    chatEditOffer={chatEditOffer}
                                    method={method}
                                    handleSubmit={handleSubmit}
                                    additionalData={additionalData}
                                    setAdditionalData={setAdditionalData}
                                    handleAdditionalData={handleAdditionalData}
                                    data={data}
                                    breakdownData={breakdownData}
                                    setBreakdownData={setBreakdownData}
                                    isMakeOffer={isMakeOffer}
                                    error={error}
                                    setError={setError}
                                    isSubmitDisable={isSubmitDisable}
                                    transportMode={isMakeOffer ? pageData?.transport_mode : data.transportMode}
                                />

                            </div>
                        </div>
                    </div>
                    <ConfirmDialog
                        confirmDialogOpen={confirmDialogOpen}
                        setConfirmDialogOpen={setConfirmDialogOpen}
                        confirmDialogFunction={createRateSheet}
                        question={createPriceResponse.msg}
                    />
                </div>
            }





        </>

    )
}
/* eslint-enable */
export default ViewBreakdown