import { getBaseUrl } from 'config/config';
import { HttpMethods, httpService } from './global';

export default class PreferencesService {
  static async fetchPreferencesData(id, token) {
    const url = `admin/fetch-preferences/${id}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'preferences_settings',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async managePreferences(data, token) {
    const url = `admin/manage-preferences`;

    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'preferences_settings',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
}
