import React from 'react'
import Style from "./ProfileActivityBox.module.css";
import { Switch } from '@material-ui/core';
import ActivityTab from './ActivityTab/ActivityTab';
import Tabs from 'components/Tabs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ShipmentsTab from './ShipmentsTab/ShipmentsTab';

const ProfileActivityBox = ({ profileData, setRefresh, refresh }) => {
  // const content = [
  //     {
  //         label: 'Activity',
  //         View: <ActivityTab />
  //     },
  //     {
  //         label: 'Shipments',
  //         View: <ShipmentsTab />
  //     },
  //     {
  //         label: 'Notes',
  //         View: <ActivityTab />
  //     },
  // ]

  return (
    <div className={Style.container}>
      <div className={Style.main}>
        <ActivityTab profileData={profileData} setRefresh={setRefresh} refresh={refresh} />
      </div>
    </div>
  );
};

export default ProfileActivityBox