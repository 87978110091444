import Tabs from 'components/Tabs';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Blank from '../blank/Blank';
import FullContainerLoad from './LoadTypes/FullContainerLoad';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import Style from './RateSheetInfo.module.css';
import PageContent from 'sites/shipper/components/layout/PageContent';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import BulkContainer from './LoadTypes/BulkContainer';
import { CARGO_CATEGORIES, TRANSPORT_MODES } from 'util/constants';
const RateSheetInfo = () => {
  const { rateSheetId } = useParams();
  const history = useHistory();

  const routeInfo = useSelector((state) => state.rateSheet.route_info);
  const [tabContent, setTabContent] = useState([]);
  
  useEffect(() => {
    if (!routeInfo._id) {
      history.replace('/rate-sheet');
    }
  }, [routeInfo]);

  const [run, setRun] = useState(false);

  useEffect(() => {
    if (routeInfo.transportMode === TRANSPORT_MODES.SEA.value) {
      const data = [
        {
          label: 'FCL',
          View: (
            <FullContainerLoad
              route_id={rateSheetId}
              run={run}
              setRun={setRun}
              transportMode={routeInfo?.transportMode}
            />
          )
        },
        {
          label: 'LCL',
          View: <Blank />
        },
        {
          label: 'BULK',
          View: <Blank />
        }
      ];
      setTabContent(data);
    }
    if (routeInfo.transportMode === TRANSPORT_MODES.AIR.value) {
      const data = [
        {
          label: 'BULK',
          View: <BulkContainer
            route_id={rateSheetId}
            loadType={CARGO_CATEGORIES.PKGS.label}
            run={run}
            setRun={setRun}
            transportMode={routeInfo?.transportMode}
          />
        }
      ];
      setTabContent(data);
    }
  }, [routeInfo, run]);

  return (
    <>
      <PageTitle goBack={() => history.go(-1)} titleHeading={'Manage Rates'}>
        <Button
          className={'btn-primary'}
          onClick={() => history.push('/rate-sheet-create')}>
          Create Rate
        </Button>
      </PageTitle>
      <PageContent>

        <div className={Style.routeDetailContainer}>
          <div>
            <span className="font-size-md font-weight-bold">Route Details</span>
          </div>
          <div className={Style.routeInnerContainer}>
            <div className={Style.routeflexContainer}>
              <div className={Style.labelContainer}>
                <p className={Style.labelHeading}>Origin Country Code :</p>{' '}
                <p className={Style.labelDescription}>
                  {routeInfo?.origin?.country?.name}
                </p>
              </div>
              <div className={Style.labelContainer}>
                <p className={Style.labelHeading}>Origin State :</p>{' '}
                <p className={Style.labelDescription}>
                  {routeInfo?.origin?.state?.name}
                </p>
              </div>
              <div className={Style.labelContainer}>
                <p className={Style.labelHeading}>Origin City : </p>
                <p className={Style.labelDescription}>
                  {routeInfo?.origin?.city?.name}
                </p>
              </div>
              <div className={Style.labelContainer}>
                <p className={Style.labelHeading}>Origin Port Name :</p>
                <p className={Style.labelDescription}>
                  {' '}
                  {routeInfo?.origin?.port?.name} (
                  {routeInfo?.origin?.port?.code})
                </p>
              </div>
            </div>
            <div className={Style.routeflexContainer}>
              <div className={Style.labelContainer}>
                <p className={Style.labelHeading}>Destination Country Code :</p>{' '}
                <p className={Style.labelDescription}>
                  {routeInfo?.destination?.country?.name}
                </p>
              </div>
              <div className={Style.labelContainer}>
                <p className={Style.labelHeading}>Destination State :</p>{' '}
                <p className={Style.labelDescription}>
                  {routeInfo?.destination?.state?.name}
                </p>
              </div>
              <div className={Style.labelContainer}>
                <p className={Style.labelHeading}>Destination City : </p>
                <p className={Style.labelDescription}>
                  {routeInfo?.destination?.city?.name}
                </p>
              </div>
              <div className={Style.labelContainer}>
                <p className={Style.labelHeading}>Destination Port Name :</p>
                <p className={Style.labelDescription}>
                  {' '}
                  {routeInfo?.destination?.port?.name} (
                  {routeInfo?.destination?.port?.code})
                </p>
              </div>
            </div>
          </div>
          <div className={`w-50 ${Style.labelContainer}`}>
            <p className={Style.labelHeading}>Transport Mode :</p>
            <p className={Style.labelDescription}>
              {' '}
              {routeInfo?.transportMode}
            </p>
          </div>
        </div>

        <div style={{ padding: '20px 0' }}>
          {tabContent.length > 1 ?
            <Tabs content={tabContent} />
            :
            <>
              {tabContent[0]?.View}
            </>
          }
        </div>

     
      </PageContent>
    </>
  );
};

export default RateSheetInfo;
