import ModalSimple from 'components/ModalSimple';
import StyledInput from 'components/StyledInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BlogService, CareerService, DocumentListService } from 'service';
import { upload_to_s3 } from 'util/awsUploader';
import { notify } from 'sites/shipper/components/Toast/toast';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import StyledSelect from 'components/StyledSelect';
import ConfirmDialog from 'sites/shipper/pages/shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import { Switch, Tooltip } from '@material-ui/core';

const AddDocument = ({
  open,
  setOpen,
  // handleClose,
  setRun,
  run,
  documentData,
  setDocumentData
}) => {
  const [details, setDetails] = useState({
    name: '',
    description: '',
    applicable_for: [],
    applicable_in: [],
    is_sensitive: false,
    is_active: false,
    show_in_overview: false
  });


  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.session.auth);
  const [loading, setLoading] = useState(false);
  const resetDetails = () => {
    setDetails({
      ...details,
      name: '',
      description: '',
      applicable_for: [],
      applicable_in: [],
      show_in_overview: false,
      is_sensitive: false,
      is_active: false
    });
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
    resetDetails();
    setDocumentData(null)
  };

  useEffect(() => {
    if (documentData) {
      setDetails(documentData);
    } else {
      resetDetails();
    }
  }, [documentData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDetails((prev) => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  const addDocument = async () => {
    try {
      setLoading(true);
      const data_to_send = {
        ...details
      };

      DocumentListService.createDcoument(data_to_send, auth.token).then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          notify(dispatch, 'success', res.message);
          setRun(!run);
          handleClose();
        }
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      notify(dispatch, 'error', 'Unable to send data');
    }
  };
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const editDocument = async () => {
    try {
      setLoading(true);

      const data_to_send = {
        ...details
      };

      DocumentListService.editDcoument(data_to_send, auth.token).then(
        (res) => {
          if (res.error) {
            setError(res.error);
          } else {
            notify(dispatch, 'success', res.message);
            setRun(!run);
            setDocumentData(null);
            resetDetails()
            handleClose();
          }
          setLoading(false);
        }
      );
    } catch (e) {
      console.log(e);
      setLoading(false);
      notify(dispatch, 'error', 'Unable to edit data');
    }
  };


  const handleSelection = (e) => {
    const value = e.target.value;
    if (details.applicable_for.includes(value)) {
      const newdocuments = details.applicable_for.filter(
        (item) => item !== value
      );
      setDetails({ ...details, applicable_for: newdocuments });
    } else {
      const newdocuments = [...details.applicable_for, value];
      setDetails({ ...details, applicable_for: newdocuments });
    }
  };


  const handleSelectionofApplicableIn = (e) => {
    const value = e.target.value;
    if (details.applicable_in.includes(value)) {
      const newdocuments = details.applicable_in.filter(
        (item) => item !== value
      );
      setDetails({ ...details, applicable_in: newdocuments });
    } else {
      const newdocuments = [...details.applicable_in, value];
      setDetails({ ...details, applicable_in: newdocuments });
    }
  };


  return (
    <div>
      {loading && <LoaderComponent loading={loading} />}

      <ModalSimple
        modalTitle={'Add Document'}
        open={open}
        action={() => setConfirmSubmit(true)}
        modalDescription={''}
        maxWidth={'md'}
        fullWidth={true}
        handleClose={handleClose}
        actionButtonText={documentData ? 'Edit' : 'Create'}>
        <StyledInput
          name="name"
          label="Name"
          value={details.name}
          onChange={handleChange}
          disabled={details.name === 'Others'}
          margin='0'
        />

        <StyledInput
          name="description"
          label="Description"
          value={details.description}
          onChange={handleChange}
        />

        <>
          <p className="font-size-md font-weight-bold my-2">
            Applicable for
          </p>
          <div className="d-flex flex-column" style={{ gap: '5px' }}>
            {[
              { label: 'SUPPORT', value: 'SUP' },
              { label: 'FORWARDER', value: 'FWD' },
              { label: 'SHIPPER', value: 'SHP' }
            ].map((option, index) => (
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px'
                }}>
                <input
                  type="checkbox"
                  value={option.value}
                  checked={details.applicable_for.includes(option.value)}
                  onChange={handleSelection}
                />
                <span className="font-size-sm text-black text-truncate">
                  {option.label}
                </span>
              </label>
            ))}
          </div>
        </>

        <>
          <p className="font-size-md font-weight-bold my-2">
            Applicable In
          </p>
          <div className="d-flex flex-column" style={{ gap: '5px' }}>
            {[
              { value: 'SEA', label: 'Sea' },
              { value: 'AIR', label: 'Air' }
            ].map((option, index) => (
              <label
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px'
                }}>
                <input
                  type="checkbox"
                  value={option.value}
                  checked={details.applicable_in.includes(option.value)}
                  onChange={handleSelectionofApplicableIn}
                />
                <span className="font-size-sm text-black text-truncate">
                  {option.label}
                </span>
              </label>
            ))}
          </div>
        </>


        {(details.applicable_in.includes("SEA") || details.applicable_in.includes( "AIR"))
          &&
          <div
            className={`c-flex c-justify-content-between my-2 font-weight-bold`}>
            <span>Show on shipment overview:</span>
            <Switch
              className="switch-small toggle-switch-primary"
              onChange={() =>
                setDetails((prev) => {
                  return {
                    ...prev,
                    show_in_overview: !prev.show_in_overview
                  };
                })
              }
              checked={details.show_in_overview}
              name="show_in_overview"
            />
          </div>
        }




        <div
          className={`c-flex c-justify-content-between my-2 font-weight-bold`}>
          <span>Is Active:</span>
          <Switch
            className="switch-small toggle-switch-primary"
            onChange={() =>
              setDetails((prev) => {
                return {
                  ...prev,
                  is_active: !prev.is_active
                };
              })
            }
            checked={details.is_active}
            name="is_active"
          />
        </div>

        <div className={`c-flex c-justify-content-between font-weight-bold`}>
          <span>Is Sensitive:</span>
          <Switch
            className="switch-small toggle-switch-primary"
            onChange={() =>
              setDetails((prev) => {
                return {
                  ...prev,
                  is_sensitive: !prev.is_sensitive
                };
              })
            }
            checked={details.is_sensitive}
            name="is_sensitive"
          />
        </div>

        {error && (
          <span style={{ color: 'red', fontSize: '12px' }}>{error}</span>
        )}
      </ModalSimple>
      <ConfirmDialog
        confirmDialogFunction={documentData ? editDocument : addDocument}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure to save`}
      />
    </div>
  );
};

export default AddDocument;
