import React from 'react'
import Style from './RouteFilter.module.css'
import CountrySelectNew from 'components/CountrySelect/CountrySelectNew'
import StateSelect from 'components/CountrySelect/StateSelect'
import CitySelect from 'components/CountrySelect/CitySelect'
import PortCodeSelect from 'components/CountrySelect/PortCodeSelect'
import { TRANSPORT_MODES_LIST } from 'util/constants'
import { Button } from '@material-ui/core';
import StyledSelect from 'components/StyledSelect'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { setFilterDataInRedux } from 'actions'
import { useDispatch } from 'react-redux'


const RouteFilter = ({ filters, setFilters, handleSubmit }) => {

    const dispatch = useDispatch()

    const handleCountry = (opt, name) => {
        console.log(opt)
        if (opt) {
            setFilters({
                ...filters,
                [name]: {
                    ...filters[name],
                    country: opt,
                    state: "",
                    city: "",
                    port: ""
                }
            })
        } else {
            setFilters({
                ...filters,
                [name]: {
                    ...filters[name],
                    country: "",
                    state: "",
                    city: "",
                    port: ""
                }
            })
        }
    }


    const handleState = (opt, name) => {
        if (opt) {
            setFilters({
                ...filters,
                [name]: {
                    ...filters[name],
                    state: opt,
                    city: "",
                    port: ""
                }
            })
        } else {
            setFilters({
                ...filters,
                [name]: {
                    ...filters[name],
                    state: "",
                    city: "",
                    port: ""
                }
            })
        }
    }

    const handleCity = (opt, name) => {
        if (opt) {
            setFilters({
                ...filters,
                [name]: {
                    ...filters[name],
                    city: opt,
                    port: ""
                }
            })
        } else {
            setFilters({
                ...filters,
                [name]: {
                    ...filters[name],
                    city: "",
                    port: ""
                }
            })
        }
    }

    const handlePort = (opt, name) => {
        if (opt) {
            setFilters({
                ...filters,
                [name]: {
                    ...filters[name],
                    port: opt
                }
            })
        } else {
            setFilters({
                ...filters,
                [name]: {
                    ...filters[name],
                    port: ""
                }
            })
        }
    }



    const resetFilter = () => {
        setFilters({
            transport_mode: "",
            origin: {
                country: "",
                city: "",
                state: "",
                port: ""
            },
            destination: {
                country: "",
                city: "",
                state: "",
                port: ""
            }
        })
        dispatch(setFilterDataInRedux(null))
    }


    return (
        <>
            <div className={Style.container}>
                {/* <div className={`d-flex align-items-center my-2`}>
                    <span className="font-weight-bold font-size-lg">Filter</span>
                </div> */}
                <div className={`w-100 my-2 d-flex align-items-center ${Style.Header}`}>
                    <div className='d-flex align-items-center'>
                        <FilterAltOutlinedIcon />
                        <span className="font-weight-bold font-size-lg">
                            Filters
                        </span>
                    </div>
                    <Button
                        className="btn-link btn-link-danger p-0"
                        // className={Style.resetButton}
                        onClick={resetFilter}
                    >
                        Reset
                    </Button>
                </div>

                <div className="divider my-3" />
                <div>
                    <span className="font-size-md font-weight-bold">Select Transport Mode</span>
                </div>
                <div className={Style.OneFieldContainer}>
                    <StyledSelect
                        label="Select Transport Mode"
                        name="transport_mode"
                        margin="0"
                        value={filters.transport_mode}
                        onChange={(e) =>
                            setFilters({ ...filters, transport_mode: e.target.value })
                        }
                        size="small"
                        options={TRANSPORT_MODES_LIST &&
                            TRANSPORT_MODES_LIST.map((item, index) => {
                                return {
                                    label: item.label,
                                    value: item.value
                                }
                            })}
                    />
                </div>

                <div>
                    <span className="font-size-md font-weight-bold">Select Origin Port</span>
                </div>
                <div className={Style.TwoFieldContainer}>
                    <CountrySelectNew
                        useDefault
                        isClearable={true}
                        isMulti={false}
                        onChange={(e, opt) => handleCountry(opt, 'origin')}
                        showError={false}
                        value={filters.origin.country}
                    />
                    <StateSelect
                        country={filters.origin.country?.code}
                        value={filters.origin.state}
                        showError={false}
                        handleChange={(e, opt) => handleState(opt, 'origin')}
                    />
                    <CitySelect
                        value={filters.origin.city}
                        state={filters.origin.state?.code}
                        showError={false}
                        handleChange={(e, opt) => handleCity(opt, 'origin')}
                    />
                    <PortCodeSelect
                        value={filters.origin.port}
                        city={filters.origin.state?.code}
                        transportMode={filters.transport_mode}
                        showError={false}
                        handleChange={(e, opt) => handlePort(opt, 'origin')}
                    />
                </div>
                <div>
                    <span className="font-size-md font-weight-bold">Select Destination Port</span>
                </div>
                <div className={Style.TwoFieldContainer}>
                    <CountrySelectNew
                        useDefault
                        isClearable={true}
                        isMulti={false}
                        onChange={(e, opt) => handleCountry(opt, 'destination')}
                        showError={false}
                        value={filters.destination.country}
                    />
                    <StateSelect
                        country={filters.destination.country?.code}
                        value={filters.destination.state}
                        showError={false}
                        handleChange={(e, opt) => handleState(opt, 'destination')}
                    />
                    <CitySelect
                        value={filters.destination.city}
                        state={filters.destination.state?.code}
                        showError={false}
                        handleChange={(e, opt) => handleCity(opt, 'destination')}
                    />
                    <PortCodeSelect
                        value={filters.destination.port}
                        city={filters.destination.state?.code}
                        transportMode={filters.transport_mode}
                        showError={false}
                        handleChange={(e, opt) => handlePort(opt, 'destination')}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'start' }}>
                    <Button className="btn-primary" onClick={handleSubmit}>Filter</Button>
                </div>

            </div>
        </>
    )
}

export default RouteFilter