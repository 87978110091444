import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import Style from "./PageTitleActions.module.css";


const PageTitleActions = (props) => {
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const [anchorEl, setAnchorEl] = useState(null);

  function openUserMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      {/* <div className={`d-flex c-gap-1 flex-wrap justify-content-end`}>
        <Button
          variant="contained"
          href="/freight/quote"
          className={`${Style.btn} btn-outline-primary shadow-none`}>
          <span className="btn-wrapper--label">Create Shipment</span>
        </Button>
      
        <Button
          variant="contained"
          className={`${Style.btn} btn-primary shadow-none`}
          onClick={toggle1}>
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={['fas', 'plus']} className="opacity-8" />
          </span>
          <span className="btn-wrapper--label">Add Shipper</span>
        </Button>
      </div> */}
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(PageTitleActions);
