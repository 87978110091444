import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';
import { httpService } from './global';

export default class PaymentService {
  static fetchStripePublicKey() {
    let url = 'stripe/publicKey';

    return HttpService.request(HttpMethods.GET, url, {})
      .then((res) => {
        console.log('fetching Stripe key resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static setupIntent(uid) {
    let url = `stripe/setupIntent/${uid}`;

    return HttpService.request(HttpMethods.POST, url, {})
      .then((res) => {
        console.log('setupIntent resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static webhook() {
    let url = 'stripe/webhook';

    return HttpService.request(HttpMethods.POST, url, {})
      .then((res) => {
        console.log('webhook resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }

  static fetchSavedCards(uid, token) {
    return HttpService.request(
      HttpMethods.GET,
      `stripe/${uid}/fetchCards `,
      {},
      {},
      token
    )
      .then((res) => res)
      .catch((err) => console.error(err));
  }





























  // created by rajkumar jaiswal



  static async fetchForwarderEarnings(token, keyword) {
    const url = `payment/a/fetch-forwarder-earnings?keyword=${keyword}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_payout',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }




  static async fetchForwarderEarningsById(id, token) {
    const url = `payment/a/fetch-forwarder-earnings-by-id?id=${id}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_payout',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }


  static async releasePayoutStripe(data, token) {
    const url = `payment/a/release-payout/stripe`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'billing_payout',
      method: 'release_payout',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }
  static async releasePayoutBankTransfer(data, token) {
    const url = `payment/a/release-payout/bank-transfer`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'billing_payout',
      method: 'release_payout',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static async fetchPayoutsForwarder(data, token, type, method) {
    const url = `payment/a/fetch/payouts`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: type,
      method: method,
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static async reCalculateForwarderEarnings(forwarder_id, token) {
    const url = `payment/a/recalculate/earnings?forwarder_id=${forwarder_id}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_payout',
      method: 'release_payout',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchForwarderbankDetails(forwarder_id, token) {
    const url = `payment/a/forwarder/bank-detail/fetch?forwarder_id=${forwarder_id}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_payout',
      method: 'release_payout',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async approveBankTransferPayout(transaction_id, token) {
    const url = `payment/a/forwarder-payout/bank-transfer/approve?transaction_id=${transaction_id}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'billing_payout',
      method: 'release_payout',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async rejectBankTransferPayout(transaction_id, data, token) {
    const url = `payment/a/forwarder-payout/bank-transfer/reject?transaction_id=${transaction_id}`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'billing_payout',
      method: 'release_payout',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }


}