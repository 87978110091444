import React from 'react'
import ModalSimple from "components/ModalSimple";
import Style from "./Summary.module.css"
const SummaryPop = ({ open, data, handleSubmit, handleClose }) => {
    return (
        <>
            <ModalSimple
                modalTitle={"Create New Rate"}
                open={open}
                action={handleSubmit}
                maxWidth={"md"}
                fullWidth={true}
                handleClose={handleClose}
                actionButtonText={"Confirm"}
            >
                <div className={Style.SummaryPopup}>
                    <p className="m-0 font-size-xs font-weight-bold">Transport line: </p><p className="m-0 font-size-xs">{data?.transport_line}</p>
                    <p className="m-0 font-size-xs font-weight-bold">Tranport Unit Name: </p><p className="m-0 font-size-xs">{data?.transport_unit_name}</p>
                    <p className="m-0 font-size-xs font-weight-bold">Price: </p><p className="m-0 font-size-xs">{data?.amount?.currency?.toUpperCase()} {data?.amount?.value}</p>
                    <p className="m-0 font-size-xs font-weight-bold">Est. Transit Time: </p><p className="m-0 font-size-xs">{data?.estimate_transit_time}</p>
                    <p className="m-0 font-size-xs font-weight-bold">Port to port  Transit Time: </p><p className="m-0 font-size-xs">{data?.port_to_port_transit_time}</p>
                    <p className="m-0 font-size-xs font-weight-bold">Effective date: </p><p className="m-0 font-size-xs">{data?.effective_date}</p>
                    <p className="m-0 font-size-xs font-weight-bold">Expiry date: </p><p className="m-0 font-size-xs">{data?.expiry_date}</p>
                </div>

            </ModalSimple>
        </>
    )
}

export default SummaryPop