import { getBaseUrl } from 'config/config';
import { HttpMethods, httpService } from './global';

export default class RateSheetService {


    static async fetchRateSheet(id, data, token,page) {
        const url = `admin/rate-sheet/fetch?admin_id=${id}&page=${page}`

         return httpService(HttpMethods.POST, url, data, {
           token,
           type: 'rate_sheet',
           method: 'read',
           platform: 'Admin'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
       
    }




    static async createRateSheet(admin_id, data, token) {
        const url = `admin/rate-sheet/create?admin_id=${admin_id}`

          return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'rate_sheet',
            method: 'create',
            platform: 'Admin'
          })
            .then((res) => {
              return res;
            })
            .catch((err) => console.log(err));
       
      
    }


    static async editRateSheet(admin_id, routeId, data, token) {
        const url = `admin/rate-sheet/update?admin_id=${admin_id}&routeId=${routeId}`

         return httpService(HttpMethods.POST, url, data, {
           token,
           type: 'rate_sheet',
           method: 'edit',
           platform: 'Admin'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
      
    }


    static async deleteRateSheet(admin_id, routeId, token) {
        const url = `admin/rate-sheet/delete?admin_id=${admin_id}&routeId=${routeId}`

         return httpService(HttpMethods.DELETE, url, {}, {
           token,
           type: 'rate_sheet',
           method: 'delete',
           platform: 'Admin'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
      
    }


    static async saveRateSheetPriceInfo(admin_id, data, token) {
        const url = `admin/rate-sheet/create-rate?admin_id=${admin_id}`

         return httpService(HttpMethods.POST, url, data, {
           token,
           type: 'rate_sheet',
           method: 'create',
           platform: 'Admin'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
      
    }



    static async fetchRateSheetPriceInfo(data, token) {
        const url = `admin/rate-sheet/fetch-rate?`

         return httpService(HttpMethods.POST, url, data, {
           token,
           type: 'rate_sheet',
           method: 'read',
           platform: 'Admin'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
      
    }

    static async deleteRateSheetPriceInfo(data, token) {
        const url = `admin/rate-sheet/delete-rate`

          return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'rate_sheet',
            method: 'delete',
            platform: 'Admin'
          })
            .then((res) => {
              return res;
            })
            .catch((err) => console.log(err));
      
    }

    static async editRateSheetPriceInfo(data, token) {
        const url = `admin/rate-sheet/edit-rate`

          return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'rate_sheet',
            method: 'edit',
            platform: 'Admin'
          })
            .then((res) => {
              return res;
            })
            .catch((err) => console.log(err));
       
    }

    static async checkIsNewPriceforType(admin_id, data, token) {
        const url = `admin/rate-sheet/check-is-new-price?admin_id=${admin_id}`

          return httpService(HttpMethods.POST, url, data, {
            token,
            type: 'rate_sheet',
            method: 'read',
            platform: 'Admin'
          })
            .then((res) => {
              return res;
            })
            .catch((err) => console.log(err));
       
    }


    static async fetchRateSheetByShippingLine(data, token) {
        const url = `admin/rate-sheet/fetch-rate-by-shipping-line`

           return httpService(HttpMethods.POST, url, data, {
             token,
             type: 'rate_sheet',
             method: 'read',
             platform: 'Admin'
           })
             .then((res) => {
               return res;
             })
             .catch((err) => console.log(err));
        
    }




}
