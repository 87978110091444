import React, { useEffect, useState } from 'react';
import AddCategory from './AddCategory/AddCategory';
import { Button, MenuItem, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BlogService } from 'service';
import { notify } from 'sites/shipper/components/Toast/toast';
import { Empty } from 'antd';
import BlogCategoryTableWrapper from './BlogCategoryTable/BlogCategoryTableWrapper/BlogCategoryTableWrapper';
import BlogCategoryTableRow from './BlogCategoryTable/BlogCategoryTableRow/BlogCategoryTableRow';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { Pagination } from '@mui/material';
import ConfirmDialog from '../../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import SkeletonComponent from 'sites/shipper/components/Skeleton/Skeleton';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import CachedIcon from '@mui/icons-material/Cached';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';

/* eslint-disable */
const Category = ({ tab }) => {
  const [open, setOpen] = useState(false);

  const [run, setRun] = useState(false);
  const [categories, setCategories] = useState([]);

  const [categoryData, setCategoryData] = useState(null);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const auth = useSelector((state) => state.session.auth);

  const [categoryCount, setCategoryCount] = useState(0);

  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(parseInt(pageValue));
  const itemsPerPage = 15;

  const [check, setCheck] = useState(true);

  useEffect(() => {
    if (loading) return;

    const element = document.getElementById('top');
    element.scrollIntoView({ behavior: 'smooth' });
  }, [check]);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/articles?tab=${tab}&page=${value}`);
  }

  useEffect(() => {
    setLoading(true);

    const data = {
      query: search,
      id: auth.accountId,
      page: page
    };

    BlogService.fetchCategories(data, auth.token).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.error);
      } else {
        setCategories(res?.data);
        setCategoryCount(res?.categoryCount);
      }

      setLoading(false);
    });
  }, [run, search, page]);

  const [removeId, setRemoveId] = useState(null);

  const handleRemoveCategory = async () => {
    setLoading(true);
    const data_to_send = {
      userId: auth.accountId,
      categoryId: removeId
    };

    BlogService.removeCategory(data_to_send, auth.token).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.error);
      } else {
        notify(dispatch, 'success', res.message);
        setRun(!run);
      }
      setLoading(false);
    });
  };

  const tableCellStyle = {
    padding: '12px',
    border: '1px solid #ddd',
    textAlign: 'left'
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [moreOptionClicked, setMoreOptionClicked] = useState(null);

  const TableHeading = [
    'S.No.',
    'Image',
    'Name',
    'Description',
    'Created By',
    'Updated By',
    'Slug',
    'Action'
  ];

  const Keys = [
    'index',
    'image',
    'name',
    'description',
    'createdBy',
    'updatedBy',
    'slug',
    '_id'
  ];

  const TableData = categories?.map((item, index) =>
    Keys.map((Key) => {
      if (Key === 'index') {
        return index + 1;
      }
      if (Key === '_id') {
        return (
          <>
            <div className="position-relative">
              <center>
                <Tooltip title="More" arrow>
                  <Button
                    variant="text"
                    className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
                    onClick={(e) => {
                      setOpenMoreOptions(e.currentTarget);
                      setMoreOptionClicked(item);
                    }}>
                    <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                  </Button>
                </Tooltip>
              </center>
              {moreOptionClicked?._id === item?._id && (
                <SmallPopupWrapper
                  open={openMoreOptions}
                  onClose={() => {
                    setMoreOptionClicked(null);
                    setOpenMoreOptions(null);
                  }}>
                  <MenuItem
                    onClick={() => {
                      setOpen(true);
                      setCategoryData(moreOptionClicked);

                      setOpenMoreOptions(null);
                    }}>
                    Edit
                  </MenuItem>

                  {
                    <MenuItem
                      onClick={() => {
                        setConfirmSubmit(true);
                        setRemoveId(moreOptionClicked?._id);

                        setOpenMoreOptions(null);
                      }}>
                      Delete
                    </MenuItem>
                  }
                </SmallPopupWrapper>
              )}
            </div>
          </>
        );
      }


      if (Key === 'image') {
        return (
          <>
            {item?.image ? (
              <img
                style={{
                  height: '50px',
                  width: '50px'
                  // borderRadius: '50%'
                }}
                src={item?.image?.url}
                alt={`Image for ${item?.name}`}
              />
            ) : (
              'no image'
            )}
          </>
        );
      } else {
        return item[Key] || '';
      }
    })
  );

  return (
    <div id="top">
      {/* {loading && <LoaderComponent loading={loading} />} */}
      <AddCategory
        open={open}
        setOpen={setOpen}
        run={run}
        setRun={setRun}
        handleClose={() => setOpen(false)}
        categoryData={categoryData}
        setCategoryData={setCategoryData}
      />

      <TableWithSearchBar
        animatedSearchbar={{
          show: true,
          value: search,
          onChange: (e) => setSearch(e.target.value)
        }}
        loading={loading}
        TableHeading={TableHeading}
        TableBody={TableData}
        TableGrid={'0.1fr repeat(7,1fr)'}
        actionButtons={[
          <Button
            variant="text"
            onClick={() => setRun(!run)}
            className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
            <CachedIcon className="w-50" />
          </Button>,
          <Button
            onClick={() => {
              setOpen(true);
              setCategoryData(null);
            }}
            variant="text"
            className={`btn-primary shadow-none py-2`}>
            Add Category
          </Button>
        ]}
        pagination={{
          count: Math.ceil(categoryCount / itemsPerPage),
          page: page,
          onChange: handlePageChange
        }}
      />
      <ConfirmDialog
        confirmDialogFunction={handleRemoveCategory}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure to delete this category`}
      />

     
    </div>
  );
};
/* eslint-enable */
export default Category;
