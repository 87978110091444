import { useEffect, useState } from "react";
import Style from "./ShipmentRates.module.css";
import RateSheet from "./rate_sheet/RateSheet";
import Blank from "./blank/Blank";
import Header from "layout-components/Header/Header";
import RightDrawer from "sites/shipper/components/layout/RightDrawer";
import AddRateSheetForm from "./AddRateSheetForm/AddRateSheetForm";
import { ForwarderService } from "service";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
// import ForwarderTeam from "service/ForwarderTeam";
import PageError403 from 'components/PageError403';
import Tabs from "components/Tabs";
import AdminTeam from "service/AdminTeam";
import PageTitle from "sites/shipper/components/layout/PageTitle/PageTitle";
import PageContent from 'sites/shipper/components/layout/PageContent';
import LoaderComponent from "../../components/LoaderComponent";
/* eslint-disable */

const ShipmentRates = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([]);
    // const [openRightDrawer, setOpenRightDrawer] = useState(false);
    // const toggleRightDrawer = (bool) => {
    //     setOpenRightDrawer(bool);
    // };


    const [rateSheets, setRateSheets] = useState([])
    const [error, setError] = useState([])

    const auth = useSelector((state) => state.session.auth)

    const [run, setRun] = useState(false) // to referesh the data after some action is performed from the frontend
    // const inits = async () => {
    //     await ForwarderService.fetchRateSheet(auth.accountId).then(data => {
    //         if (data.length > 0) {
    //             setRateSheets(data)
    //         } else {
    //             setError(data.error)
    //         }

    //     })
    // }
    // useEffect(() => {
    //     inits()
    // }, [auth, run])




    const [allow, setAllow] = useState(true)


    // useEffect(() => {
    //     AdminTeam.fetchMemberPermission(auth.accountId, "rate_sheet", auth.token).then(res => {
    //         if (res.success) {
    //             setAllow(true)
    //         } else {
    //             setAllow(false)
    //         }
    //         setLoading(false)
    //     })
    // }, [])

    return (
        <>


            {loading ?
                <LoaderComponent loading={loading}/>
                :

                <>
                    {allow ?
                        <>
                            <PageTitle titleHeading={"Rate Sheet"} />
                            <PageContent>
                                {/* <div className={Style.container}> */}
                                    {/* <div className={Style.main}> */}
                                        {/* <div className={Style.options_container}>
                                            <Button
                                                className="btn-primary"
                                                onClick={() => toggleRightDrawer(true)}
                                            >
                                                Create Rate Sheet
                                            </Button>
                                        </div> */}
                                        <RateSheet />
                                        {/* <Tabs
                                            content={[
                                                {
                                                    label: 'Sea',
                                                    View: data ?
                                                        <RateSheet mode={"SEA"} toggleRightDrawer={toggleRightDrawer} run={run} setRun={setRun} />
                                                        :
                                                        <Blank />
                                                },
                                                {
                                                    label: 'Air',
                                                    View: data ?
                                                        <RateSheet mode={"AIR"} toggleRightDrawer={toggleRightDrawer} run={run} setRun={setRun} />
                                                        :
                                                        <Blank />
                                                },
                                            ]}
                                        /> */}
                                    {/* </div> */}

                                    {/* <RightDrawer
                                        onClose={() => toggleRightDrawer(false)}
                                        open={openRightDrawer}
                                    >
                                        <AddRateSheetForm run={run} setRun={setRun} toggleRightDrawer={toggleRightDrawer} />
                                    </RightDrawer> */}
                                {/* </div> */}
                            </PageContent>

                        </>
                        :
                        <>
                            <PageError403 />
                        </>
                    }
                </>
            }


        </>
    )
}
/* eslint-enable */
export default ShipmentRates