import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Style from "./ShipmentDocument.module.css";
import {
  Grid,
  InputLabel,
  InputAdornment,
  Menu,
  MenuItem,
  Button,
  TextField,
  FormControl,
  Select,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton
} from '@material-ui/core';
/* eslint-disable */
import clsx from 'clsx';
import Pagination from '@material-ui/lab/Pagination';
import DocumentCard from './DocumentCard';

import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import { DOCUMENT_CATEGORY } from 'util/documentsList';
import { ForwarderService } from 'service';
import { useSelector } from 'react-redux';
import { Visibility } from '@material-ui/icons';
import TabsNav from 'sites/shipper/pages/Document/tabs/TabsNav';
import { handlePreview } from 'util/OpenPreviewWindow/OpenPreviewWindow';
import { Empty } from 'antd';
import EmptyContainer from 'sites/shipper/components/EmptyContainer/EmptyContainer';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%'
  }
});

const ShipmentDocuments = ({ booking, classes }) => {

  // 0 -> images, 1 -> attachments
  const [value, setValue] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);

  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [status, setStatus] = useState('');

  const handleStatus = (event) => {
    setStatus(event.target.value);
  };

  const [type, setType] = useState('all');

  const handleType = (event) => {
    setType(event.target.value);
  };

  const [searchOpen, setSearchOpen] = useState(false);

  const openSearch = () => setSearchOpen(true);
  const closeSearch = () => setSearchOpen(false);

  const [resultsValue, setResultsValue] = useState(5);

  const handleResultsChange = (event) => {
    setResultsValue(event.target.value);
  };

  const [data, setData] = useState()
  const [error, setError] = useState()
  const auth = useSelector((state) => state.session.auth)

  useEffect(() => {
    ForwarderService.fetch_shipment_documents(booking, auth.token).then(res => {
      if (res.error) {
        setError(res.error)
      } else {
        setData(res)
      }
    })
  }, [booking, auth])


  return (
    <div className={`${Style.container} ${classes.root}`}>

      <TabsNav
        labels={['Attachments', 'Images']}
        value={value}
        setValue={setValue}
      />

      {value === 1 &&
        <div className={`${Style.main}`}>
          {data && data.images.length > 0 ?
            <Grid container spacing={4}>
              {data.images.map((document, index) => {
                return (
                  <Grid key={index} item xl={4} sm={6}>
                    <div
                      onClick={() => handlePreview(document.url, 'image')}
                      className={Style.image_container}
                    >

                      <img src={document.url} className={Style.image} width={100} />

                      <IconButton
                        className={Style.preview_btn}
                      >
                        <Visibility fontSize='small' style={{ color: 'white' }} />
                      </IconButton>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
            :
            <>
              {/* <Empty /> */}
              <EmptyContainer title='No attachments found' />
            </>
          }
        </div>
      }


      {value === 0 &&
        <div className={`${Style.main}`}>
          {data && data.documents.length > 0 ?
            <Grid container spacing={4}>
              {data.documents.map((document, index) => {
                return (
                  <Grid key={index} item xl={6} sm={12}>
                    <DocumentCard
                      document={document}
                      handleClick={handlePreview}
                    />
                  </Grid>
                );
              })}
            </Grid>
            :
            <>
              {/* <Empty /> */}
              <EmptyContainer title='No Images found' />
            </>
          }
        </div>
      }


      {error && <div>{error}</div>}
    </div>
  );
};

ShipmentDocuments.defaultProps = {};

ShipmentDocuments.propTypes = {
  classes: PropTypes.object.isRequired,
  documents: PropTypes.object.isRequired
};

export default withStyles(styles)(ShipmentDocuments);
/* eslint-enable */
