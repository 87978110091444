export const SET_CHAT_ROOM = 'SET_CHAT_ROOM';
export const setChatRoom = (payload) => ({
  type: SET_CHAT_ROOM,
  payload
});

export const SET_CHAT_DATA = 'SET_CHAT_DATA';
export const setChatData = (payload) => ({
  type: SET_CHAT_DATA,
  payload
})