import { AuthService, DashService } from '../service';
import moment from 'moment';
import { logout } from './logout';
import { useSelector } from 'react-redux';

/*-- Action Creators for Auth Fetch --*/
export const REQUEST_AUTH = 'REQUEST_AUTH';
export const requestAuth = () => ({
  type: REQUEST_AUTH
});

export const RECEIVE_AUTH_SUCCESS = 'RECEIVE_AUTH_SUCCESS';
export const receiveAuthSuccess = (payload) => ({
  type: RECEIVE_AUTH_SUCCESS,
  payload
});

export const RECEIVE_AUTH_FAILURE = 'RECEIVE_AUTH_FAILURE';
export const receiveAuthFailure = (payload) => ({
  type: RECEIVE_AUTH_FAILURE,
  payload
});

export function fetchAuth(email, password, accountType) {
  return (dispatch) => {
    dispatch(requestAuth());

    return AuthService.login(email, password, accountType).then((res) => {
      sendAuthActions(dispatch, res);
    });
  };
}

export function setUserCreds(res) {
  return (dispatch) => {
    sendAuthActions(dispatch, res);
  };
}

export function sendAuthActions(dispatch, res) {
  if (res && res.returnCode === 0) {
    return dispatch(receiveAuthSuccess(res));
  } else {
    return dispatch(receiveAuthFailure(res));
  }
}

export const REQUEST_SESSION_VALIDATION = 'REQUEST_SESSION_VALIDATION';
export const requestSessionValidation = () => ({
  type: REQUEST_SESSION_VALIDATION
});

export const INVALID_SESSION = 'INVALID_SESSION';
export const invalidSession = () => ({
  type: INVALID_SESSION
});

export const LOGGED_IN_ELSEWHERE = 'LOGGED_IN_ELSEWHERE';
export const loggedInElsewhere = (payload) => ({
  type: LOGGED_IN_ELSEWHERE,
  payload
});

export const VALID_SESSION = 'VALID_SESSION';
export const validSession = (sessionTime) => ({
  type: VALID_SESSION,
  msg: sessionTime
});

export function checkSession(token, page) {
  console.log('Func Checking session...', token, page);
  return (dispatch) => {
    dispatch(requestSessionValidation());
    return DashService.checkUserSession(token)
      .then((res) => {
        console.log('Session Response', res);
        if (res && res.returnCode === 0) {
          const currentTime = moment.utc();
          const sessionTime = moment.utc(res.expiry);
          if (currentTime.isBefore(sessionTime)) {
            dispatch(validSession(currentTime.isBefore(sessionTime)));
          } else {
            console.log('Else 1');
            dispatch(invalidSession());
            logout(token);
          }
        } else if (res.returnCode === 25) {
          console.log('Else 2');
          dispatch(loggedInElsewhere(res));
          logout();
        } else {
          console.log('Else 3');
          dispatch(invalidSession());
          // logout(token);
        }
      })
      .catch((_) => {
        console.log('Else 4');
        dispatch(invalidSession());
        logout(token);
      });
  };
}

export function sendVerificationCode(data) {
  return (dispatch) => {
    // dispatch(requestAuth());

    return AuthService.sendVerificationCode(data).then((res) => {
      return res && res.returnCode === 0;
    });
  };
}

export function verifyCode(data) {
  return (dispatch) => {
    //dispatch(requestAuth());

    return AuthService.verifyCode(data).then((res) => {
      return res;
    });
  };
}
