import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubscriberService from 'service/SubscriberService';
import PageContent from 'sites/shipper/components/layout/PageContent';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import AddSubscriber from './AddSubscriber/AddSubscriber';
import TableWrapper from './SubscriberManagmentTable/TableWrapper/TableWrapper';
import TableRow from './SubscriberManagmentTable/TableRow/TableRow';
import { Empty } from 'antd';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { notify } from 'sites/shipper/components/Toast/toast';
import { Pagination } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from '../shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import * as XLSX from 'xlsx';
import { tr } from 'date-fns/locale';
import SkeletonComponent from 'sites/shipper/components/Skeleton/Skeleton';
import { userPermission } from 'util/adminPermissionRoles';
import { Button, MenuItem, Tooltip } from '@material-ui/core';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import CachedIcon from '@mui/icons-material/Cached';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';

/* eslint-disable */
const SubscriberManagement = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.session.auth);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [run, setRun] = useState(true);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(parseInt(pageValue));
  const itemsPerPage = 15;

  const [check, setCheck] = useState(true);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (loading) return;

    const element = document.getElementById('top');
    element.scrollIntoView({ behavior: 'smooth' });
  }, [check]);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/subscriber-management?page=${value}`);
  }

  const init = () => {
    setLoading(true);
    SubscriberService.fetchSubscriber(auth.accountId, auth.token, page, {
      query: search
    }).then((res) => {
      if (res.error) {
        console.log(res.error);
        setData([]);
      } else {
        setData(res?.data);
        setSubscriberCount(res?.subscriberCount);
      }
      setLoading(false);
    });
  };

  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [subscriberData, setSubscriberData] = useState(null);

  useEffect(() => init(), [run, page, search]);

  const handleEdit = (item) => {
    setSubscriberData(item);
    setOpen(true);
  };

  const [removeId, setRemoveId] = useState(false);
  const [removeName, setRemoveName] = useState("")

  const handleDelete = () => {
    setLoading(true);
    SubscriberService.deleteSubscriber(
      auth.accountId,
      removeId,
      removeName,
      auth.token
    ).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.error);
      } else {
        notify(dispatch, 'success', res.message);
        setRun(!run);
      }
      setLoading(false);
    });
  };

  const handleDownloadData = () => {
    setLoading(true);
    SubscriberService.downloadSubscriber(
      auth.accountId,

      auth.token
    ).then((res) => {
      if (res?.error) {
        notify(dispatch, 'error', res.error);
      } else if (res?.data) {
        try {
          const ws = XLSX.utils.json_to_sheet(res?.data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

          XLSX.writeFile(wb, 'Subscribers Data.xlsx');
        } catch (e) {
          notify(dispatch, 'error', e);
        }
      }
      setLoading(false);
    });
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [moreOptionClicked, setMoreOptionClicked] = useState(null);

  const TableHeading = [
    'S.No.',
    'Name',
    'Email',
    'Created By',
    'Created At',
    (userPermission.subscribers.delete.includes(auth.accountCmpyLinkType) ||
      userPermission.subscribers.edit.includes(auth.accountCmpyLinkType)) &&
    'Action'
  ];

  const Keys = ['index', 'name', 'email', 'created_by', 'createDate', '_id'];

  const TableData = data?.map((item, index) =>
    Keys.map((Key) => {
      if (Key === 'index') {
        return index + 1;
      }
      if (Key === '_id') {
        return (
          <>
            <div className="position-relative">
              <center>
                <Tooltip title="More" arrow>
                  <Button
                    variant="text"
                    className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
                    onClick={(e) => {
                      setOpenMoreOptions(e.currentTarget);
                      setMoreOptionClicked(item);
                    }}>
                    <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                  </Button>
                </Tooltip>
              </center>
              {moreOptionClicked?.id === item?.id && (
                <SmallPopupWrapper
                  open={openMoreOptions}
                  onClose={() => {
                    setMoreOptionClicked(null);
                    setOpenMoreOptions(null);
                  }}>
                  {userPermission.subscribers.edit.includes(
                    auth.accountCmpyLinkType
                  ) && (
                      <MenuItem
                        onClick={() => {
                          handleEdit(moreOptionClicked);
                          setOpenMoreOptions(null);
                        }}>
                        Edit
                      </MenuItem>
                    )}

                  {userPermission.subscribers.delete.includes(
                    auth.accountCmpyLinkType
                  ) && (
                      <MenuItem
                        onClick={() => {
                          setConfirmSubmit(true);
                          setRemoveName(`${item?.first_name} ${item?.last_name}`)
                          setRemoveId(moreOptionClicked?.id);
                          setOpenMoreOptions(null);
                        }}>
                        Delete
                      </MenuItem>
                    )}
                </SmallPopupWrapper>
              )}
            </div>
          </>
        );
      }
      if (Key === 'createDate') {
        return moment(item.createDate).format('ll');
      }
      if (Key === 'name') {
        return (
          <>
            {item?.first_name} {item?.last_name}
          </>
        );
      } else {
        return item[Key] || '';
      }
    })
  );



  return (
    <div id="top">
      {/* {loading && <LoaderComponent loading={loading} />} */}
      <PageTitle titleHeading={`Subscriber Managment`}></PageTitle>
      <PageContent>
        {open && (
          <AddSubscriber
            open={open}
            setOpen={setOpen}
            subscriberData={subscriberData}
            run={run}
            setRun={setRun}
          />
        )}

        {/* <TableWrapper
          setSearch={setSearch}
          search={search}
          setRun={setRun}
          run={run}
          handleAddSubscriber={() => {
            setOpen(true);
            setSubscriberData(null);
          }}
          handleDownloadData={handleDownloadData}
          tableHeaders={[
            'No.',
            'Name',
            'Email',
            'Created By',
            'Created At',
            (userPermission.subscribers.delete.includes(
              auth.accountCmpyLinkType
            ) ||
              userPermission.subscribers.edit.includes(
                auth.accountCmpyLinkType
              )) &&
              'Action'
          ]}>
          {loading && <SkeletonComponent height="60px" />}
          {loading === false &&
            (data.length > 0 ? (
              <>
                {data.map((item, index) => (
                  <TableRow
                    data={item}
                    index={index}
                    handleEdit={handleEdit}
                    handleDelete={(id) => {
                      setRemoveId(id);
                      setConfirmSubmit(true);
                    }}
                  />
                ))}
              </>
            ) : (
              <Empty />
            ))}
          <div className="d-flex justify-content-end my-5">
            <Pagination
              className="pagination-primary"
              count={Math.ceil(subscriberCount / itemsPerPage)}
              page={page}
              onChange={handlePageChange}
            />
          </div>
        </TableWrapper> */}

        <TableWithSearchBar
          animatedSearchbar={{
            show: true,
            value: search,
            onChange: (e) => setSearch(e.target.value)
          }}
          loading={loading}
          TableHeading={TableHeading}
          TableBody={TableData}
          TableGrid={'0.1fr repeat(5,1fr)'}
          actionButtons={[
            <Button
              variant="text"
              onClick={() => setRun(!run)}
              className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
              <CachedIcon className="w-50" />
            </Button>,
            userPermission.subscribers.download.includes(
              auth.accountCmpyLinkType
            ) && (
              <Tooltip title="Download all data" arrow>
                <Button
                  variant="text"
                  className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}
                  onClick={handleDownloadData}>
                  <DownloadIcon />
                </Button>
              </Tooltip>
            ),

            userPermission.subscribers.create.includes(
              auth.accountCmpyLinkType
            ) && (
              <Button
                variant="contained"
                className="btn-primary shadow-none py-2"
                onClick={() => {
                  setOpen(true);
                  setSubscriberData(null);
                }}>
                Add Subscriber
              </Button>
            )
          ]}
          pagination={{
            count: Math.ceil(subscriberCount / itemsPerPage),
            page: page,
            onChange: handlePageChange
          }}
        />
      </PageContent>

      <ConfirmDialog
        confirmDialogFunction={handleDelete}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure to delete`}
      />
    </div>
  );
};
/* eslint-enable */

export default SubscriberManagement;
