import React, { useEffect, useState } from 'react';
import AddServicePricePopup from './AddServicePricePopup/AddServicePricePopup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Empty } from 'antd';
import ServiceManagementService from 'service/ServiceManagementService';
import ConfirmDialog from 'sites/shipper/pages/shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import { notify } from 'sites/shipper/components/Toast/toast';
import { Button, MenuItem, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { West } from '@mui/icons-material';
import PageContent from 'sites/shipper/components/layout/PageContent';
import ModalPopupWrapper from 'components/Common/ModalPopup/ModalPopup';
import Style from './SingleService.module.css';
import SingleServiceTableWrapper from './SingleServiceTableWrapper/SingleServiceTableWrapper';
import SingleServiceTableRow from './SingleServiceTableWrapper/SingleServiceTableRow/SingleServiceTableRow';
import SkeletonComponent from 'sites/shipper/components/Skeleton/Skeleton';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import CachedIcon from '@mui/icons-material/Cached';
import PageTitle from '../../../components/layout/PageTitle/PageTitle';
import moment from 'moment';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { getCurrencyIcon, numberToLocaleString } from 'util/Shipment/shipment';

/* eslint-disable */
const SingleServicePage = () => {
  const history = useHistory();
  const { service_id } = useParams();
  const auth = useSelector((state) => state.session.auth);
  const dispatch = useDispatch();
  const [servicePriceList, setServicePriceList] = useState([]);
  const [run, setRun] = useState(false);
  const [serviceDetails, setServiceDetails] = useState();
  const [loading, setLoading] = useState(true);

  const searchParams = new URLSearchParams(location.search);

  const [servicePriceCount, setServicePriceCount] = useState(0);

  const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(parseInt(pageValue));
  const itemsPerPage = 15;

  const [check, setCheck] = useState(true);

  // useEffect(() => {
  //   if (loading) return;

  //   const element = document.getElementById('top');
  //   element.scrollIntoView({ behavior: 'smooth' });
  // }, [check]);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/service-management/${service_id}?page=${value}`);
  }

  useEffect(() => {
    setLoading(true);
    ServiceManagementService.fetchService(
      auth.accountId,
      service_id,
      auth.token
    ).then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setServiceDetails(res);
      }
      setLoading(false);
    });
    setLoading(true);
    ServiceManagementService.fetchServicePrice(
      auth.accountId,
      service_id,
      auth.token,
      page
    ).then((res) => {
      if (res.error) {
        console.log(res.error);
        setServicePriceList([])
        setServicePriceCount(0)
        setLoading(false);
      } else {
        setServicePriceList(res?.data);
        setServicePriceCount(res?.servicePriceCount);
        setLoading(false);
      }
    });
  }, [run, page]);

  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const handleDelete = () => {
    setLoading(true);
    ServiceManagementService.deleteServicePrice(
      auth.accountId,
      deleteId,
      auth.token
    ).then((res) => {
      if (res.error) {
        notify(dispatch, 'error', res.error);
      } else {
        setRun(!run);
        notify(dispatch, 'success', res.message);
      }
      setLoading(false);
    });
  };

  const [open, setOpen] = useState(false);

  const deleteClick = (id) => {
    setConfirmSubmit(true);
    setDeleteId(id);
  };

  const [edit, setEdit] = useState({
    edit: false,
    editData: null
  });

  const handleEdit = (data) => {
    if (data) {
      setEdit({
        ...edit,
        edit: true,
        editData: data
      });
      setOpen(true);
    } else {
      setEdit({
        ...edit,
        edit: false,
        editData: data
      });
      setOpen(false);
    }
  };

  const TableHeading = [
    'Country Name',
    'Country Code',
    'Price',
    'Last updated by',
    'Action'
  ];

  const Keys = ['label', 'value', 'price', 'last_updated_by', '_id'];
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [moreOptionClicked, setMoreOptionClicked] = useState(null);

  const TableData = servicePriceList?.map((item, index) =>
    Keys.map((Key) => {
      if (Key === 'label') {
        return item.country.name;
      }
      if (Key === 'value') {
        return item.country.code;
      }

      if (Key === 'price') {
        return (
          <>
           {getCurrencyIcon(item.price.currency)}{numberToLocaleString(item.price.value)} {item.price.currency.toUpperCase()}
          </>
        );
      }

      if (Key === '_id') {

        return (
          <>
            <div className="position-relative">
              <center>
                <Tooltip title="More" arrow>
                  <Button
                    variant="text"
                    className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
                    onClick={(e) => {
                      setOpenMoreOptions(e.currentTarget);
                      setMoreOptionClicked(item);
                    }}>
                    <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                  </Button>
                </Tooltip>
              </center>
              {moreOptionClicked?._id === item?._id && (
                <SmallPopupWrapper
                  open={openMoreOptions}
                  onClose={() => {
                    setMoreOptionClicked(null);
                    setOpenMoreOptions(null);
                  }}>
                  <MenuItem
                    onClick={() => {
                      handleEdit(moreOptionClicked)
                      setOpenMoreOptions(null);


                    }}>
                    Edit
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      deleteClick(moreOptionClicked._id)
                      setOpenMoreOptions(null);


                    }}>
                    Delete
                  </MenuItem>


                </SmallPopupWrapper>
              )}
            </div>
          </>
        );
        // return (
        //   <>
        //     <div className="position-relative">
        //       <center>
        //         <div style={{ display: 'flex', gap: '0.7rem' }}>
        //           <Tooltip title="Edit Service" arrow>
        //             <Button
        //               variant="text"
        //               className="btn-neutral-primary d-flex align-items-center justify-content-center shadow-none"
        //               onClick={() => handleEdit(item)}>
        //               Edit
        //               {/* <VisibilityIcon style={{ fontSize: '18px' }} /> */}
        //             </Button>
        //           </Tooltip>
        //           <Tooltip title="Delete Service" arrow>
        //             <Button
        //               variant="text"
        //               className="btn-outline-primary d-flex align-items-center justify-content-center shadow-none"
        //               onClick={() => handleDeldeleteClickete(item._id)}>
        //               Delete
        //               {/* <MoreVertTwoToneIcon style={{ fontSize: '18px' }} /> */}
        //             </Button>
        //           </Tooltip>
        //         </div>
        //       </center>
        //     </div>
        //   </>
        // );
      } else {
        return item[Key] || '';
      }
    })
  );

  return (
    <>
      <PageTitle
        goBack={() => history.go(-1)}
        titleHeading={serviceDetails?.serviceName}
      >
        <Button
          className={`btn-primary`}
          size="small"
          onClick={() => {
            setOpen(true);
          }}>
          Add Price
        </Button>
      </PageTitle>
      <PageContent>
        {/* <div className={Style.header_button}>
          <Button
            className={`btn-neutral-primary`}
            size="small"
            startIcon={<West />}
            onClick={() => history.replace('/service-management')}
            >
            Back
          </Button>
          <Button
            className={`btn-primary`}
            size="small"
            onClick={() => {
              setOpen(true);
            }}>
            Add Price
          </Button>
        </div> */}

        {serviceDetails && (
          <div className={Style.service_info_container}>
            <div className={Style.icon_background}>
              <img src={serviceDetails.icon} />
            </div>
            <h4 className={Style.Service_name}>
              {serviceDetails.serviceName}
            </h4>
            <div className={Style.description_container}>
              <span className={Style.heading}>Service Description: </span>
              <span className={Style.description}>
                {serviceDetails.serviceDescription}
              </span>
            </div>
            <div className={Style.description_container}>
              <span className={Style.heading}>Default Price: </span>
              <span className={Style.description}>
                {getCurrencyIcon(serviceDetails.default_price.currency)}{numberToLocaleString(serviceDetails.default_price.value)} {serviceDetails.default_price.currency.toUpperCase()}
              </span>
            </div>
            <div className={Style.description_container}>
              <span className={Style.heading}>Created By: </span>
              <span className={Style.description}>
                {serviceDetails.created_by}
              </span>
            </div>
            <div className={Style.description_container}>
              <span className={Style.heading}>Created At: </span>
              <span className={Style.description}>
                {moment(serviceDetails.created_at).format("ll")}
              </span>
            </div>
          </div>
        )}
        <TableWithSearchBar
          tableMainHeading={'All Prices'}
          loading={loading}
          TableHeading={TableHeading}
          TableBody={TableData}
          TableGrid={'repeat(5,1fr)'}
          actionButtons={[
            <Button
              variant="text"
              onClick={() => setRun(!run)}
              className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
              <CachedIcon className="w-50" />
            </Button>,
            <Button
              className={`btn-primary`}
              onClick={() => {
                setOpen(true);
              }}>
              Add Price
            </Button>
          ]}
          pagination={{
            count: Math.ceil(servicePriceCount / itemsPerPage),
            page: page,
            onChange: handlePageChange
          }}
        />
      </PageContent>

      {/* <ModalPopupWrapper open={open} > */}
      {open && (
        <AddServicePricePopup
          service_id={service_id}
          run={run}
          setRun={setRun}
          handleClose={() => setOpen(false)}
          handleEdit={handleEdit}
          edit={edit.edit}
          open={open}
          editData={edit.editData}
        />
      )}
      {/* </ModalPopupWrapper> */}

      <ConfirmDialog
        confirmDialogFunction={handleDelete}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure to delete this price for the selected country`}
      />
    </>
  );
};
/* eslint-enable */
export default SingleServicePage;
