import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Step, StepContent, StepLabel, Stepper } from '@mui/material'
import { Button } from '@material-ui/core';
import Style from "./StatusStepper.module.css";
import { ForwarderService } from 'service';
import { useSelector } from 'react-redux';
import ConfirmDialog from 'sites/shipper/pages/shipment_rates/rate_sheet/ConfirmDialog/ConfirmDialog';
import { notify } from 'sites/shipper/components/Toast/toast';
import { useDispatch } from 'react-redux'




const StatusStepper = ({ shippmentId, shipmentStatus, run, setRun }) => {

    const [activeStep, setActiveStep] = useState();


    const dispatch = useDispatch()


    const [steps, setSteps] = useState([
        { label: 'Pending' },
        { label: 'Shipment Initiated' },
        { label: 'Booking Sent to Logistics provider' },
        { label: 'Transport Booked' },
        { label: 'Transhipment Loaded' },
        { label: 'Departed from Port' },
        { label: 'Arrived at Port' },
        { label: 'Delivered' },
    ])

    useEffect(() => {
        steps.map((item, index) => {
            if (item.label === shipmentStatus) {
                setActiveStep(index)
            }
        })
    }, [shipmentStatus])

    const auth = useSelector((state) => state.session.auth)

    const [allow, setAllow] = useState(false)


    // useEffect(() => {
    //     ForwarderTeam.fetchForwarderPermission(auth.accountId, "booking_status", auth.token).then(res => {
    //         if (res.success) {
    //             setAllow(true)
    //         } else {
    //             setAllow(false)
    //         }
    //     })
    // }, [])


    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    
    const handleNext = () => {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        let newStatus = ''
        steps.map((item, index) => {
            if (index === activeStep + 1) {
                newStatus = item.label
            }
        })
        const data_to_send = {
            id: shippmentId,
            status: newStatus,
            forwarder_id: auth.accountId,
            company_id: auth.cmpyId
        }
        ForwarderService.updateShipmentStatus(data_to_send).then(data => {
            if (data.error) {
                // window.alert(data.error)
                notify(dispatch, "error", data.error)
            } else {
                // window.alert(data.message)
                notify(dispatch, "success", data.message)
                setRun(!run)
            }
        })
    };
    return (
        <>

            <Stepper activeStep={activeStep} orientation="vertical" className={Style.stepper}>
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel className={Style.label}>
                            {step.label}
                        </StepLabel>
                        {((steps.length - 1) !== index)
                            &&
                            <>
                                {allow &&
                                    <StepContent>
                                        <div className='mb-2'>
                                            <div className='d-flex c-gap-2'>
                                                <Button
                                                    size='small'
                                                    className={`${Style.btn} btn-outline-dark`}
                                                >
                                                    Flag
                                                </Button>
                                                <Button
                                                    size='small'
                                                    className={`${Style.btn} btn-outline-primary`}
                                                    onClick={() => setConfirmDialogOpen(true)}
                                                >
                                                    Next Step
                                                </Button>
                                            </div>
                                        </div>
                                    </StepContent>
                                }
                            </>
                        }

                    </Step>
                ))}
            </Stepper>

            <ConfirmDialog
                confirmDialogOpen={confirmDialogOpen}
                setConfirmDialogOpen={setConfirmDialogOpen}
                confirmDialogFunction={handleNext}
                question={`Are you sure you want to update status to ${steps[activeStep + 1]?.label}`}
            />


        </>

    )
}

export default StatusStepper