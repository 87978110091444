import Tabs from 'components/Tabs';
import React, { useEffect, useState } from 'react';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import PageContent from 'sites/shipper/components/layout/PageContent';
import { useHistory, useParams } from 'react-router-dom';
import ViewCarriers from './ViewCarriers';

import TrackingSources from './TrackingSources';
import { useSelector } from 'react-redux';
import AdminTeam from 'service/AdminTeam';
import PageError403 from 'components/PageError403';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
/* eslint-disable */
const Index = () => {
  const history = useHistory();
  const auth = useSelector((state) => state.session.auth);
  const transportLineInfo = useSelector(
    (state) => state.transportUnitNames.transport_lines_info
  );
  const { id } = useParams();

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get('tab');

  useEffect(() => {
    if (!transportLineInfo || transportLineInfo?.id !== id) {
      history.replace('/carriers');
    }
  }, [transportLineInfo]);
  const content = [
    {
      label: 'Carrier Vessels',
      value: 'carrier-vessels',
      View: <ViewCarriers tab={'carrier-vessels'} carrier_id={id} />
    },
    {
      label: 'Tracking Sources',
      value: 'tracking-sources',
      View: <TrackingSources tab={'tracking-sources'} carrier_id={id} />
    }
  ];

  const [allow, setAllow] = useState(true);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   AdminTeam.fetchMemberPermission(
  //     auth.accountId,
  //     'carriers',
  //     auth.token
  //   ).then((res) => {
  //     if (res.success) {
  //       setAllow(true);
  //     } else {
  //       setAllow(false);
  //     }
  //     setLoading(false);
  //   });
  // }, []);
  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      {allow ? (
        <>
          {' '}
          <PageTitle
            goBack={() => history.go(-1)}
            titleHeading={`${transportLineInfo.fullName}`}></PageTitle>
          <PageContent>
            <div style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem"
            }}>
              <TrackingSources tab={'tracking-sources'} carrier_id={id} />
              <ViewCarriers tab={'carrier-vessels'} carrier_id={id} />
            </div>
            {/* <Tabs
              content={content}
              active={tab || content[0].value}
            
                onClick={(e) => history.push(`/carriers/${id}?tab=${e}`)}
            /> */}
          </PageContent>{' '}
        </>
      ) : (
        <>
          <PageError403 />
        </>
      )}
    </>
  );
};
/* eslint-enable */

export default Index;
