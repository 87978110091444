import { combineReducers } from 'redux';
import dashboard from './dashboard';
import drawer from './drawer';
import messages from './messages';
import serviceworker from './serviceworker';
import signup from './signup';
import session from './session';
import user from './user';
import billing from './billing';
import ThemeOptions from './ThemeOptions';
import makeOfferDetailsReducer from './makeOfferDetails';
import chatRoom from './chat';
import socketConnection from './socket';
import filterDataRedux from './filterData';
import rateSheet from './rateSheet';

import loadingReducer from './loader'
import transportUnitNames from './transportUnitNames';
const rootReducer = combineReducers({
  dashboard,
  drawer,
  messages,
  serviceworker,
  signup,
  session,
  user,
  billing,
  ThemeOptions,
  makeOfferDetailsReducer,
  chatRoom,
  socketConnection,
  filterDataRedux,
  rateSheet,
  transportUnitNames,
  loadingReducer
});

export default rootReducer;
