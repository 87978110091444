import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Sidebar from './Sidebar';
import { setSidebarToggle } from 'reducers/ThemeOptions';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable))
    },
    dispatch
  );
};

const mapStateToProps = (state) => ({
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarFooter: state.ThemeOptions.sidebarFooter,
  sidebarStyle: state.ThemeOptions.sidebarStyle,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  setSidebarToggle: state.ThemeOptions.setSidebarToggle
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
