import { getBaseUrl } from 'config/config';
import { HttpMethods, httpService } from './global';

export default class SubscriberService {
  static async fetchSubscriber(id, token, page, data) {
    const url = `admin/fetch-all-subscribers?userId=${id}&page=${page}`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'subscribers',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));


  }

  static async downloadSubscriber(id, token) {
    const url = `admin/download-subscribers?userId=${id}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'subscribers',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static async editSubscriber(id, data, token) {
    const url = `admin/edit-subscribers?userId=${id}`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'subscribers',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }
  static async createSubscriber(id, data, token) {
    const url = `admin/create-subscribers?userId=${id}`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'subscribers',
      method: 'create',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }

  static async deleteSubscriber(id, subscriber_id, removeName, token) {
    const url = `admin/delete-subscribers?userId=${id}&delete_id=${subscriber_id}&name=${removeName}`;
    return httpService(HttpMethods.DELETE, url, {}, {
      token,
      type: 'subscribers',
      method: 'delete',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));

  }
}
