import React from 'react'
import Table from './Table/Table'
import Style from "./Preview.module.css";

const Preview = ({ transportMode, pageData, method, breakdownData, setBreakdownData,isSubmitDisable, setIsSubmitDisable }) => {
    // console.log(breakdownData)
    return (
        <div className={Style.container}>
            <span className={Style.heading}>Preview</span>

            <div className={Style.main}>

                {breakdownData?.price_breakdown?.map((data, idx) => (
                    <Table isSubmitDisable={isSubmitDisable} setIsSubmitDisable={setIsSubmitDisable} transportMode={transportMode} pageData={pageData} method={method} key={idx} idx={idx} data={data} breakdownData={breakdownData} setBreakdownData={setBreakdownData} />
                ))}


            </div>
        </div>
    )
}

export default Preview