import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyMceEditor = ({ onInputChange, initialValue }) => {
  const [value, setValue] = useState(initialValue ?? '');

  useEffect(() => setValue(initialValue ?? ''), [initialValue]);

  useEffect(() => {
    if (value.length < 7) {
      return;
    }
    onInputChange(value);
  }, [value]);

  return (
    <div
      style={{
        // border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '6px',
        minHeight: '350px',
        margin: '10px 0 0 0',
        // padding: '1rem'
      }}>
      <Editor
        apiKey="aqygb0nray6ndft5xnofephz987r5xyievp496djp24o3kvq"
        initialValue={initialValue}
        onEditorChange={(newValue, editor) => setValue(newValue)}
        init={{
          branding: false,
          height: 400,
          menubar: true,
          plugins:
            'print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern',
          toolbar:
            'formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat',
          image_advtab: true
        }}
      />
    </div>
  );
};

export default TinyMceEditor;
