import { getBaseUrl } from 'config/config';
import { HttpMethods, httpService } from './global';

export default class CareerService {
  static async fetchCareers(data, token,page) {
    const url = `admin/fetch-careers?page=${page}`;
     return httpService(HttpMethods.POST, url, data, {
       token,
       type: 'career',
       method: 'read',
       platform: 'Admin'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
   
  }

 
  static async createCareer(data, token) {
    const url = `admin/create-career`;
      return httpService(HttpMethods.POST, url, data, {
        token,
        type: 'career',
        method: 'create',
        platform: 'Admin'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  }

 

  static async removeCareer(data, token) {
    const url = `admin/remove-career`;
     return httpService(HttpMethods.DELETE, url, data, {
       token,
       type: 'career',
       method: 'delete',
       platform: 'Admin'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));

  }

 
  static async fetchCareerDetails(data, token) {
    const url = `admin/fetch-career-details`;
     return httpService(HttpMethods.POST, url, data, {
       token,
       type: 'career',
       method: 'read',
       platform: 'Admin'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
  
  }

 

  static async updateCareerDetails(data, token) {
    const url = `admin/update-career`;
      return httpService(HttpMethods.PUT, url, data, {
        token,
        type: 'career',
        method: 'edit',
        platform: 'Admin'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  }

 

  static async checkSlugAvailability(data, token) {
    const url = `admin/check-career-slug-availability`;
      return httpService(HttpMethods.POST, url, data, {
        token,
        type: 'career',
        method: 'read',
        platform: 'Admin'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
  
  }

 
}
