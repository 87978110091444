import { getBaseUrl } from "config/config";

export const httpService = async (method, endpoint, additionalData, header) => {
  const URL = `${getBaseUrl()}${endpoint}`;
  let configuration = {
    method: method.toUpperCase() || HttpMethods.GET,
    headers: {
      'Content-Type': HeaderContentTypes.JSON
    }
  };
  if (method.toUpperCase() !== HttpMethods.GET) {
    configuration.body = JSON.stringify(additionalData);
  }
 if (header) {
   if (typeof header === 'string') {
     const token = header;
     configuration.headers.Authorization = `Bearer ${token}`;
   } else {
     if (header?.token) {
       const token = header?.token;
       configuration.headers.Authorization = `Bearer ${token}`;
     }

     configuration.headers.type = header?.type;
     configuration.headers.method = header?.method;
     configuration.headers.platform = header?.platform;
   }
 }
  try {
    const response = await fetch(URL, configuration);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error in API call:', error);
    throw error;
  }
};

export const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
};

export const HeaderContentTypes = {
    FORMDATA: 'multipart/form-data',
    JSON: 'application/json; charset=UTF-8',
};
