import { HttpMethods, HttpService } from './index';


export default class DashService {
  static checkUserSession(token) {
    return HttpService.request(HttpMethods.GET, `admin/session`, {}, {}, token).then(
      (res) => res
    );
  }
}

