import React from 'react'
import Style from "./GridViewRateSheet.module.css"
import AnimatedSearchbar from 'components/Common/AnimatedSearchbar/AnimatedSearchbar'
import { Tooltip } from '@material-ui/core'
import SkeletonComponent from 'sites/shipper/components/Skeleton/Skeleton'
const GridViewRateSheet = ({
    animatedSearchbar = {},
    actionButtons,
    tableMainHeading,
    GridViewHeadings,
    GridData,
    loading= false
}) => {
    return (
        <>
            <div className={Style.main}>
                {animatedSearchbar.show ?
                    <AnimatedSearchbar
                        value={animatedSearchbar.value}
                        onChange={animatedSearchbar.onChange}>
                        {actionButtons.map((item, index) => { return item })}
                    </AnimatedSearchbar>
                    :
                    <div className={Style.tableMainHeadingContainer}>
                        <div className="app-page-title--heading ml-2">
                            <h1>{tableMainHeading}</h1>
                        </div>
                        <div className='d-flex c-gap-half'>
                            {actionButtons.map((item, index) => { return item })}
                        </div>
                    </div>
                }










                <div className={Style.containerTable}>
                    <div className={Style.cointainerHeading} style={{ gridTemplateColumns: `repeat(${GridViewHeadings.length},1fr)` }}>
                        {GridViewHeadings.map((data, index) => (
                            <span className={Style.cointainerHeadingText} key={index} >{data}</span>
                        ))}
                    </div>
                    {loading && <SkeletonComponent instances={28} columns={4} height='160px' />}
                    {loading === false && (GridData?.length > 0 && GridData?.map((ex, index) => {
                        return <div className={Style.containerBody} style={{ gridTemplateColumns: `repeat(${ex.length},1fr)` }}>
                            {ex.map((item, idx) => {
                                return (<> {item}</>)
                            }
                            )}
                        </div>
                    }))}
                </div>
            </div>
        </>
    )
}

export default GridViewRateSheet