import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';

export default class DashboardService {
  static async fetchInsightsOverview(token) {
    const url = `${getBaseUrl()}admin/fetch-dashboard-overview`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'dashboard',
        method: 'earnings_overview_card',
        platform: 'Admin'
      }
    });
    const response = await result.json();
    return response;
  }



  // used in shipments and dashboard
  static async fetchShipmentsAnalytics(token,type,method) {
    const url = `${getBaseUrl()}admin/fetch-shipment-analytics`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: type,
        method: method,
        platform: 'Admin'
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchUserGrowthChartAnalytics(token,option) {
    const url = `${getBaseUrl()}admin/fetch-user-growth-chart?option=${option}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'dashboard',
        method: 'shipper_growth',
        platform: 'Admin'
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchTransactionsAnalytics(token) {
    const url = `${getBaseUrl()}admin/fetch-transactions-details`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'dashboard',
        method: 'transaction',
        platform: 'Admin'
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchNewForwarders(token) {
    const url = `${getBaseUrl()}admin/fetch-new-forwarders`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'dashboard',
        method: 'forwarder',
        platform: 'Admin'
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchNewShippers(token) {
    const url = `${getBaseUrl()}admin/fetch-new-shippers`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        type: 'dashboard',
        method: 'shipper',
        platform: 'Admin'
      }
    });
    const response = await result.json();
    return response;
  }
}
