/*eslint-disable*/

import { CARGO_CATEGORIES, TRANSPORT_MODES } from "util/constants";

export const chargeType = {
    "Freight": "Freight",
    "Free On Board (FOB)": "Freight",
    "DDU": "DDU",
}

export const chargeTypeArray = [
    {
        label: chargeType.Freight,
        value: chargeType.Freight
    },
    {
        label: chargeType["Free On Board (FOB)"],
        value: chargeType["Free On Board (FOB)"]
    },
    {
        label: chargeType.DDU,
        value: chargeType.DDU
    }
]



const chargeDetailsArray = [
    {
        feeCode: "LSC",
        feeName: "LOW SULPHUR CHARGE",
        description: "It is a surcharge for vessels operating in the European Union area.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import / Export",
            "All countries"
        ]
    },
    {
        feeCode: "PCC",
        feeName: "PANAMA CANAL CHARGE",
        description: "It is a surcharge applied to cargo transiting through the Panama Canal.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import / Export",
            "USA"
        ]
    },
    {
        feeCode: "OCE",
        feeName: "OCEAN FREIGHT",
        description: "It is the cost of shipping a container from one point to another.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import / Export",
            "All countries"
        ]
    },
    {
        feeCode: "SUE",
        feeName: "SUEZ CANAL SURCHARGE",
        description: "It is a surcharge applied to cargo transiting through the Suez Canal.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import / Export",
            "All countries"
        ]
    },
    {
        feeCode: "ADE",
        feeName: "ADEN GULF SURCHARGE",
        description: "The Gulf of Aden is in the Republic of Yemen. Shipping companies are exposed to risk due to the presence of piracy. They see their costs increased due to higher insurance premiums because of this risk. For this reason a surcharge is applied to compensate costs.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import / Export",
            "Asia"
        ]
    },
    {
        feeCode: "BAF",
        feeName: "BUNKER ADJUSTMENT FACTOR",
        description: "Fuel prices are subject to major price fluctuations. For that reason, shipping companies may apply a bunker surcharge of BUC (Bunker Contribution) to compensate price fluctuations.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import / Export",
            "All countries"
        ]
    },
    {
        feeCode: "CAF",
        feeName: "CURRENCY ADJUSTMENT FACTOR",
        description: "If your cargo is paid in a foreign currency, the currency may be subject to exchange fluctuations. For that reason shipping companies may apply a currency surcharge to compensate exchange rate risks. It is charged as a percentage of the basic sea freight.",
        comment: [
            "FCL: per-cent",
            "LCL: per-w/m",
            "Import / Export",
            "All countries"
        ]
    },
    {
        feeCode: "IFP",
        feeName: "INTERIM FUEL PARTICIPATION",
        description: "It is an additional cost equivalent to the BAF and is applied when there is an increase in fuel prices.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import / Export",
            "All countries"
        ]
    },
    {
        feeCode: "COL",
        feeName: "COLLECTION FEE",
        description: "It is the cost of collecting and delivering goods from the departure point to the depot destination.",
        comment: [
            "FCL: per-container",
            "LCL: per-Kg",
            "Import / Export",
            "All countries"
        ]
    },
    {
        feeCode: "SEC",
        feeName: "SECURITY FEE",
        description: "It is a security fee charged by ports per LCL or FCL.",
        comment: [
            "FCL: per-container",
            "LCL: per-file",
            "Import / Export",
            "All countries"
        ]
    },
    {
        feeCode: "CUS",
        feeName: "CUSTOMS ENTRY",
        description: "It is a tax imposed by countries to protect their domestic markets against foreign products.",
        comment: [
            "FCL: per-file",
            "LCL: per-file",
            "Import / Export",
            "All countries"
        ]
    },
    {
        feeCode: "DOC",
        feeName: "DOCUMENTATION",
        description: "It is an administration fee for preparation or handling import/export documentation.",
        comment: [
            "FCL: per-file",
            "LCL: per-file",
            "Import / Export",
            "All countries"
        ]
    },
    {
        feeCode: "OFUE",
        feeName: "ORIGIN FUEL SURCHARGE",
        description: "The Fuel surcharge is determined by the origin country and is applied due to oil prices. It is charged as a percentage of the sea freight.",
        comment: [
            "FCL: per-cent",
            "LCL: per-cent",
            "Import / Export",
            "All countries"
        ]
    },
    {
        feeCode: "OTHC",
        feeName: "ORIGIN TERMINAL HANDLING",
        description: "It is a charge for handling containers at the port of origin or terminal before being loaded onboard a vessel.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import / Export",
            "All countries"
        ]
    },
    {
        feeCode: "ACI",
        feeName: "ADVANCED COMMERCIAL INFORMATION",
        description: "It is a fee charged for additional costs of declaring cargo information in advance to Canada authorities.",
        comment: [
            "FCL: per-file",
            "LCL: per-file",
            "Export",
            "Canada"
        ]
    },
    {
        feeCode: "AMA",
        feeName: "ADVANCED MANIFEST FILING (CHINA & JAPAN)",
        description: "It is a fee charged for additional costs of declaring cargo information in advance to China and Japan authorities.",
        comment: [
            "FCL: per-file",
            "LCL: per-file",
            "Import/Export",
            "China and Japan"
        ]
    },
    {
        feeCode: "AMS",
        feeName: "AMS SECURITY FEE (USA)",
        description: "Automated Manifest System (AMS) is a regulation that affects all ships bringing cargo into US ports. Comprised air/sea/rail modes. It is a electronic transmission system to US Customs (CBP) before the cargo arrives in the US. This is for security measures to avoid terrorism. This fee is charged for additional costs of declaring cargo information in advance.",
        comment: [
            "FCL: per-file",
            "LCL: per-file",
            "Export",
            "USA"
        ]
    },
    {
        feeCode: "CON",
        feeName: "CONGESTION SURCHARGE",
        description: "In some ports, the process of incoming and outgoing vessels may be delayed. That congestion brings costs and to compensate those costs an additional surcharge is applied on the sea freight.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "EBD",
        feeName: "EMERGENCY BUNKER DIFFERENTIAL",
        description: "It is an emergency expense for covering higher fuel prices than the ones that were originally anticipated. The charge is based on the size and type of the container, as well as, the destination port.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "ERS",
        feeName: "EMERGENCY RISK SURCHARGE",
        description: "It is an additional cost charged by the shipping line for handling cargo in dangerous regions including hazards, violence or piracy. The charge covers extra bunker cost, insurance coverage, and additional security measures.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "GRI",
        feeName: "GENERAL RATE INCREASE",
        description: "Used to describe an across-the-board tariff rate increase implemented by conference members and applied to base rates.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "HEA",
        feeName: "HEAVY LIFT",
        description: "An additional charge for handling heavy cargo that weighs more than the standard limit for a shipment.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "OOG",
        feeName: "OUT OF GAUGE SURCHARGE",
        description: "It is an additional charge for cargo that is out of the standard dimensions of a container and it is applied for lost slots because no other containers can be loaded.",
        comment: [
            "FCL: per-container",
            "LCL: N/A",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "OWS",
        feeName: "OVER WEIGHT SURCHARGE",
        description: "It is charged by shipping companies for handling heavy containers that weigh more than the standard limit of a cargo. Generally applies to 20ft containers exceeding the weight of 14,000 kg tare.",
        comment: [
            "FCL: per-container",
            "LCL: N/A",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "PSS",
        feeName: "PEAK SEASON SURCHARGE",
        description: "It is a fee applied in periods of peak demand. It is levied to all shipments that move in certain trades during the high volume periods.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "SPE",
        feeName: "SPECIAL EQUIPMENT SURCHARGE",
        description: "It is an additional cost applied for Hanging Garment containers, In-Gauge Flats and Open Tops, and 45 fts because they need to be transported in a special temperature environment.",
        comment: [
            "FCL: per-container",
            "LCL: N/A",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "WAR",
        feeName: "WAR RISK",
        description: "It is an additional cost applied by shipping lines due to risk of war to compensate extra costs to bear.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "LWR",
        feeName: "LOW WATER SURCHARGE",
        description: "It is an additional cost applied by the Port of Montreal due to low water levels in the St. Lawrence River. It is applied for all shipments that transit this port.",
        comment: [
            "FCL: per-container",
            "LCL: N/A",
            "Import/Export",
            "Canada"
        ]
    },
    {
        feeCode: "SED",
        feeName: "SANTOS EMERGENCY DREDGING FEE",
        description: "It is an additional charge for transiting the port of Santos (Brazil) due to dredging service.",
        comment: [
            "FCL: per-container",
            "LCL: N/A",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "HAZ",
        feeName: "HAZARDOUS SURCHARGE",
        description: "It is a surcharge imposed for shipping hazardous goods.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "MAR",
        feeName: "MARPOL SURCHARGE",
        description: "It is a Marine Pollution regulation in the international Convention for the Prevention of Pollution from ships in the EU.",
        comment: [
            "FCL: per-container",
            "LCL: N/A",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "SMD",
        feeName: "SECURITY MANIFEST DOCUMENTATION FEE",
        description: "An additional cost for submitting in advance manifest declarations to US, Canadian and Mexican customs authorities. It is applied for cargo loaded on a vessel at a non-US port.",
        comment: [
            "FCL: per-file",
            "LCL: per-file",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "EIS",
        feeName: "EQUIPMENT IMBALANCE SURCHARGE",
        description: "It is a temporary cost charged by shipping lines to compensate the cost of relocating large quantities of empty containers between countries where there is an imbalance of trade (there is no export use for those containers that had been previously imported into those countries).",
        comment: [
            "FCL: per-container",
            "LCL: N/A",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "ECA",
        feeName: "EMISSION CONTROL AREA",
        description: "It is the Emission Control Area by the MARPOL Protocol to reduce airborne emissions from ships.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "DFUE",
        feeName: "DESTINATION FUEL SURCHARGE",
        description: "The Fuel surcharge is determined by the destination country and is applied due to oil prices. It is charged as a percentage of the sea freight.",
        comment: [
            "FCL: per-cent",
            "LCL: per-cent",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "DHC",
        feeName: "DESTINATION HANDLING CHARGE",
        description: "It is a charge for handling containers at the destination port or terminal before being loaded onboard a vessel.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "HQA",
        feeName: "HIGH CUBE ADDITIONAL",
        description: "It is a charge that may be applied when you use a High Cube container.",
        comment: [
            "FCL: per-container",
            "LCL: N/A",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "CCF",
        feeName: "CONTAINER CLEANING FEE",
        description: "It is a charge applied for extra costs for cleaning the container. It is applicable when the container does not meet the standard of cleanliness when is returned from the customer.",
        comment: [
            "FCL: per-container",
            "LCL: N/A",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "CHF",
        feeName: "CHASSIS FEE",
        description: "An additional cost for the use of a chassis to facilitate overland transportation.",
        comment: [
            "FCL: per-container",
            "LCL: N/A",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "PPF",
        feeName: "PIER PASS FEE",
        description: "It is The Traffic Mitigation Fee paid by the owner of a cargo and applied during peak hours when a container arrives or leaves Los Angeles or Long Beach marine terminal by truck.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "WHA",
        feeName: "WHARFAGE",
        description: "It is a fee applied by a port authority to the carrier for the usage of a port’s wharf.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "ONC",
        feeName: "ONCARRIAGE",
        description: "It is a charge for moving a container after is unloaded at the port of discharge. It is applied for inland movement service.",
        comment: [
            "FCL: per-container",
            "LCL: per-Kg",
            "Import/Export",
            "All countries"
        ]
    },
    {
        feeCode: "DES",
        feeName: "DESTINATION CHARGES",
        description: "It is a charged applied to the place which cargo is consigned or where the cargo is turned over to the consignee or agent.",
        comment: [
            "FCL: per-container",
            "LCL: per-w/m",
            "Import/Export",
            "All countries"
        ]
    }
];




export const weightClassLimits = {
    WC1: {
        min: 0,
        max: 9
    },
    WC2: {
        min: 10,
        max: 29
    },
    WC3: {
        min: 30,
        max: 49
    },
    WC4: {
        min: 50,
        max: 99
    },
    WC5: {
        min: 100,
        max: 299,
    },
    WC6: {
        min: 300,
        max: 499,
    },
    WC7: {
        min: 500,
        max: 999
    },
    WC8: {
        min: 1000
    }
}



export const weightClassArray = [
    {
        feeCode: "WC1",
        feeName: "Weight Class 1 ",
        description: `Weight range from ${weightClassLimits.WC1.min} - ${weightClassLimits.WC1.max}`,
        comment: [
            "per-kg",
            // "per-lb",
        ]
    },
    {
        feeCode: "WC2",
        feeName: "Weight Class 2 ",
        description: `Weight range from ${weightClassLimits.WC2.min} - ${weightClassLimits.WC2.max}`,
        comment: [
            "per-kg",
            // "per-lb",
        ]
    },
    {
        feeCode: "WC3",
        feeName: "Weight Class 3 ",
        description: `Weight range from ${weightClassLimits.WC3.min} - ${weightClassLimits.WC3.max}`,
        comment: [
            "per-kg",
            // "per-lb",
        ]
    },
    {
        feeCode: "WC4",
        feeName: "Weight Class 4 ",
        description: `Weight range from ${weightClassLimits.WC4.min} - ${weightClassLimits.WC4.max}`,
        comment: [
            "per-kg",
            // "per-lb",
        ]
    },
    {
        feeCode: "WC5",
        feeName: "Weight Class 5 ",
        description: `Weight range from ${weightClassLimits.WC5.min} - ${weightClassLimits.WC5.max}`,
        comment: [
            "per-kg",
            // "per-lb",
        ]
    },
    {
        feeCode: "WC6",
        feeName: "Weight Class 6 ",
        description: `Weight range from ${weightClassLimits.WC6.min} - ${weightClassLimits.WC6.max}`,
        comment: [
            "per-kg",
            // "per-lb",
        ]
    },
    {
        feeCode: "WC7",
        feeName: "Weight Class 7 ",
        description: `Weight range from ${weightClassLimits.WC7.min} - ${weightClassLimits.WC7.max}`,
        comment: [
            "per-kg",
            // "per-lb",
        ]
    },
    {
        feeCode: "WC8",
        feeName: "Weight Class 8 ",
        description: `Weight range from ${weightClassLimits.WC8.min} - ${weightClassLimits.WC8.max}`,
        comment: [
            "per-kg",
            // "per-lb",
        ]
    },
]

export const sectionName = {
    "Origin Charges": "Origin Charges",
    "Shipment Charges": "Shipment Charges",
    "Destination Charges": "Destination Charges",
    "Pickup Charges": "Pickup Charges",
    "Delivery Charges": "Delivery Charges",
    "Customs Charges": "Customs Charges",
    "Miscellaneous Charges": "Miscellaneous Charges"
}

export const chargeDetails = (transportMode, title) => {
    if (transportMode === TRANSPORT_MODES.SEA.label) {
        return chargeDetailsArray
    }
    if (transportMode === TRANSPORT_MODES.AIR.label) {
        if (title === sectionName["Shipment Charges"]) {
            return weightClassArray
        } else {
            return chargeDetailsArray
        }
    }
}



export const sectionNameArray = Object.keys(sectionName).map((item) => ({ label: item, value: item }))


export const getSectionName = (array) => {
    return sectionNameArray.filter(section =>
        !array.some(item => section.value === item.title)
    );
};


export const initialPriceBreakDown = (transportMode, data) => {
    const createChargeData = (detail) => ({
        fee_code: detail.feeCode,
        fee_name: detail.feeName,
        comment: detail.comment[0], // or adjust if you need multiple comments
        units: 1,
        unit_price: 50,
        amount: 50,
    });
    // data ? data?.load?.cargoDetails?.quantity : 
    // data ? data?.load?.cargoDetails?.quantity * 50 :
    const array = [
        {
            title: sectionName["Origin Charges"],
            data: [createChargeData(chargeDetails(transportMode, sectionName["Origin Charges"])[0])]
        }
    ];

    if (transportMode === TRANSPORT_MODES.SEA.label) {
        array.push({
            title: sectionName["Shipment Charges"],
            data: [createChargeData(chargeDetails(transportMode, sectionName["Shipment Charges"])[0])]
        });
    } else if (transportMode === TRANSPORT_MODES.AIR.label) {
        array.push({
            title: sectionName["Shipment Charges"],
            data: chargeDetails(transportMode, sectionName["Shipment Charges"]).map((detail) => createChargeData(detail))
        });
    }

    array.push({
        title: sectionName["Destination Charges"],
        data: [createChargeData(chargeDetails(transportMode, sectionName["Destination Charges"])[0])]
    });

    return array;
};


export const findLowestPriceItem = (array) => {
    if (array.length === 0) return [];

    // Find the object with the minimum `unit_price`
    const lowestPriceItem = array.reduce((minItem, currentItem) => {
        return parseFloat(currentItem.unit_price) < parseFloat(minItem.unit_price) ? currentItem : minItem;
    });

    // Return the result as an array with one element
    return [lowestPriceItem];

}

export const findHighestPriceItem = (array) => {
    if (array.length === 0) return [];

    // Find the object with the maximum `unit_price`
    const highestPriceItem = array.reduce((maxItem, currentItem) => {
        return parseFloat(currentItem.unit_price) > parseFloat(maxItem.unit_price) ? currentItem : maxItem;
    });

    // Return the result as an array with one element
    return [highestPriceItem];
}


export const renderLoadType = (data) => {
    if (data?.load_type === CARGO_CATEGORIES.PKGS.label) {
        return (
            <> {data?.load_type} | Unit : {data?.load?.cargoDetails?.quantity} | Volume : {data?.load?.cargoDetails?.volume?.volume} {data?.load?.cargoDetails?.volume?.unit} | Weight : {data?.load?.cargoDetails?.weight?.weight} {data?.load?.cargoDetails?.weight?.unit}</>
        )
    } else {
        return (
            <> {data?.load_type} | {data?.cargo_type}</>
        )
    }
}