import React, { useEffect, useState } from 'react';
import Style from './ActivityTab.module.css';
import ActivityCard from './ActivityCard/ActivityCard';
import { useSelector } from 'react-redux';
import AdminTeam from 'service/AdminTeam';
import { Button } from '@material-ui/core';
const ActivityTab = ({ profileData, refresh, setRefresh }) => {
  const auth = useSelector((state) => state.session.auth);

  const [activities, setActivities] = useState([]);
  const [activitiesCount, setActivitiesCount] = useState(0);

  const fetchActivity = () => {
    const skip = activities.length;
    AdminTeam.fetchAdminActivity(
      profileData?.id,
      auth.cmpyId,
      auth.token,
      skip
    ).then((res) => {
      if (res.error) {
        console.log(res?.error);
      } else {
        const array = [...activities, ...res.data];
        setActivities(array);

        setActivitiesCount(res?.activityCount);
      }
    });
  };

  useEffect(() => {
    fetchActivity();
  }, [refresh]);

  return (
    <div className={Style.container}>
      <div>
        <div className="app-page-title--heading">
          <h1>Activity Feed</h1>
        </div>
        <div className="divider my-3" />
      </div>
      {activities &&
        activities.length > 0 &&
        activities.map((item, index) => (
          <ActivityCard key={index} data={item} />
        ))}

      {activitiesCount > activities.length && (
        <div className="text-center">
          <Button
            className="btn-link btn-link-primary font-weight-bold "
            // className={Style.resetButton}
            onClick={fetchActivity}>
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default ActivityTab;
