import React from 'react';

import personalIcon from 'assets/images/illustrations/pack1/analysis.svg';
import companyIcon from 'assets/images/illustrations/pack1/time.svg';
import shippingIcon from 'assets/images/illustrations/pack1/wireframe.svg';
import paymentIcon from 'assets/images/illustrations/pack1/businessman.svg';
import securityIcon from 'assets/images/illustrations/pack1/authentication.svg';
import referralIcon from 'assets/images/illustrations/pack1/handshake.svg';
import notificationIcon from 'assets/images/illustrations/pack1/moving.svg';
import prefencesIcon from 'assets/images/illustrations/pack4/personal_settings.svg';
import IconCardLink from 'sites/shipper/components/atomic/IconCardLink';
import AccountCmpyLinkType from 'model/enum/AccountCmpyLinkType';
import { userPermission } from 'util/adminPermissionRoles';

const ShipperSettings = React.lazy(() => import('../Settings'));

//  Shipper Sub Pages
const ShipperShipmentDetails = React.lazy(() =>
  import('../Shipments/ShipmentDetails')
);
const ShipperSettingsPersonal = React.lazy(() =>
  import('../SettingsPersonalInfo')
);
const ShipperSettingsCompany = React.lazy(() =>
  import('../SettingsCompanyInfo')
);
const ShipperSettingsShipping = React.lazy(() =>
  import('../SettingsShippingInfo')
);
const ShipperSettingsPayments = React.lazy(() =>
  import('../SettingsSubscription')
);
const ShipperSettingsPrivacy = React.lazy(() => import('../SettingsPrivacy'));
const ShipperSettingsSecurity = React.lazy(() => import('../SettingsSecurity'));
const ShipperSettingsReferrals = React.lazy(() =>
  import('../SettingsReferrals')
);
const ShipperSettingsNotifications = React.lazy(() =>
  import('../SettingsNotifications')
);
const ShipperSettingsPreference = React.lazy(() =>
  import('../SettingsPreferences')
);

const SettingsRouteMaps = {
  '/settings/account': {
    allowed:[],
    protected: false,
    exact: true,
    label: 'General Account Settings',
    primary: false,
    path: '/settings/account',
    component: ShipperSettingsPersonal
  },
  // '/settings/company-info': {
  //   allowed:userPermission.company_settings.read,
  //   protected: true,
  //   exact: true,
  //   label: 'Company Info',
  //   primary: false,
  //   path: '/settings/company-info',
  //   component: ShipperSettingsCompany
  // },
  // '/settings/shipping-info': {
  //   protected: true,
  //   exact: true,
  //   label: 'Shipping Info',
  //   primary: false,
  //   path: '/settings/shipping-info',
  //   component: ShipperSettingsShipping
  // },
  // '/settings/subscription': {
  //   protected: true,
  //   exact: true,
  //   label: 'Subscription',
  //   primary: false,
  //   path: '/settings/subscription',
  //   component: ShipperSettingsPayments
  // },
  // '/settings/privacy': {
  //   protected: false,
  //   exact: true,
  //   label: 'Privacy',
  //   primary: false,
  //   path: '/settings/privacy',
  //   component: ShipperSettingsPrivacy
  // },
  '/settings/security': {
    allowed:userPermission.security_account_settings.read,
    protected: false,
    exact: true,
    label: 'Security',
    primary: false,
    path: '/settings/security',
    component: ShipperSettingsSecurity
  },
  // '/settings/referrals': {
  //   protected: false,
  //   exact: true,
  //   label: 'Referrals',
  //   primary: false,
  //   path: '/settings/referrals',
  //   component: ShipperSettingsReferrals
  // },
  '/settings/notifications': {
    allowed:userPermission.notifications_settings.read,
    protected: false,
    exact: true,
    label: 'Notifications',
    primary: false,
    path: '/settings/notifications',
    component: ShipperSettingsNotifications
  },
  '/settings/preferences': {
    allowed:userPermission.preferences_settings.read,
    protected: false,
    exact: true,
    label: 'Global Preferences',
    primary: false,
    path: '/settings/preferences',
    component: ShipperSettingsPreference
  }
};

export default SettingsRouteMaps;

export const SettingsSidebarMenus = [
  {
    icon: personalIcon,
    text: 'Account',
    description: 'Provide personal details and how we can reach you.',
    label: 'Manage Account',
    url: '/settings/account',
    protected: true,
    allowed:[]
  },
  // {
  //   icon: companyIcon,
  //   text: 'Company',
  //   description: 'Manage Company details and Team members',
  //   label: 'Manage Company',
  //   url: '/settings/company-info',
  //   protected: true,
  //   allowed:userPermission.company_settings.read
  // },
  // {
  //   icon: shippingIcon,
  //   text: 'Shipping',
  //   description: 'You can view, manage and customize your shipping details.',
  //   label: 'Manage Shipping',
  //   url: '/settings/shipping-info',
  //   protected: true
  // },
  // {
  //   icon: paymentIcon,
  //   text: 'Subscription',
  //   description: 'Manage Subscriptions and Plans',
  //   label: 'Manage Subscription',
  //   url: '/settings/subscription',
  //   protected: true
  // },
  {
    icon: securityIcon,
    text: 'Security',
    description: 'Update your password and secure your account.',
    label: 'Manage Security',
    url: '/settings/security',
    allowed:userPermission.security_account_settings.read
  },
  // {
  //   icon: referralIcon,
  //   text: 'Referrals',
  //   description: 'You have $0 CAD referral credits and coupon. Learn more.',
  //   label: 'Track Referrals',
  //   url: '/settings/referrals'
  // },
  {
    icon: notificationIcon,
    text: 'Notifications',
    description:
      'Choose notification preferences and how you want to be contacted',
    label: 'Manage Notifications',
    url: '/settings/notifications',
    allowed:userPermission.notifications_settings.read
  },
  {
    icon: prefencesIcon,
    text: 'Preferences',
    description: 'Manage default language, currency, and time-zone',
    label: 'Manage Preferences',
    url: '/settings/preferences',
    allowed:userPermission.preferences_settings.read
  }
];
