const countries = [
  { value: 'AF', label: 'Afghanistan', phone: '93' },
  { value: 'AL', label: 'Albania', phone: '355' },
  { value: 'DZ', label: 'Algeria', phone: '213' },
  { value: 'AX', label: 'Alland Islands', phone: '358' },
  { value: 'AS', label: 'American Samoa', phone: '1-684' },
  { value: 'AD', label: 'Andorra', phone: '376' },
  { value: 'AO', label: 'Angola', phone: '244' },
  { value: 'AI', label: 'Anguilla', phone: '1-264' },
  { value: 'AQ', label: 'Antarctica', phone: '672' },
  { value: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
  { value: 'AR', label: 'Argentina', phone: '54' },
  { value: 'AM', label: 'Armenia', phone: '374' },
  { value: 'AW', label: 'Aruba', phone: '297' },
  { value: 'AU', label: 'Australia', phone: '61', suggested: true },
  { value: 'AT', label: 'Austria', phone: '43' },
  { value: 'AZ', label: 'Azerbaijan', phone: '994' },
  { value: 'BS', label: 'Bahamas', phone: '1-242' },
  { value: 'BH', label: 'Bahrain', phone: '973' },
  { value: 'BD', label: 'Bangladesh', phone: '880' },
  { value: 'BB', label: 'Barbados', phone: '1-246' },
  { value: 'BY', label: 'Belarus', phone: '375' },
  { value: 'BE', label: 'Belgium', phone: '32' },
  { value: 'BZ', label: 'Belize', phone: '501' },
  { value: 'BJ', label: 'Benin', phone: '229' },
  { value: 'BM', label: 'Bermuda', phone: '1-441' },
  { value: 'BT', label: 'Bhutan', phone: '975' },
  { value: 'BO', label: 'Bolivia', phone: '591' },
  { value: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
  { value: 'BW', label: 'Botswana', phone: '267' },
  { value: 'BV', label: 'Bouvet Island', phone: '47' },
  { value: 'BR', label: 'Brazil', phone: '55' },
  { value: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
  { value: 'VG', label: 'British Virgin Islands', phone: '1-284' },
  { value: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { value: 'BG', label: 'Bulgaria', phone: '359' },
  { value: 'BF', label: 'Burkina Faso', phone: '226' },
  { value: 'BI', label: 'Burundi', phone: '257' },
  { value: 'KH', label: 'Cambodia', phone: '855' },
  { value: 'CM', label: 'Cameroon', phone: '237' },
  { value: 'CA', label: 'Canada', phone: '1', suggested: true },
  { value: 'CV', label: 'Cape Verde', phone: '238' },
  { value: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { value: 'CF', label: 'Central African Republic', phone: '236' },
  { value: 'TD', label: 'Chad', phone: '235' },
  { value: 'CL', label: 'Chile', phone: '56' },
  { value: 'CN', label: 'China', phone: '86' },
  { value: 'CX', label: 'Christmas Island', phone: '61' },
  { value: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
  { value: 'CO', label: 'Colombia', phone: '57' },
  { value: 'KM', label: 'Comoros', phone: '269' },
  { value: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
  { value: 'CG', label: 'Congo, Republic of the', phone: '242' },
  { value: 'CK', label: 'Cook Islands', phone: '682' },
  { value: 'CR', label: 'Costa Rica', phone: '506' },
  { value: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { value: 'HR', label: 'Croatia', phone: '385' },
  { value: 'CU', label: 'Cuba', phone: '53' },
  { value: 'CW', label: 'Curacao', phone: '599' },
  { value: 'CY', label: 'Cyprus', phone: '357' },
  { value: 'CZ', label: 'Czech Republic', phone: '420' },
  { value: 'DK', label: 'Denmark', phone: '45' },
  { value: 'DJ', label: 'Djibouti', phone: '253' },
  { value: 'DM', label: 'Dominica', phone: '1-767' },
  { value: 'DO', label: 'Dominican Republic', phone: '1-809' },
  { value: 'EC', label: 'Ecuador', phone: '593' },
  { value: 'EG', label: 'Egypt', phone: '20' },
  { value: 'SV', label: 'El Salvador', phone: '503' },
  { value: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { value: 'ER', label: 'Eritrea', phone: '291' },
  { value: 'EE', label: 'Estonia', phone: '372' },
  { value: 'ET', label: 'Ethiopia', phone: '251' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
  { value: 'FO', label: 'Faroe Islands', phone: '298' },
  { value: 'FJ', label: 'Fiji', phone: '679' },
  { value: 'FI', label: 'Finland', phone: '358' },
  { value: 'FR', label: 'France', phone: '33', suggested: true },
  { value: 'GF', label: 'French Guiana', phone: '594' },
  { value: 'PF', label: 'French Polynesia', phone: '689' },
  { value: 'TF', label: 'French Southern Territories', phone: '262' },
  { value: 'GA', label: 'Gabon', phone: '241' },
  { value: 'GM', label: 'Gambia', phone: '220' },
  { value: 'GE', label: 'Georgia', phone: '995' },
  { value: 'DE', label: 'Germany', phone: '49', suggested: true },
  { value: 'GH', label: 'Ghana', phone: '233' },
  { value: 'GI', label: 'Gibraltar', phone: '350' },
  { value: 'GR', label: 'Greece', phone: '30' },
  { value: 'GL', label: 'Greenland', phone: '299' },
  { value: 'GD', label: 'Grenada', phone: '1-473' },
  { value: 'GP', label: 'Guadeloupe', phone: '590' },
  { value: 'GU', label: 'Guam', phone: '1-671' },
  { value: 'GT', label: 'Guatemala', phone: '502' },
  { value: 'GG', label: 'Guernsey', phone: '44' },
  { value: 'GN', label: 'Guinea', phone: '224' },
  { value: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { value: 'GY', label: 'Guyana', phone: '592' },
  { value: 'HT', label: 'Haiti', phone: '509' },
  { value: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
  { value: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
  { value: 'HN', label: 'Honduras', phone: '504' },
  { value: 'HK', label: 'Hong Kong', phone: '852' },
  { value: 'HU', label: 'Hungary', phone: '36' },
  { value: 'IS', label: 'Iceland', phone: '354' },
  { value: 'IN', label: 'India', phone: '91' },
  { value: 'ID', label: 'Indonesia', phone: '62' },
  { value: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
  { value: 'IQ', label: 'Iraq', phone: '964' },
  { value: 'IE', label: 'Ireland', phone: '353' },
  { value: 'IM', label: 'Isle of Man', phone: '44' },
  { value: 'IL', label: 'Israel', phone: '972' },
  { value: 'IT', label: 'Italy', phone: '39' },
  { value: 'JM', label: 'Jamaica', phone: '1-876' },
  { value: 'JP', label: 'Japan', phone: '81', suggested: true },
  { value: 'JE', label: 'Jersey', phone: '44' },
  { value: 'JO', label: 'Jordan', phone: '962' },
  { value: 'KZ', label: 'Kazakhstan', phone: '7' },
  { value: 'KE', label: 'Kenya', phone: '254' },
  { value: 'KI', label: 'Kiribati', phone: '686' },
  {
    value: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850'
  },
  { value: 'KR', label: 'Korea, Republic of', phone: '82' },
  { value: 'XK', label: 'Kosovo', phone: '383' },
  { value: 'KW', label: 'Kuwait', phone: '965' },
  { value: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { value: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
  { value: 'LV', label: 'Latvia', phone: '371' },
  { value: 'LB', label: 'Lebanon', phone: '961' },
  { value: 'LS', label: 'Lesotho', phone: '266' },
  { value: 'LR', label: 'Liberia', phone: '231' },
  { value: 'LY', label: 'Libya', phone: '218' },
  { value: 'LI', label: 'Liechtenstein', phone: '423' },
  { value: 'LT', label: 'Lithuania', phone: '370' },
  { value: 'LU', label: 'Luxembourg', phone: '352' },
  { value: 'MO', label: 'Macao', phone: '853' },
  {
    value: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389'
  },
  { value: 'MG', label: 'Madagascar', phone: '261' },
  { value: 'MW', label: 'Malawi', phone: '265' },
  { value: 'MY', label: 'Malaysia', phone: '60' },
  { value: 'MV', label: 'Maldives', phone: '960' },
  { value: 'ML', label: 'Mali', phone: '223' },
  { value: 'MT', label: 'Malta', phone: '356' },
  { value: 'MH', label: 'Marshall Islands', phone: '692' },
  { value: 'MQ', label: 'Martinique', phone: '596' },
  { value: 'MR', label: 'Mauritania', phone: '222' },
  { value: 'MU', label: 'Mauritius', phone: '230' },
  { value: 'YT', label: 'Mayotte', phone: '262' },
  { value: 'MX', label: 'Mexico', phone: '52' },
  { value: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
  { value: 'MD', label: 'Moldova, Republic of', phone: '373' },
  { value: 'MC', label: 'Monaco', phone: '377' },
  { value: 'MN', label: 'Mongolia', phone: '976' },
  { value: 'ME', label: 'Montenegro', phone: '382' },
  { value: 'MS', label: 'Montserrat', phone: '1-664' },
  { value: 'MA', label: 'Morocco', phone: '212' },
  { value: 'MZ', label: 'Mozambique', phone: '258' },
  { value: 'MM', label: 'Myanmar', phone: '95' },
  { value: 'NA', label: 'Namibia', phone: '264' },
  { value: 'NR', label: 'Nauru', phone: '674' },
  { value: 'NP', label: 'Nepal', phone: '977' },
  { value: 'NL', label: 'Netherlands', phone: '31' },
  { value: 'NC', label: 'New Caledonia', phone: '687' },
  { value: 'NZ', label: 'New Zealand', phone: '64' },
  { value: 'NI', label: 'Nicaragua', phone: '505' },
  { value: 'NE', label: 'Niger', phone: '227' },
  { value: 'NG', label: 'Nigeria', phone: '234' },
  { value: 'NU', label: 'Niue', phone: '683' },
  { value: 'NF', label: 'Norfolk Island', phone: '672' },
  { value: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
  { value: 'NO', label: 'Norway', phone: '47' },
  { value: 'OM', label: 'Oman', phone: '968' },
  { value: 'PK', label: 'Pakistan', phone: '92' },
  { value: 'PW', label: 'Palau', phone: '680' },
  { value: 'PS', label: 'Palestine, State of', phone: '970' },
  { value: 'PA', label: 'Panama', phone: '507' },
  { value: 'PG', label: 'Papua New Guinea', phone: '675' },
  { value: 'PY', label: 'Paraguay', phone: '595' },
  { value: 'PE', label: 'Peru', phone: '51' },
  { value: 'PH', label: 'Philippines', phone: '63' },
  { value: 'PN', label: 'Pitcairn', phone: '870' },
  { value: 'PL', label: 'Poland', phone: '48' },
  { value: 'PT', label: 'Portugal', phone: '351' },
  { value: 'PR', label: 'Puerto Rico', phone: '1' },
  { value: 'QA', label: 'Qatar', phone: '974' },
  { value: 'RE', label: 'Reunion', phone: '262' },
  { value: 'RO', label: 'Romania', phone: '40' },
  { value: 'RU', label: 'Russian Federation', phone: '7' },
  { value: 'RW', label: 'Rwanda', phone: '250' },
  { value: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { value: 'SH', label: 'Saint Helena', phone: '290' },
  { value: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
  { value: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { value: 'MF', label: 'Saint Martin (French part)', phone: '590' },
  { value: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
  { value: 'WS', label: 'Samoa', phone: '685' },
  { value: 'SM', label: 'San Marino', phone: '378' },
  { value: 'ST', label: 'Sao Tome and Principe', phone: '239' },
  { value: 'SA', label: 'Saudi Arabia', phone: '966' },
  { value: 'SN', label: 'Senegal', phone: '221' },
  { value: 'RS', label: 'Serbia', phone: '381' },
  { value: 'SC', label: 'Seychelles', phone: '248' },
  { value: 'SL', label: 'Sierra Leone', phone: '232' },
  { value: 'SG', label: 'Singapore', phone: '65' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
  { value: 'SK', label: 'Slovakia', phone: '421' },
  { value: 'SI', label: 'Slovenia', phone: '386' },
  { value: 'SB', label: 'Solomon Islands', phone: '677' },
  { value: 'SO', label: 'Somalia', phone: '252' },
  { value: 'ZA', label: 'South Africa', phone: '27' },
  {
    value: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500'
  },
  { value: 'SS', label: 'South Sudan', phone: '211' },
  { value: 'ES', label: 'Spain', phone: '34' },
  { value: 'LK', label: 'Sri Lanka', phone: '94' },
  { value: 'SD', label: 'Sudan', phone: '249' },
  { value: 'SR', label: 'Suriname', phone: '597' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
  { value: 'SZ', label: 'Swaziland', phone: '268' },
  { value: 'SE', label: 'Sweden', phone: '46' },
  { value: 'CH', label: 'Switzerland', phone: '41' },
  { value: 'SY', label: 'Syrian Arab Republic', phone: '963' },
  { value: 'TW', label: 'Taiwan, Province of China', phone: '886' },
  { value: 'TJ', label: 'Tajikistan', phone: '992' },
  { value: 'TH', label: 'Thailand', phone: '66' },
  { value: 'TL', label: 'Timor-Leste', phone: '670' },
  { value: 'TG', label: 'Togo', phone: '228' },
  { value: 'TK', label: 'Tokelau', phone: '690' },
  { value: 'TO', label: 'Tonga', phone: '676' },
  { value: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
  { value: 'TN', label: 'Tunisia', phone: '216' },
  { value: 'TR', label: 'Turkey', phone: '90' },
  { value: 'TM', label: 'Turkmenistan', phone: '993' },
  { value: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
  { value: 'TV', label: 'Tuvalu', phone: '688' },
  { value: 'VI', label: 'US Virgin Islands', phone: '1-340' },
  { value: 'UG', label: 'Uganda', phone: '256' },
  { value: 'UA', label: 'Ukraine', phone: '380' },
  { value: 'AE', label: 'United Arab Emirates', phone: '971' },
  { value: 'GB', label: 'United Kingdom', phone: '44' },
  { value: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
  { value: 'US', label: 'United States', phone: '1', suggested: true },
  { value: 'UY', label: 'Uruguay', phone: '598' },
  { value: 'UZ', label: 'Uzbekistan', phone: '998' },
  { value: 'VU', label: 'Vanuatu', phone: '678' },
  { value: 'VE', label: 'Venezuela', phone: '58' },
  { value: 'VN', label: 'Vietnam', phone: '84' },
  { value: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { value: 'EH', label: 'Western Sahara', phone: '212' },
  { value: 'YE', label: 'Yemen', phone: '967' },
  { value: 'ZM', label: 'Zambia', phone: '260' },
  { value: 'ZW', label: 'Zimbabwe', phone: '263' }
];

export default countries;


export const countriesDataFromDatabase = [
  {
    name: "Aruba",
    code: "AW",
    alpha3: "ABW",
    numericCode: "533",
    continent: "CB",
    phone: "297",
    currency: [
      {
        code: "AWG",
        name: "Aruban florin",
        symbol: "ƒ"
      }
    ],
    geolocation: {
      latitude: 12.5,
      longitude: -69.96666666
    }
  },
  {
    name: "Afghanistan",
    code: "AF",
    alpha3: "AFG",
    numericCode: "004",
    continent: "AS",
    phone: "93",
    currency: [
      {
        code: "AFN",
        name: "Afghan afghani",
        symbol: "؋"
      }
    ],
    geolocation: {
      latitude: 33,
      longitude: 65
    }
  },
  {
    name: "Angola",
    code: "AO",
    alpha3: "AGO",
    numericCode: "024",
    continent: "AF",
    phone: "244",
    currency: [
      {
        code: "AOA",
        name: "Angolan kwanza",
        symbol: "Kz"
      }
    ],
    geolocation: {
      latitude: -12.5,
      longitude: 18.5
    }
  },
  {
    name: "Anguilla",
    code: "AI",
    alpha3: "AIA",
    numericCode: "660",
    continent: "CB",
    phone: "1264",
    currency: [
      {
        code: "XCD",
        name: "Eastern Caribbean dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 18.25,
      longitude: -63.16666666
    }
  },
  {
    name: "Åland Islands",
    code: "AX",
    alpha3: "ALA",
    numericCode: "248",
    continent: "EU",
    phone: "35818",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 60.116667,
      longitude: 19.9
    }
  },
  {
    name: "Albania",
    code: "AL",
    alpha3: "ALB",
    numericCode: "008",
    continent: "EU",
    phone: "355",
    currency: [
      {
        code: "ALL",
        name: "Albanian lek",
        symbol: "L"
      }
    ],
    geolocation: {
      latitude: 41,
      longitude: 20
    }
  },
  {
    name: "Andorra",
    code: "AD",
    alpha3: "AND",
    numericCode: "020",
    continent: "EU",
    phone: "376",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 42.5,
      longitude: 1.5
    }
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    alpha3: "ARE",
    numericCode: "784",
    continent: "AS",
    phone: "971",
    currency: [
      {
        code: "AED",
        name: "United Arab Emirates dirham",
        symbol: "د.إ"
      }
    ],
    geolocation: {
      latitude: 24,
      longitude: 54
    }
  },
  {
    name: "Argentina",
    code: "AR",
    alpha3: "ARG",
    numericCode: "032",
    continent: "SA",
    phone: "54",
    currency: [
      {
        code: "ARS",
        name: "Argentine peso",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -34,
      longitude: -64
    }
  },
  {
    name: "Armenia",
    code: "AM",
    alpha3: "ARM",
    numericCode: "051",
    continent: "AS",
    phone: "374",
    currency: [
      {
        code: "AMD",
        name: "Armenian dram",
        symbol: "֏"
      }
    ],
    geolocation: {
      latitude: 40,
      longitude: 45
    }
  },
  {
    name: "American Samoa",
    code: "AS",
    alpha3: "ASM",
    numericCode: "016",
    continent: "OC",
    phone: "1684",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -14.33333333,
      longitude: -170
    }
  },
  {
    name: "Antarctica",
    code: "AQ",
    alpha3: "ATA",
    numericCode: "010",
    continent: "AN",
    currency: [],
    geolocation: {
      latitude: -90,
      longitude: 0
    }
  },
  {
    name: "French Southern and Antarctic Lands",
    code: "TF",
    alpha3: "ATF",
    numericCode: "260",
    continent: "AN",
    phone: "262",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: -49.25,
      longitude: 69.167
    }
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    alpha3: "ATG",
    numericCode: "028",
    continent: "CB",
    phone: "1268",
    currency: [
      {
        code: "XCD",
        name: "Eastern Caribbean dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 17.05,
      longitude: -61.8
    }
  },
  {
    name: "Australia",
    code: "AU",
    alpha3: "AUS",
    numericCode: "036",
    continent: "OC",
    phone: "61",
    currency: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -27,
      longitude: 133
    }
  },
  {
    name: "Austria",
    code: "AT",
    alpha3: "AUT",
    numericCode: "040",
    continent: "EU",
    phone: "43",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 47.33333333,
      longitude: 13.33333333
    }
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    alpha3: "AZE",
    numericCode: "031",
    continent: "AS",
    phone: "994",
    currency: [
      {
        code: "AZN",
        name: "Azerbaijani manat",
        symbol: "₼"
      }
    ],
    geolocation: {
      latitude: 40.5,
      longitude: 47.5
    }
  },
  {
    name: "Burundi",
    code: "BI",
    alpha3: "BDI",
    numericCode: "108",
    continent: "AF",
    phone: "257",
    currency: [
      {
        code: "BIF",
        name: "Burundian franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: -3.5,
      longitude: 30
    }
  },
  {
    name: "Belgium",
    code: "BE",
    alpha3: "BEL",
    numericCode: "056",
    continent: "EU",
    phone: "32",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 50.83333333,
      longitude: 4
    }
  },
  {
    name: "Benin",
    code: "BJ",
    alpha3: "BEN",
    numericCode: "204",
    continent: "AF",
    phone: "229",
    currency: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 9.5,
      longitude: 2.25
    }
  },
  {
    name: "Burkina Faso",
    code: "BF",
    alpha3: "BFA",
    numericCode: "854",
    continent: "AF",
    phone: "226",
    currency: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 13,
      longitude: -2
    }
  },
  {
    name: "Bangladesh",
    code: "BD",
    alpha3: "BGD",
    numericCode: "050",
    continent: "AS",
    phone: "880",
    currency: [
      {
        code: "BDT",
        name: "Bangladeshi taka",
        symbol: "৳"
      }
    ],
    geolocation: {
      latitude: 24,
      longitude: 90
    }
  },
  {
    name: "Bulgaria",
    code: "BG",
    alpha3: "BGR",
    numericCode: "100",
    continent: "EU",
    phone: "359",
    currency: [
      {
        code: "BGN",
        name: "Bulgarian lev",
        symbol: "лв"
      }
    ],
    geolocation: {
      latitude: 43,
      longitude: 25
    }
  },
  {
    name: "Bahrain",
    code: "BH",
    alpha3: "BHR",
    numericCode: "048",
    continent: "AS",
    phone: "973",
    currency: [
      {
        code: "BHD",
        name: "Bahraini dinar",
        symbol: ".د.ب"
      }
    ],
    geolocation: {
      latitude: 26,
      longitude: 50.55
    }
  },
  {
    name: "Bahamas",
    code: "BS",
    alpha3: "BHS",
    numericCode: "044",
    continent: "CB",
    phone: "1242",
    currency: [
      {
        code: "BSD",
        name: "Bahamian dollar",
        symbol: "$"
      },
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 24.25,
      longitude: -76
    }
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    alpha3: "BIH",
    numericCode: "070",
    continent: "EU",
    phone: "387",
    currency: [
      {
        code: "BAM",
        name: "Bosnia and Herzegovina convertible mark",
        symbol: "KM"
      }
    ],
    geolocation: {
      latitude: 44,
      longitude: 18
    }
  },
  {
    name: "Saint Barthélemy",
    code: "BL",
    alpha3: "BLM",
    numericCode: "652",
    continent: "CB",
    phone: "590",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 18.5,
      longitude: -63.41666666
    }
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
    alpha3: "SHN",
    numericCode: "654",
    continent: "AF",
    phone: "290",
    currency: [
      {
        code: "GBP",
        name: "Pound sterling",
        symbol: "£"
      },
      {
        code: "SHP",
        name: "Saint Helena pound",
        symbol: "£"
      }
    ],
    geolocation: {
      latitude: -15.95,
      longitude: -5.72
    }
  },
  {
    name: "Belarus",
    code: "BY",
    alpha3: "BLR",
    numericCode: "112",
    continent: "EU",
    phone: "375",
    currency: [
      {
        code: "BYN",
        name: "Belarusian ruble",
        symbol: "Br"
      }
    ],
    geolocation: {
      latitude: 53,
      longitude: 28
    }
  },
  {
    name: "Belize",
    code: "BZ",
    alpha3: "BLZ",
    numericCode: "084",
    continent: "CA",
    phone: "501",
    currency: [
      {
        code: "BZD",
        name: "Belize dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 17.25,
      longitude: -88.75
    }
  },
  {
    name: "Bermuda",
    code: "BM",
    alpha3: "BMU",
    numericCode: "060",
    continent: "NA",
    phone: "1441",
    currency: [
      {
        code: "BMD",
        name: "Bermudian dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 32.33333333,
      longitude: -64.75
    }
  },
  {
    name: "Bolivia",
    code: "BO",
    alpha3: "BOL",
    numericCode: "068",
    continent: "SA",
    phone: "591",
    currency: [
      {
        code: "BOB",
        name: "Bolivian boliviano",
        symbol: "Bs."
      }
    ],
    geolocation: {
      latitude: -17,
      longitude: -65
    }
  },
  {
    name: "Caribbean Netherlands",
    code: "BQ",
    alpha3: "BES",
    numericCode: "535",
    continent: "CB",
    phone: "599",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 12.18,
      longitude: -68.25
    }
  },
  {
    name: "Brazil",
    code: "BR",
    alpha3: "BRA",
    numericCode: "076",
    continent: "SA",
    phone: "55",
    currency: [
      {
        code: "BRL",
        name: "Brazilian real",
        symbol: "R$"
      }
    ],
    geolocation: {
      latitude: -10,
      longitude: -55
    }
  },
  {
    name: "Barbados",
    code: "BB",
    alpha3: "BRB",
    numericCode: "052",
    continent: "CB",
    phone: "1246",
    currency: [
      {
        code: "BBD",
        name: "Barbadian dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 13.16666666,
      longitude: -59.53333333
    }
  },
  {
    name: "Brunei",
    code: "BN",
    alpha3: "BRN",
    numericCode: "096",
    continent: "AS",
    phone: "673",
    currency: [
      {
        code: "BND",
        name: "Brunei dollar",
        symbol: "$"
      },
      {
        code: "SGD",
        name: "Singapore dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 4.5,
      longitude: 114.66666666
    }
  },
  {
    name: "Bhutan",
    code: "BT",
    alpha3: "BTN",
    numericCode: "064",
    continent: "AS",
    phone: "975",
    currency: [
      {
        code: "BTN",
        name: "Bhutanese ngultrum",
        symbol: "Nu."
      },
      {
        code: "INR",
        name: "Indian rupee",
        symbol: "₹"
      }
    ],
    geolocation: {
      latitude: 27.5,
      longitude: 90.5
    }
  },
  {
    name: "Bouvet Island",
    code: "BV",
    alpha3: "BVT",
    numericCode: "074",
    continent: "AN",
    phone: "47",
    currency: [],
    geolocation: {
      latitude: -54.43333333,
      longitude: 3.4
    }
  },
  {
    name: "Botswana",
    code: "BW",
    alpha3: "BWA",
    numericCode: "072",
    continent: "AF",
    phone: "267",
    currency: [
      {
        code: "BWP",
        name: "Botswana pula",
        symbol: "P"
      }
    ],
    geolocation: {
      latitude: -22,
      longitude: 24
    }
  },
  {
    name: "Central African Republic",
    code: "CF",
    alpha3: "CAF",
    numericCode: "140",
    continent: "AF",
    phone: "236",
    currency: [
      {
        code: "XAF",
        name: "Central African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 7,
      longitude: 21
    }
  },
  {
    name: "Canada",
    code: "CA",
    alpha3: "CAN",
    numericCode: "124",
    continent: "NA",
    phone: "1204",
    currency: [
      {
        code: "CAD",
        name: "Canadian dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 60,
      longitude: -95
    }
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    alpha3: "CCK",
    numericCode: "166",
    continent: "OC",
    phone: "61",
    currency: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -12.5,
      longitude: 96.83333333
    }
  },
  {
    name: "Switzerland",
    code: "CH",
    alpha3: "CHE",
    numericCode: "756",
    continent: "EU",
    phone: "41",
    currency: [
      {
        code: "CHF",
        name: "Swiss franc",
        symbol: "Fr."
      }
    ],
    geolocation: {
      latitude: 47,
      longitude: 8
    }
  },
  {
    name: "Chile",
    code: "CL",
    alpha3: "CHL",
    numericCode: "152",
    continent: "SA",
    phone: "56",
    currency: [
      {
        code: "CLP",
        name: "Chilean peso",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -30,
      longitude: -71
    }
  },
  {
    name: "China",
    code: "CN",
    alpha3: "CHN",
    numericCode: "156",
    continent: "AS",
    phone: "86",
    currency: [
      {
        code: "CNY",
        name: "Chinese yuan",
        symbol: "¥"
      }
    ],
    geolocation: {
      latitude: 35,
      longitude: 105
    }
  },
  {
    name: "Ivory Coast",
    code: "CI",
    alpha3: "CIV",
    numericCode: "384",
    continent: "AF",
    phone: "225",
    currency: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 8,
      longitude: -5
    }
  },
  {
    name: "Cameroon",
    code: "CM",
    alpha3: "CMR",
    numericCode: "120",
    continent: "AF",
    phone: "237",
    currency: [
      {
        code: "XAF",
        name: "Central African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 6,
      longitude: 12
    }
  },
  {
    name: "DR Congo",
    code: "CD",
    alpha3: "COD",
    numericCode: "180",
    continent: "AF",
    phone: "243",
    currency: [
      {
        code: "CDF",
        name: "Congolese franc",
        symbol: "FC"
      }
    ],
    geolocation: {
      latitude: 0,
      longitude: 25
    }
  },
  {
    name: "Republic of the Congo",
    code: "CG",
    alpha3: "COG",
    numericCode: "178",
    continent: "AF",
    phone: "242",
    currency: [
      {
        code: "XAF",
        name: "Central African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: -1,
      longitude: 15
    }
  },
  {
    name: "Cook Islands",
    code: "CK",
    alpha3: "COK",
    numericCode: "184",
    continent: "OC",
    phone: "682",
    currency: [
      {
        code: "CKD",
        name: "Cook Islands dollar",
        symbol: "$"
      },
      {
        code: "NZD",
        name: "New Zealand dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -21.23333333,
      longitude: -159.76666666
    }
  },
  {
    name: "Colombia",
    code: "CO",
    alpha3: "COL",
    numericCode: "170",
    continent: "SA",
    phone: "57",
    currency: [
      {
        code: "COP",
        name: "Colombian peso",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 4,
      longitude: -72
    }
  },
  {
    name: "Comoros",
    code: "KM",
    alpha3: "COM",
    numericCode: "174",
    continent: "AF",
    phone: "269",
    currency: [
      {
        code: "KMF",
        name: "Comorian franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: -12.16666666,
      longitude: 44.25
    }
  },
  {
    name: "Cape Verde",
    code: "CV",
    alpha3: "CPV",
    numericCode: "132",
    continent: "AF",
    phone: "238",
    currency: [
      {
        code: "CVE",
        name: "Cape Verdean escudo",
        symbol: "Esc"
      }
    ],
    geolocation: {
      latitude: 16,
      longitude: -24
    }
  },
  {
    name: "Costa Rica",
    code: "CR",
    alpha3: "CRI",
    numericCode: "188",
    continent: "CA",
    phone: "506",
    currency: [
      {
        code: "CRC",
        name: "Costa Rican colón",
        symbol: "₡"
      }
    ],
    geolocation: {
      latitude: 10,
      longitude: -84
    }
  },
  {
    name: "Cuba",
    code: "CU",
    alpha3: "CUB",
    numericCode: "192",
    continent: "CB",
    phone: "53",
    currency: [
      {
        code: "CUC",
        name: "Cuban convertible peso",
        symbol: "$"
      },
      {
        code: "CUP",
        name: "Cuban peso",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 21.5,
      longitude: -80
    }
  },
  {
    name: "Curaçao",
    code: "CW",
    alpha3: "CUW",
    numericCode: "531",
    continent: "CB",
    phone: "599",
    currency: [
      {
        code: "ANG",
        name: "Netherlands Antillean guilder",
        symbol: "ƒ"
      }
    ],
    geolocation: {
      latitude: 12.116667,
      longitude: -68.933333
    }
  },
  {
    name: "Christmas Island",
    code: "CX",
    alpha3: "CXR",
    numericCode: "162",
    continent: "OC",
    phone: "61",
    currency: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -10.5,
      longitude: 105.66666666
    }
  },
  {
    name: "Cayman Islands",
    code: "KY",
    alpha3: "CYM",
    numericCode: "136",
    continent: "CB",
    phone: "1345",
    currency: [
      {
        code: "KYD",
        name: "Cayman Islands dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 19.5,
      longitude: -80.5
    }
  },
  {
    name: "Cyprus",
    code: "CY",
    alpha3: "CYP",
    numericCode: "196",
    continent: "EU",
    phone: "357",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 35,
      longitude: 33
    }
  },
  {
    name: "Czechia",
    code: "CZ",
    alpha3: "CZE",
    numericCode: "203",
    continent: "EU",
    phone: "420",
    currency: [
      {
        code: "CZK",
        name: "Czech koruna",
        symbol: "Kč"
      }
    ],
    geolocation: {
      latitude: 49.75,
      longitude: 15.5
    }
  },
  {
    name: "Germany",
    code: "DE",
    alpha3: "DEU",
    numericCode: "276",
    continent: "EU",
    phone: "49",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 51,
      longitude: 9
    }
  },
  {
    name: "Djibouti",
    code: "DJ",
    alpha3: "DJI",
    numericCode: "262",
    continent: "AF",
    phone: "253",
    currency: [
      {
        code: "DJF",
        name: "Djiboutian franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 11.5,
      longitude: 43
    }
  },
  {
    name: "Dominica",
    code: "DM",
    alpha3: "DMA",
    numericCode: "212",
    continent: "CB",
    phone: "1767",
    currency: [
      {
        code: "XCD",
        name: "Eastern Caribbean dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 15.41666666,
      longitude: -61.33333333
    }
  },
  {
    name: "Denmark",
    code: "DK",
    alpha3: "DNK",
    numericCode: "208",
    continent: "EU",
    phone: "45",
    currency: [
      {
        code: "DKK",
        name: "Danish krone",
        symbol: "kr"
      }
    ],
    geolocation: {
      latitude: 56,
      longitude: 10
    }
  },
  {
    name: "Dominican Republic",
    code: "DO",
    alpha3: "DOM",
    numericCode: "214",
    continent: "CB",
    phone: "1809",
    currency: [
      {
        code: "DOP",
        name: "Dominican peso",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 19,
      longitude: -70.66666666
    }
  },
  {
    name: "Algeria",
    code: "DZ",
    alpha3: "DZA",
    numericCode: "012",
    continent: "AF",
    phone: "213",
    currency: [
      {
        code: "DZD",
        name: "Algerian dinar",
        symbol: "د.ج"
      }
    ],
    geolocation: {
      latitude: 28,
      longitude: 3
    }
  },
  {
    name: "Ecuador",
    code: "EC",
    alpha3: "ECU",
    numericCode: "218",
    continent: "SA",
    phone: "593",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -2,
      longitude: -77.5
    }
  },
  {
    name: "Egypt",
    code: "EG",
    alpha3: "EGY",
    numericCode: "818",
    continent: "AF",
    phone: "20",
    currency: [
      {
        code: "EGP",
        name: "Egyptian pound",
        symbol: "£"
      }
    ],
    geolocation: {
      latitude: 27,
      longitude: 30
    }
  },
  {
    name: "Eritrea",
    code: "ER",
    alpha3: "ERI",
    numericCode: "232",
    continent: "AF",
    phone: "291",
    currency: [
      {
        code: "ERN",
        name: "Eritrean nakfa",
        symbol: "Nfk"
      }
    ],
    geolocation: {
      latitude: 15,
      longitude: 39
    }
  },
  {
    name: "Western Sahara",
    code: "EH",
    alpha3: "ESH",
    numericCode: "732",
    continent: "AF",
    phone: "2125288",
    currency: [
      {
        code: "DZD",
        name: "Algerian dinar",
        symbol: "دج"
      },
      {
        code: "MAD",
        name: "Moroccan dirham",
        symbol: "DH"
      },
      {
        code: "MRU",
        name: "Mauritanian ouguiya",
        symbol: "UM"
      }
    ],
    geolocation: {
      latitude: 24.5,
      longitude: -13
    }
  },
  {
    name: "Spain",
    code: "ES",
    alpha3: "ESP",
    numericCode: "724",
    continent: "EU",
    phone: "34",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 40,
      longitude: -4
    }
  },
  {
    name: "Estonia",
    code: "EE",
    alpha3: "EST",
    numericCode: "233",
    continent: "EU",
    phone: "372",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 59,
      longitude: 26
    }
  },
  {
    name: "Ethiopia",
    code: "ET",
    alpha3: "ETH",
    numericCode: "231",
    continent: "AF",
    phone: "251",
    currency: [
      {
        code: "ETB",
        name: "Ethiopian birr",
        symbol: "Br"
      }
    ],
    geolocation: {
      latitude: 8,
      longitude: 38
    }
  },
  {
    name: "Finland",
    code: "FI",
    alpha3: "FIN",
    numericCode: "246",
    continent: "EU",
    phone: "358",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 64,
      longitude: 26
    }
  },
  {
    name: "Fiji",
    code: "FJ",
    alpha3: "FJI",
    numericCode: "242",
    continent: "OC",
    phone: "679",
    currency: [
      {
        code: "FJD",
        name: "Fijian dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -18,
      longitude: 175
    }
  },
  {
    name: "Falkland Islands",
    code: "FK",
    alpha3: "FLK",
    numericCode: "238",
    continent: "SA",
    phone: "500",
    currency: [
      {
        code: "FKP",
        name: "Falkland Islands pound",
        symbol: "£"
      }
    ],
    geolocation: {
      latitude: -51.75,
      longitude: -59
    }
  },
  {
    name: "France",
    code: "FR",
    alpha3: "FRA",
    numericCode: "250",
    continent: "EU",
    phone: "33",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 46,
      longitude: 2
    }
  },
  {
    name: "Faroe Islands",
    code: "FO",
    alpha3: "FRO",
    numericCode: "234",
    continent: "EU",
    phone: "298",
    currency: [
      {
        code: "DKK",
        name: "Danish krone",
        symbol: "kr"
      },
      {
        code: "FOK",
        name: "Faroese króna",
        symbol: "kr"
      }
    ],
    geolocation: {
      latitude: 62,
      longitude: -7
    }
  },
  {
    name: "Micronesia",
    code: "FM",
    alpha3: "FSM",
    numericCode: "583",
    continent: "OC",
    phone: "691",
    currency: [],
    geolocation: {
      latitude: 6.91666666,
      longitude: 158.25
    }
  },
  {
    name: "Gabon",
    code: "GA",
    alpha3: "GAB",
    numericCode: "266",
    continent: "AF",
    phone: "241",
    currency: [
      {
        code: "XAF",
        name: "Central African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: -1,
      longitude: 11.75
    }
  },
  {
    name: "United Kingdom",
    code: "GB",
    alpha3: "GBR",
    numericCode: "826",
    continent: "EU",
    phone: "44",
    currency: [
      {
        code: "GBP",
        name: "British pound",
        symbol: "£"
      }
    ],
    geolocation: {
      latitude: 54,
      longitude: -2
    }
  },
  {
    name: "Georgia",
    code: "GE",
    alpha3: "GEO",
    numericCode: "268",
    continent: "AS",
    phone: "995",
    currency: [
      {
        code: "GEL",
        name: "lari",
        symbol: "₾"
      }
    ],
    geolocation: {
      latitude: 42,
      longitude: 43.5
    }
  },
  {
    name: "Guernsey",
    code: "GG",
    alpha3: "GGY",
    numericCode: "831",
    continent: "EU",
    phone: "44",
    currency: [
      {
        code: "GBP",
        name: "British pound",
        symbol: "£"
      },
      {
        code: "GGP",
        name: "Guernsey pound",
        symbol: "£"
      }
    ],
    geolocation: {
      latitude: 49.46666666,
      longitude: -2.58333333
    }
  },
  {
    name: "Ghana",
    code: "GH",
    alpha3: "GHA",
    numericCode: "288",
    continent: "AF",
    phone: "233",
    currency: [
      {
        code: "GHS",
        name: "Ghanaian cedi",
        symbol: "₵"
      }
    ],
    geolocation: {
      latitude: 8,
      longitude: -2
    }
  },
  {
    name: "Gibraltar",
    code: "GI",
    alpha3: "GIB",
    numericCode: "292",
    continent: "EU",
    phone: "350",
    currency: [
      {
        code: "GIP",
        name: "Gibraltar pound",
        symbol: "£"
      }
    ],
    geolocation: {
      latitude: 36.13333333,
      longitude: -5.35
    }
  },
  {
    name: "Guinea",
    code: "GN",
    alpha3: "GIN",
    numericCode: "324",
    continent: "AF",
    phone: "224",
    currency: [
      {
        code: "GNF",
        name: "Guinean franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 11,
      longitude: -10
    }
  },
  {
    name: "Guadeloupe",
    code: "GP",
    alpha3: "GLP",
    numericCode: "312",
    continent: "CB",
    phone: "590",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 16.25,
      longitude: -61.583333
    }
  },
  {
    name: "Gambia",
    code: "GM",
    alpha3: "GMB",
    numericCode: "270",
    continent: "AF",
    phone: "220",
    currency: [
      {
        code: "GMD",
        name: "dalasi",
        symbol: "D"
      }
    ],
    geolocation: {
      latitude: 13.46666666,
      longitude: -16.56666666
    }
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    alpha3: "GNB",
    numericCode: "624",
    continent: "AF",
    phone: "245",
    currency: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 12,
      longitude: -15
    }
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    alpha3: "GNQ",
    numericCode: "226",
    continent: "AF",
    phone: "240",
    currency: [
      {
        code: "XAF",
        name: "Central African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 2,
      longitude: 10
    }
  },
  {
    name: "Greece",
    code: "GR",
    alpha3: "GRC",
    numericCode: "300",
    continent: "EU",
    phone: "30",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 39,
      longitude: 22
    }
  },
  {
    name: "Grenada",
    code: "GD",
    alpha3: "GRD",
    numericCode: "308",
    continent: "CB",
    phone: "1473",
    currency: [
      {
        code: "XCD",
        name: "Eastern Caribbean dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 12.11666666,
      longitude: -61.66666666
    }
  },
  {
    name: "Greenland",
    code: "GL",
    alpha3: "GRL",
    numericCode: "304",
    continent: "NA",
    phone: "299",
    currency: [
      {
        code: "DKK",
        name: "krone",
        symbol: "kr."
      }
    ],
    geolocation: {
      latitude: 72,
      longitude: -40
    }
  },
  {
    name: "Guatemala",
    code: "GT",
    alpha3: "GTM",
    numericCode: "320",
    continent: "CA",
    phone: "502",
    currency: [
      {
        code: "GTQ",
        name: "Guatemalan quetzal",
        symbol: "Q"
      }
    ],
    geolocation: {
      latitude: 15.5,
      longitude: -90.25
    }
  },
  {
    name: "French Guiana",
    code: "GF",
    alpha3: "GUF",
    numericCode: "254",
    continent: "SA",
    phone: "594",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 4,
      longitude: -53
    }
  },
  {
    name: "Guam",
    code: "GU",
    alpha3: "GUM",
    numericCode: "316",
    continent: "OC",
    phone: "1671",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 13.46666666,
      longitude: 144.78333333
    }
  },
  {
    name: "Guyana",
    code: "GY",
    alpha3: "GUY",
    numericCode: "328",
    continent: "SA",
    phone: "592",
    currency: [
      {
        code: "GYD",
        name: "Guyanese dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 5,
      longitude: -59
    }
  },
  {
    name: "Hong Kong",
    code: "HK",
    alpha3: "HKG",
    numericCode: "344",
    continent: "AS",
    phone: "852",
    currency: [
      {
        code: "HKD",
        name: "Hong Kong dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 22.267,
      longitude: 114.188
    }
  },
  {
    name: "Heard Island and McDonald Islands",
    code: "HM",
    alpha3: "HMD",
    numericCode: "334",
    continent: "AN",
    currency: [],
    geolocation: {
      latitude: -53.1,
      longitude: 72.51666666
    }
  },
  {
    name: "Honduras",
    code: "HN",
    alpha3: "HND",
    numericCode: "340",
    continent: "CA",
    phone: "504",
    currency: [
      {
        code: "HNL",
        name: "Honduran lempira",
        symbol: "L"
      }
    ],
    geolocation: {
      latitude: 15,
      longitude: -86.5
    }
  },
  {
    name: "Croatia",
    code: "HR",
    alpha3: "HRV",
    numericCode: "191",
    continent: "EU",
    phone: "385",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 45.16666666,
      longitude: 15.5
    }
  },
  {
    name: "Haiti",
    code: "HT",
    alpha3: "HTI",
    numericCode: "332",
    continent: "CB",
    phone: "509",
    currency: [
      {
        code: "HTG",
        name: "Haitian gourde",
        symbol: "G"
      }
    ],
    geolocation: {
      latitude: 19,
      longitude: -72.41666666
    }
  },
  {
    name: "Hungary",
    code: "HU",
    alpha3: "HUN",
    numericCode: "348",
    continent: "EU",
    phone: "36",
    currency: [
      {
        code: "HUF",
        name: "Hungarian forint",
        symbol: "Ft"
      }
    ],
    geolocation: {
      latitude: 47,
      longitude: 20
    }
  },
  {
    name: "Indonesia",
    code: "ID",
    alpha3: "IDN",
    numericCode: "360",
    continent: "AS",
    phone: "62",
    currency: [
      {
        code: "IDR",
        name: "Indonesian rupiah",
        symbol: "Rp"
      }
    ],
    geolocation: {
      latitude: -5,
      longitude: 120
    }
  },
  {
    name: "Isle of Man",
    code: "IM",
    alpha3: "IMN",
    numericCode: "833",
    continent: "EU",
    phone: "44",
    currency: [
      {
        code: "GBP",
        name: "British pound",
        symbol: "£"
      },
      {
        code: "IMP",
        name: "Manx pound",
        symbol: "£"
      }
    ],
    geolocation: {
      latitude: 54.25,
      longitude: -4.5
    }
  },
  {
    name: "India",
    code: "IN",
    alpha3: "IND",
    numericCode: "356",
    continent: "AS",
    phone: "91",
    currency: [
      {
        code: "INR",
        name: "Indian rupee",
        symbol: "₹"
      }
    ],
    geolocation: {
      latitude: 20,
      longitude: 77
    }
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    alpha3: "IOT",
    numericCode: "086",
    continent: "AF",
    phone: "246",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -6,
      longitude: 71.5
    }
  },
  {
    name: "Ireland",
    code: "IE",
    alpha3: "IRL",
    numericCode: "372",
    continent: "EU",
    phone: "353",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 53,
      longitude: -8
    }
  },
  {
    name: "Iran",
    code: "IR",
    alpha3: "IRN",
    numericCode: "364",
    continent: "AS",
    phone: "98",
    currency: [
      {
        code: "IRR",
        name: "Iranian rial",
        symbol: "﷼"
      }
    ],
    geolocation: {
      latitude: 32,
      longitude: 53
    }
  },
  {
    name: "Iraq",
    code: "IQ",
    alpha3: "IRQ",
    numericCode: "368",
    continent: "AS",
    phone: "964",
    currency: [
      {
        code: "IQD",
        name: "Iraqi dinar",
        symbol: "ع.د"
      }
    ],
    geolocation: {
      latitude: 33,
      longitude: 44
    }
  },
  {
    name: "Iceland",
    code: "IS",
    alpha3: "ISL",
    numericCode: "352",
    continent: "EU",
    phone: "354",
    currency: [
      {
        code: "ISK",
        name: "Icelandic króna",
        symbol: "kr"
      }
    ],
    geolocation: {
      latitude: 65,
      longitude: -18
    }
  },
  {
    name: "Israel",
    code: "IL",
    alpha3: "ISR",
    numericCode: "376",
    continent: "AS",
    phone: "972",
    currency: [
      {
        code: "ILS",
        name: "Israeli new shekel",
        symbol: "₪"
      }
    ],
    geolocation: {
      latitude: 31.47,
      longitude: 35.13
    }
  },
  {
    name: "Italy",
    code: "IT",
    alpha3: "ITA",
    numericCode: "380",
    continent: "EU",
    phone: "39",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 42.83333333,
      longitude: 12.83333333
    }
  },
  {
    name: "Jamaica",
    code: "JM",
    alpha3: "JAM",
    numericCode: "388",
    continent: "CB",
    phone: "1876",
    currency: [
      {
        code: "JMD",
        name: "Jamaican dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 18.25,
      longitude: -77.5
    }
  },
  {
    name: "Jersey",
    code: "JE",
    alpha3: "JEY",
    numericCode: "832",
    continent: "EU",
    phone: "44",
    currency: [
      {
        code: "GBP",
        name: "British pound",
        symbol: "£"
      },
      {
        code: "JEP",
        name: "Jersey pound",
        symbol: "£"
      }
    ],
    geolocation: {
      latitude: 49.25,
      longitude: -2.16666666
    }
  },
  {
    name: "Jordan",
    code: "JO",
    alpha3: "JOR",
    numericCode: "400",
    continent: "AS",
    phone: "962",
    currency: [
      {
        code: "JOD",
        name: "Jordanian dinar",
        symbol: "د.ا"
      }
    ],
    geolocation: {
      latitude: 31,
      longitude: 36
    }
  },
  {
    name: "Japan",
    code: "JP",
    alpha3: "JPN",
    numericCode: "392",
    continent: "AS",
    phone: "81",
    currency: [
      {
        code: "JPY",
        name: "Japanese yen",
        symbol: "¥"
      }
    ],
    geolocation: {
      latitude: 36,
      longitude: 138
    }
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    alpha3: "KAZ",
    numericCode: "398",
    continent: "AS",
    phone: "76",
    currency: [
      {
        code: "KZT",
        name: "Kazakhstani tenge",
        symbol: "₸"
      }
    ],
    geolocation: {
      latitude: 48,
      longitude: 68
    }
  },
  {
    name: "Kenya",
    code: "KE",
    alpha3: "KEN",
    numericCode: "404",
    continent: "AF",
    phone: "254",
    currency: [
      {
        code: "KES",
        name: "Kenyan shilling",
        symbol: "Sh"
      }
    ],
    geolocation: {
      latitude: 1,
      longitude: 38
    }
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    alpha3: "KGZ",
    numericCode: "417",
    continent: "AS",
    phone: "996",
    currency: [
      {
        code: "KGS",
        name: "Kyrgyzstani som",
        symbol: "с"
      }
    ],
    geolocation: {
      latitude: 41,
      longitude: 75
    }
  },
  {
    name: "Cambodia",
    code: "KH",
    alpha3: "KHM",
    numericCode: "116",
    continent: "AS",
    phone: "855",
    currency: [
      {
        code: "KHR",
        name: "Cambodian riel",
        symbol: "៛"
      },
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 13,
      longitude: 105
    }
  },
  {
    name: "Kiribati",
    code: "KI",
    alpha3: "KIR",
    numericCode: "296",
    continent: "OC",
    phone: "686",
    currency: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$"
      },
      {
        code: "KID",
        name: "Kiribati dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 1.41666666,
      longitude: 173
    }
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    alpha3: "KNA",
    numericCode: "659",
    continent: "CB",
    phone: "1869",
    currency: [
      {
        code: "XCD",
        name: "Eastern Caribbean dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 17.33333333,
      longitude: -62.75
    }
  },
  {
    name: "South Korea",
    code: "KR",
    alpha3: "KOR",
    numericCode: "410",
    continent: "AS",
    phone: "82",
    currency: [
      {
        code: "KRW",
        name: "South Korean won",
        symbol: "₩"
      }
    ],
    geolocation: {
      latitude: 37,
      longitude: 127.5
    }
  },
  {
    name: "Kosovo",
    code: "XK",
    alpha3: "UNK",
    numericCode: "",
    continent: "EU",
    phone: "383",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 42.666667,
      longitude: 21.166667
    }
  },
  {
    name: "Kuwait",
    code: "KW",
    alpha3: "KWT",
    numericCode: "414",
    continent: "AS",
    phone: "965",
    currency: [
      {
        code: "KWD",
        name: "Kuwaiti dinar",
        symbol: "د.ك"
      }
    ],
    geolocation: {
      latitude: 29.5,
      longitude: 45.75
    }
  },
  {
    name: "Laos",
    code: "LA",
    alpha3: "LAO",
    numericCode: "418",
    continent: "AS",
    phone: "856",
    currency: [
      {
        code: "LAK",
        name: "Lao kip",
        symbol: "₭"
      }
    ],
    geolocation: {
      latitude: 18,
      longitude: 105
    }
  },
  {
    name: "Lebanon",
    code: "LB",
    alpha3: "LBN",
    numericCode: "422",
    continent: "AS",
    phone: "961",
    currency: [
      {
        code: "LBP",
        name: "Lebanese pound",
        symbol: "ل.ل"
      }
    ],
    geolocation: {
      latitude: 33.83333333,
      longitude: 35.83333333
    }
  },
  {
    name: "Liberia",
    code: "LR",
    alpha3: "LBR",
    numericCode: "430",
    continent: "AF",
    phone: "231",
    currency: [
      {
        code: "LRD",
        name: "Liberian dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 6.5,
      longitude: -9.5
    }
  },
  {
    name: "Libya",
    code: "LY",
    alpha3: "LBY",
    numericCode: "434",
    continent: "AF",
    phone: "218",
    currency: [
      {
        code: "LYD",
        name: "Libyan dinar",
        symbol: "ل.د"
      }
    ],
    geolocation: {
      latitude: 25,
      longitude: 17
    }
  },
  {
    name: "Saint Lucia",
    code: "LC",
    alpha3: "LCA",
    numericCode: "662",
    continent: "CB",
    phone: "1758",
    currency: [
      {
        code: "XCD",
        name: "Eastern Caribbean dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 13.88333333,
      longitude: -60.96666666
    }
  },
  {
    name: "Liechtenstein",
    code: "LI",
    alpha3: "LIE",
    numericCode: "438",
    continent: "EU",
    phone: "423",
    currency: [
      {
        code: "CHF",
        name: "Swiss franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 47.26666666,
      longitude: 9.53333333
    }
  },
  {
    name: "Sri Lanka",
    code: "LK",
    alpha3: "LKA",
    numericCode: "144",
    continent: "AS",
    phone: "94",
    currency: [
      {
        code: "LKR",
        name: "Sri Lankan rupee",
        symbol: "Rs  රු"
      }
    ],
    geolocation: {
      latitude: 7,
      longitude: 81
    }
  },
  {
    name: "Lesotho",
    code: "LS",
    alpha3: "LSO",
    numericCode: "426",
    continent: "AF",
    phone: "266",
    currency: [
      {
        code: "LSL",
        name: "Lesotho loti",
        symbol: "L"
      },
      {
        code: "ZAR",
        name: "South African rand",
        symbol: "R"
      }
    ],
    geolocation: {
      latitude: -29.5,
      longitude: 28.5
    }
  },
  {
    name: "Lithuania",
    code: "LT",
    alpha3: "LTU",
    numericCode: "440",
    continent: "EU",
    phone: "370",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 56,
      longitude: 24
    }
  },
  {
    name: "Luxembourg",
    code: "LU",
    alpha3: "LUX",
    numericCode: "442",
    continent: "EU",
    phone: "352",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 49.75,
      longitude: 6.16666666
    }
  },
  {
    name: "Latvia",
    code: "LV",
    alpha3: "LVA",
    numericCode: "428",
    continent: "EU",
    phone: "371",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 57,
      longitude: 25
    }
  },
  {
    name: "Macau",
    code: "MO",
    alpha3: "MAC",
    numericCode: "446",
    continent: "AS",
    phone: "853",
    currency: [
      {
        code: "MOP",
        name: "Macanese pataca",
        symbol: "P"
      }
    ],
    geolocation: {
      latitude: 22.16666666,
      longitude: 113.55
    }
  },
  {
    name: "Saint Martin",
    code: "MF",
    alpha3: "MAF",
    numericCode: "663",
    continent: "CB",
    phone: "590",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 18.08333333,
      longitude: -63.95
    }
  },
  {
    name: "Morocco",
    code: "MA",
    alpha3: "MAR",
    numericCode: "504",
    continent: "AF",
    phone: "212",
    currency: [
      {
        code: "MAD",
        name: "Moroccan dirham",
        symbol: "د.م."
      }
    ],
    geolocation: {
      latitude: 32,
      longitude: -5
    }
  },
  {
    name: "Monaco",
    code: "MC",
    alpha3: "MCO",
    numericCode: "492",
    continent: "EU",
    phone: "377",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 43.73333333,
      longitude: 7.4
    }
  },
  {
    name: "Moldova",
    code: "MD",
    alpha3: "MDA",
    numericCode: "498",
    continent: "EU",
    phone: "373",
    currency: [
      {
        code: "MDL",
        name: "Moldovan leu",
        symbol: "L"
      }
    ],
    geolocation: {
      latitude: 47,
      longitude: 29
    }
  },
  {
    name: "Madagascar",
    code: "MG",
    alpha3: "MDG",
    numericCode: "450",
    continent: "AF",
    phone: "261",
    currency: [
      {
        code: "MGA",
        name: "Malagasy ariary",
        symbol: "Ar"
      }
    ],
    geolocation: {
      latitude: -20,
      longitude: 47
    }
  },
  {
    name: "Maldives",
    code: "MV",
    alpha3: "MDV",
    numericCode: "462",
    continent: "AS",
    phone: "960",
    currency: [
      {
        code: "MVR",
        name: "Maldivian rufiyaa",
        symbol: ".ރ"
      }
    ],
    geolocation: {
      latitude: 3.25,
      longitude: 73
    }
  },
  {
    name: "Mexico",
    code: "MX",
    alpha3: "MEX",
    numericCode: "484",
    continent: "NA",
    phone: "52",
    currency: [
      {
        code: "MXN",
        name: "Mexican peso",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 23,
      longitude: -102
    }
  },
  {
    name: "Marshall Islands",
    code: "MH",
    alpha3: "MHL",
    numericCode: "584",
    continent: "OC",
    phone: "692",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 9,
      longitude: 168
    }
  },
  {
    name: "North Macedonia",
    code: "MK",
    alpha3: "MKD",
    numericCode: "807",
    continent: "EU",
    phone: "389",
    currency: [
      {
        code: "MKD",
        name: "denar",
        symbol: "den"
      }
    ],
    geolocation: {
      latitude: 41.83333333,
      longitude: 22
    }
  },
  {
    name: "Mali",
    code: "ML",
    alpha3: "MLI",
    numericCode: "466",
    continent: "AF",
    phone: "223",
    currency: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 17,
      longitude: -4
    }
  },
  {
    name: "Malta",
    code: "MT",
    alpha3: "MLT",
    numericCode: "470",
    continent: "EU",
    phone: "356",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 35.83333333,
      longitude: 14.58333333
    }
  },
  {
    name: "Myanmar",
    code: "MM",
    alpha3: "MMR",
    numericCode: "104",
    continent: "AS",
    phone: "95",
    currency: [
      {
        code: "MMK",
        name: "Burmese kyat",
        symbol: "Ks"
      }
    ],
    geolocation: {
      latitude: 22,
      longitude: 98
    }
  },
  {
    name: "Montenegro",
    code: "ME",
    alpha3: "MNE",
    numericCode: "499",
    continent: "EU",
    phone: "382",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 42.5,
      longitude: 19.3
    }
  },
  {
    name: "Mongolia",
    code: "MN",
    alpha3: "MNG",
    numericCode: "496",
    continent: "AS",
    phone: "976",
    currency: [
      {
        code: "MNT",
        name: "Mongolian tögrög",
        symbol: "₮"
      }
    ],
    geolocation: {
      latitude: 46,
      longitude: 105
    }
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    alpha3: "MNP",
    numericCode: "580",
    continent: "OC",
    phone: "1670",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 15.2,
      longitude: 145.75
    }
  },
  {
    name: "Mozambique",
    code: "MZ",
    alpha3: "MOZ",
    numericCode: "508",
    continent: "AF",
    phone: "258",
    currency: [
      {
        code: "MZN",
        name: "Mozambican metical",
        symbol: "MT"
      }
    ],
    geolocation: {
      latitude: -18.25,
      longitude: 35
    }
  },
  {
    name: "Mauritania",
    code: "MR",
    alpha3: "MRT",
    numericCode: "478",
    continent: "AF",
    phone: "222",
    currency: [
      {
        code: "MRU",
        name: "Mauritanian ouguiya",
        symbol: "UM"
      }
    ],
    geolocation: {
      latitude: 20,
      longitude: -12
    }
  },
  {
    name: "Montserrat",
    code: "MS",
    alpha3: "MSR",
    numericCode: "500",
    continent: "CB",
    phone: "1664",
    currency: [
      {
        code: "XCD",
        name: "Eastern Caribbean dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 16.75,
      longitude: -62.2
    }
  },
  {
    name: "Martinique",
    code: "MQ",
    alpha3: "MTQ",
    numericCode: "474",
    continent: "CB",
    phone: "596",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 14.666667,
      longitude: -61
    }
  },
  {
    name: "Mauritius",
    code: "MU",
    alpha3: "MUS",
    numericCode: "480",
    continent: "AF",
    phone: "230",
    currency: [
      {
        code: "MUR",
        name: "Mauritian rupee",
        symbol: "₨"
      }
    ],
    geolocation: {
      latitude: -20.28333333,
      longitude: 57.55
    }
  },
  {
    name: "Malawi",
    code: "MW",
    alpha3: "MWI",
    numericCode: "454",
    continent: "AF",
    phone: "265",
    currency: [
      {
        code: "MWK",
        name: "Malawian kwacha",
        symbol: "MK"
      }
    ],
    geolocation: {
      latitude: -13.5,
      longitude: 34
    }
  },
  {
    name: "Malaysia",
    code: "MY",
    alpha3: "MYS",
    numericCode: "458",
    continent: "AS",
    phone: "60",
    currency: [
      {
        code: "MYR",
        name: "Malaysian ringgit",
        symbol: "RM"
      }
    ],
    geolocation: {
      latitude: 2.5,
      longitude: 112.5
    }
  },
  {
    name: "Mayotte",
    code: "YT",
    alpha3: "MYT",
    numericCode: "175",
    continent: "AF",
    phone: "262",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: -12.83333333,
      longitude: 45.16666666
    }
  },
  {
    name: "Namibia",
    code: "NA",
    alpha3: "NAM",
    numericCode: "516",
    continent: "AF",
    phone: "264",
    currency: [
      {
        code: "NAD",
        name: "Namibian dollar",
        symbol: "$"
      },
      {
        code: "ZAR",
        name: "South African rand",
        symbol: "R"
      }
    ],
    geolocation: {
      latitude: -22,
      longitude: 17
    }
  },
  {
    name: "New Caledonia",
    code: "NC",
    alpha3: "NCL",
    numericCode: "540",
    continent: "OC",
    phone: "687",
    currency: [
      {
        code: "XPF",
        name: "CFP franc",
        symbol: "₣"
      }
    ],
    geolocation: {
      latitude: -21.5,
      longitude: 165.5
    }
  },
  {
    name: "Niger",
    code: "NE",
    alpha3: "NER",
    numericCode: "562",
    continent: "AF",
    phone: "227",
    currency: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 16,
      longitude: 8
    }
  },
  {
    name: "Norfolk Island",
    code: "NF",
    alpha3: "NFK",
    numericCode: "574",
    continent: "OC",
    phone: "672",
    currency: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -29.03333333,
      longitude: 167.95
    }
  },
  {
    name: "Nigeria",
    code: "NG",
    alpha3: "NGA",
    numericCode: "566",
    continent: "AF",
    phone: "234",
    currency: [
      {
        code: "NGN",
        name: "Nigerian naira",
        symbol: "₦"
      }
    ],
    geolocation: {
      latitude: 10,
      longitude: 8
    }
  },
  {
    name: "Nicaragua",
    code: "NI",
    alpha3: "NIC",
    numericCode: "558",
    continent: "CA",
    phone: "505",
    currency: [
      {
        code: "NIO",
        name: "Nicaraguan córdoba",
        symbol: "C$"
      }
    ],
    geolocation: {
      latitude: 13,
      longitude: -85
    }
  },
  {
    name: "Niue",
    code: "NU",
    alpha3: "NIU",
    numericCode: "570",
    continent: "OC",
    phone: "683",
    currency: [
      {
        code: "NZD",
        name: "New Zealand dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -19.03333333,
      longitude: -169.86666666
    }
  },
  {
    name: "Netherlands",
    code: "NL",
    alpha3: "NLD",
    numericCode: "528",
    continent: "EU",
    phone: "31",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 52.5,
      longitude: 5.75
    }
  },
  {
    name: "Norway",
    code: "NO",
    alpha3: "NOR",
    numericCode: "578",
    continent: "EU",
    phone: "47",
    currency: [
      {
        code: "NOK",
        name: "Norwegian krone",
        symbol: "kr"
      }
    ],
    geolocation: {
      latitude: 62,
      longitude: 10
    }
  },
  {
    name: "Nepal",
    code: "NP",
    alpha3: "NPL",
    numericCode: "524",
    continent: "AS",
    phone: "977",
    currency: [
      {
        code: "NPR",
        name: "Nepalese rupee",
        symbol: "₨"
      }
    ],
    geolocation: {
      latitude: 28,
      longitude: 84
    }
  },
  {
    name: "Nauru",
    code: "NR",
    alpha3: "NRU",
    numericCode: "520",
    continent: "OC",
    phone: "674",
    currency: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -0.53333333,
      longitude: 166.91666666
    }
  },
  {
    name: "New Zealand",
    code: "NZ",
    alpha3: "NZL",
    numericCode: "554",
    continent: "OC",
    phone: "64",
    currency: [
      {
        code: "NZD",
        name: "New Zealand dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -41,
      longitude: 174
    }
  },
  {
    name: "Oman",
    code: "OM",
    alpha3: "OMN",
    numericCode: "512",
    continent: "AS",
    phone: "968",
    currency: [
      {
        code: "OMR",
        name: "Omani rial",
        symbol: "ر.ع."
      }
    ],
    geolocation: {
      latitude: 21,
      longitude: 57
    }
  },
  {
    name: "Pakistan",
    code: "PK",
    alpha3: "PAK",
    numericCode: "586",
    continent: "AS",
    phone: "92",
    currency: [
      {
        code: "PKR",
        name: "Pakistani rupee",
        symbol: "₨"
      }
    ],
    geolocation: {
      latitude: 30,
      longitude: 70
    }
  },
  {
    name: "Panama",
    code: "PA",
    alpha3: "PAN",
    numericCode: "591",
    continent: "CA",
    phone: "507",
    currency: [
      {
        code: "PAB",
        name: "Panamanian balboa",
        symbol: "B/."
      },
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 9,
      longitude: -80
    }
  },
  {
    name: "Pitcairn Islands",
    code: "PN",
    alpha3: "PCN",
    numericCode: "612",
    continent: "OC",
    phone: "64",
    currency: [
      {
        code: "NZD",
        name: "New Zealand dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -25.06666666,
      longitude: -130.1
    }
  },
  {
    name: "Peru",
    code: "PE",
    alpha3: "PER",
    numericCode: "604",
    continent: "SA",
    phone: "51",
    currency: [
      {
        code: "PEN",
        name: "Peruvian sol",
        symbol: "S/."
      }
    ],
    geolocation: {
      latitude: -10,
      longitude: -76
    }
  },
  {
    name: "Philippines",
    code: "PH",
    alpha3: "PHL",
    numericCode: "608",
    continent: "AS",
    phone: "63",
    currency: [
      {
        code: "PHP",
        name: "Philippine peso",
        symbol: "₱"
      }
    ],
    geolocation: {
      latitude: 13,
      longitude: 122
    }
  },
  {
    name: "Palau",
    code: "PW",
    alpha3: "PLW",
    numericCode: "585",
    continent: "OC",
    phone: "680",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 7.5,
      longitude: 134.5
    }
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    alpha3: "PNG",
    numericCode: "598",
    continent: "OC",
    phone: "675",
    currency: [
      {
        code: "PGK",
        name: "Papua New Guinean kina",
        symbol: "K"
      }
    ],
    geolocation: {
      latitude: -6,
      longitude: 147
    }
  },
  {
    name: "Poland",
    code: "PL",
    alpha3: "POL",
    numericCode: "616",
    continent: "EU",
    phone: "48",
    currency: [
      {
        code: "PLN",
        name: "Polish złoty",
        symbol: "zł"
      }
    ],
    geolocation: {
      latitude: 52,
      longitude: 20
    }
  },
  {
    name: "Puerto Rico",
    code: "PR",
    alpha3: "PRI",
    numericCode: "630",
    continent: "CB",
    phone: "1787",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 18.25,
      longitude: -66.5
    }
  },
  {
    name: "North Korea",
    code: "KP",
    alpha3: "PRK",
    numericCode: "408",
    continent: "AS",
    phone: "850",
    currency: [
      {
        code: "KPW",
        name: "North Korean won",
        symbol: "₩"
      }
    ],
    geolocation: {
      latitude: 40,
      longitude: 127
    }
  },
  {
    name: "Portugal",
    code: "PT",
    alpha3: "PRT",
    numericCode: "620",
    continent: "EU",
    phone: "351",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 39.5,
      longitude: -8
    }
  },
  {
    name: "Paraguay",
    code: "PY",
    alpha3: "PRY",
    numericCode: "600",
    continent: "SA",
    phone: "595",
    currency: [
      {
        code: "PYG",
        name: "Paraguayan guaraní",
        symbol: "₲"
      }
    ],
    geolocation: {
      latitude: -23,
      longitude: -58
    }
  },
  {
    name: "Palestine",
    code: "PS",
    alpha3: "PSE",
    numericCode: "275",
    continent: "AS",
    phone: "970",
    currency: [
      {
        code: "EGP",
        name: "Egyptian pound",
        symbol: "E£"
      },
      {
        code: "ILS",
        name: "Israeli new shekel",
        symbol: "₪"
      },
      {
        code: "JOD",
        name: "Jordanian dinar",
        symbol: "JD"
      }
    ],
    geolocation: {
      latitude: 31.9,
      longitude: 35.2
    }
  },
  {
    name: "French Polynesia",
    code: "PF",
    alpha3: "PYF",
    numericCode: "258",
    continent: "OC",
    phone: "689",
    currency: [
      {
        code: "XPF",
        name: "CFP franc",
        symbol: "₣"
      }
    ],
    geolocation: {
      latitude: -15,
      longitude: -140
    }
  },
  {
    name: "Qatar",
    code: "QA",
    alpha3: "QAT",
    numericCode: "634",
    continent: "AS",
    phone: "974",
    currency: [
      {
        code: "QAR",
        name: "Qatari riyal",
        symbol: "ر.ق"
      }
    ],
    geolocation: {
      latitude: 25.5,
      longitude: 51.25
    }
  },
  {
    name: "Réunion",
    code: "RE",
    alpha3: "REU",
    numericCode: "638",
    continent: "AF",
    phone: "262",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: -21.15,
      longitude: 55.5
    }
  },
  {
    name: "Romania",
    code: "RO",
    alpha3: "ROU",
    numericCode: "642",
    continent: "EU",
    phone: "40",
    currency: [
      {
        code: "RON",
        name: "Romanian leu",
        symbol: "lei"
      }
    ],
    geolocation: {
      latitude: 46,
      longitude: 25
    }
  },
  {
    name: "Russia",
    code: "RU",
    alpha3: "RUS",
    numericCode: "643",
    continent: "EU",
    phone: "73",
    currency: [
      {
        code: "RUB",
        name: "Russian ruble",
        symbol: "₽"
      }
    ],
    geolocation: {
      latitude: 60,
      longitude: 100
    }
  },
  {
    name: "Rwanda",
    code: "RW",
    alpha3: "RWA",
    numericCode: "646",
    continent: "AF",
    phone: "250",
    currency: [
      {
        code: "RWF",
        name: "Rwandan franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: -2,
      longitude: 30
    }
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    alpha3: "SAU",
    numericCode: "682",
    continent: "AS",
    phone: "966",
    currency: [
      {
        code: "SAR",
        name: "Saudi riyal",
        symbol: "ر.س"
      }
    ],
    geolocation: {
      latitude: 25,
      longitude: 45
    }
  },
  {
    name: "Sudan",
    code: "SD",
    alpha3: "SDN",
    numericCode: "729",
    continent: "AF",
    phone: "249",
    currency: [
      {
        code: "SDG",
        name: "Sudanese pound",
        symbol: "PT"
      }
    ],
    geolocation: {
      latitude: 15,
      longitude: 30
    }
  },
  {
    name: "Senegal",
    code: "SN",
    alpha3: "SEN",
    numericCode: "686",
    continent: "AF",
    phone: "221",
    currency: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 14,
      longitude: -14
    }
  },
  {
    name: "Singapore",
    code: "SG",
    alpha3: "SGP",
    numericCode: "702",
    continent: "AS",
    phone: "65",
    currency: [
      {
        code: "SGD",
        name: "Singapore dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 1.36666666,
      longitude: 103.8
    }
  },
  {
    name: "South Georgia",
    code: "GS",
    alpha3: "SGS",
    numericCode: "239",
    continent: "AN",
    phone: "500",
    currency: [
      {
        code: "SHP",
        name: "Saint Helena pound",
        symbol: "£"
      }
    ],
    geolocation: {
      latitude: -54.5,
      longitude: -37
    }
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    alpha3: "SJM",
    numericCode: "744",
    continent: "EU",
    phone: "4779",
    currency: [
      {
        code: "NOK",
        name: "krone",
        symbol: "kr"
      }
    ],
    geolocation: {
      latitude: 78,
      longitude: 20
    }
  },
  {
    name: "Solomon Islands",
    code: "SB",
    alpha3: "SLB",
    numericCode: "090",
    continent: "OC",
    phone: "677",
    currency: [
      {
        code: "SBD",
        name: "Solomon Islands dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -8,
      longitude: 159
    }
  },
  {
    name: "Sierra Leone",
    code: "SL",
    alpha3: "SLE",
    numericCode: "694",
    continent: "AF",
    phone: "232",
    currency: [
      {
        code: "SLL",
        name: "Sierra Leonean leone",
        symbol: "Le"
      }
    ],
    geolocation: {
      latitude: 8.5,
      longitude: -11.5
    }
  },
  {
    name: "El Salvador",
    code: "SV",
    alpha3: "SLV",
    numericCode: "222",
    continent: "CA",
    phone: "503",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 13.83333333,
      longitude: -88.91666666
    }
  },
  {
    name: "San Marino",
    code: "SM",
    alpha3: "SMR",
    numericCode: "674",
    continent: "EU",
    phone: "378",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 43.76666666,
      longitude: 12.41666666
    }
  },
  {
    name: "Somalia",
    code: "SO",
    alpha3: "SOM",
    numericCode: "706",
    continent: "AF",
    phone: "252",
    currency: [
      {
        code: "SOS",
        name: "Somali shilling",
        symbol: "Sh"
      }
    ],
    geolocation: {
      latitude: 10,
      longitude: 49
    }
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    alpha3: "SPM",
    numericCode: "666",
    continent: "NA",
    phone: "508",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 46.83333333,
      longitude: -56.33333333
    }
  },
  {
    name: "Serbia",
    code: "RS",
    alpha3: "SRB",
    numericCode: "688",
    continent: "EU",
    phone: "381",
    currency: [
      {
        code: "RSD",
        name: "Serbian dinar",
        symbol: "дин."
      }
    ],
    geolocation: {
      latitude: 44,
      longitude: 21
    }
  },
  {
    name: "South Sudan",
    code: "SS",
    alpha3: "SSD",
    numericCode: "728",
    continent: "AF",
    phone: "211",
    currency: [
      {
        code: "SSP",
        name: "South Sudanese pound",
        symbol: "£"
      }
    ],
    geolocation: {
      latitude: 7,
      longitude: 30
    }
  },
  {
    name: "São Tomé and Príncipe",
    code: "ST",
    alpha3: "STP",
    numericCode: "678",
    continent: "AF",
    phone: "239",
    currency: [
      {
        code: "STN",
        name: "São Tomé and Príncipe dobra",
        symbol: "Db"
      }
    ],
    geolocation: {
      latitude: 1,
      longitude: 7
    }
  },
  {
    name: "Suriname",
    code: "SR",
    alpha3: "SUR",
    numericCode: "740",
    continent: "SA",
    phone: "597",
    currency: [
      {
        code: "SRD",
        name: "Surinamese dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 4,
      longitude: -56
    }
  },
  {
    name: "Slovakia",
    code: "SK",
    alpha3: "SVK",
    numericCode: "703",
    continent: "EU",
    phone: "421",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 48.66666666,
      longitude: 19.5
    }
  },
  {
    name: "Slovenia",
    code: "SI",
    alpha3: "SVN",
    numericCode: "705",
    continent: "EU",
    phone: "386",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 46.11666666,
      longitude: 14.81666666
    }
  },
  {
    name: "Sweden",
    code: "SE",
    alpha3: "SWE",
    numericCode: "752",
    continent: "EU",
    phone: "46",
    currency: [
      {
        code: "SEK",
        name: "Swedish krona",
        symbol: "kr"
      }
    ],
    geolocation: {
      latitude: 62,
      longitude: 15
    }
  },
  {
    name: "Eswatini",
    code: "SZ",
    alpha3: "SWZ",
    numericCode: "748",
    continent: "AF",
    phone: "268",
    currency: [
      {
        code: "SZL",
        name: "Swazi lilangeni",
        symbol: "L"
      },
      {
        code: "ZAR",
        name: "South African rand",
        symbol: "R"
      }
    ],
    geolocation: {
      latitude: -26.5,
      longitude: 31.5
    }
  },
  {
    name: "Sint Maarten",
    code: "SX",
    alpha3: "SXM",
    numericCode: "534",
    continent: "CB",
    phone: "1721",
    currency: [
      {
        code: "ANG",
        name: "Netherlands Antillean guilder",
        symbol: "ƒ"
      }
    ],
    geolocation: {
      latitude: 18.033333,
      longitude: -63.05
    }
  },
  {
    name: "Seychelles",
    code: "SC",
    alpha3: "SYC",
    numericCode: "690",
    continent: "AF",
    phone: "248",
    currency: [
      {
        code: "SCR",
        name: "Seychellois rupee",
        symbol: "₨"
      }
    ],
    geolocation: {
      latitude: -4.58333333,
      longitude: 55.66666666
    }
  },
  {
    name: "Syria",
    code: "SY",
    alpha3: "SYR",
    numericCode: "760",
    continent: "AS",
    phone: "963",
    currency: [
      {
        code: "SYP",
        name: "Syrian pound",
        symbol: "£"
      }
    ],
    geolocation: {
      latitude: 35,
      longitude: 38
    }
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    alpha3: "TCA",
    numericCode: "796",
    continent: "CB",
    phone: "1649",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 21.75,
      longitude: -71.58333333
    }
  },
  {
    name: "Chad",
    code: "TD",
    alpha3: "TCD",
    numericCode: "148",
    continent: "AF",
    phone: "235",
    currency: [
      {
        code: "XAF",
        name: "Central African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 15,
      longitude: 19
    }
  },
  {
    name: "Togo",
    code: "TG",
    alpha3: "TGO",
    numericCode: "768",
    continent: "AF",
    phone: "228",
    currency: [
      {
        code: "XOF",
        name: "West African CFA franc",
        symbol: "Fr"
      }
    ],
    geolocation: {
      latitude: 8,
      longitude: 1.16666666
    }
  },
  {
    name: "Thailand",
    code: "TH",
    alpha3: "THA",
    numericCode: "764",
    continent: "AS",
    phone: "66",
    currency: [
      {
        code: "THB",
        name: "Thai baht",
        symbol: "฿"
      }
    ],
    geolocation: {
      latitude: 15,
      longitude: 100
    }
  },
  {
    name: "Tajikistan",
    code: "TJ",
    alpha3: "TJK",
    numericCode: "762",
    continent: "AS",
    phone: "992",
    currency: [
      {
        code: "TJS",
        name: "Tajikistani somoni",
        symbol: "ЅМ"
      }
    ],
    geolocation: {
      latitude: 39,
      longitude: 71
    }
  },
  {
    name: "Tokelau",
    code: "TK",
    alpha3: "TKL",
    numericCode: "772",
    continent: "OC",
    phone: "690",
    currency: [
      {
        code: "NZD",
        name: "New Zealand dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -9,
      longitude: -172
    }
  },
  {
    name: "Turkmenistan",
    code: "TM",
    alpha3: "TKM",
    numericCode: "795",
    continent: "AS",
    phone: "993",
    currency: [
      {
        code: "TMT",
        name: "Turkmenistan manat",
        symbol: "m"
      }
    ],
    geolocation: {
      latitude: 40,
      longitude: 60
    }
  },
  {
    name: "Timor-Leste",
    code: "TL",
    alpha3: "TLS",
    numericCode: "626",
    continent: "AS",
    phone: "670",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -8.83333333,
      longitude: 125.91666666
    }
  },
  {
    name: "Tonga",
    code: "TO",
    alpha3: "TON",
    numericCode: "776",
    continent: "OC",
    phone: "676",
    currency: [
      {
        code: "TOP",
        name: "Tongan paʻanga",
        symbol: "T$"
      }
    ],
    geolocation: {
      latitude: -20,
      longitude: -175
    }
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    alpha3: "TTO",
    numericCode: "780",
    continent: "CB",
    phone: "1868",
    currency: [
      {
        code: "TTD",
        name: "Trinidad and Tobago dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 11,
      longitude: -61
    }
  },
  {
    name: "Tunisia",
    code: "TN",
    alpha3: "TUN",
    numericCode: "788",
    continent: "AF",
    phone: "216",
    currency: [
      {
        code: "TND",
        name: "Tunisian dinar",
        symbol: "د.ت"
      }
    ],
    geolocation: {
      latitude: 34,
      longitude: 9
    }
  },
  {
    name: "Turkey",
    code: "TR",
    alpha3: "TUR",
    numericCode: "792",
    continent: "AS",
    phone: "90",
    currency: [
      {
        code: "TRY",
        name: "Turkish lira",
        symbol: "₺"
      }
    ],
    geolocation: {
      latitude: 39,
      longitude: 35
    }
  },
  {
    name: "Tuvalu",
    code: "TV",
    alpha3: "TUV",
    numericCode: "798",
    continent: "OC",
    phone: "688",
    currency: [
      {
        code: "AUD",
        name: "Australian dollar",
        symbol: "$"
      },
      {
        code: "TVD",
        name: "Tuvaluan dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -8,
      longitude: 178
    }
  },
  {
    name: "Taiwan",
    code: "TW",
    alpha3: "TWN",
    numericCode: "158",
    continent: "AS",
    phone: "886",
    currency: [
      {
        code: "TWD",
        name: "New Taiwan dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 23.5,
      longitude: 121
    }
  },
  {
    name: "Tanzania",
    code: "TZ",
    alpha3: "TZA",
    numericCode: "834",
    continent: "AF",
    phone: "255",
    currency: [
      {
        code: "TZS",
        name: "Tanzanian shilling",
        symbol: "Sh"
      }
    ],
    geolocation: {
      latitude: -6,
      longitude: 35
    }
  },
  {
    name: "Uganda",
    code: "UG",
    alpha3: "UGA",
    numericCode: "800",
    continent: "AF",
    phone: "256",
    currency: [
      {
        code: "UGX",
        name: "Ugandan shilling",
        symbol: "Sh"
      }
    ],
    geolocation: {
      latitude: 1,
      longitude: 32
    }
  },
  {
    name: "Ukraine",
    code: "UA",
    alpha3: "UKR",
    numericCode: "804",
    continent: "EU",
    phone: "380",
    currency: [
      {
        code: "UAH",
        name: "Ukrainian hryvnia",
        symbol: "₴"
      }
    ],
    geolocation: {
      latitude: 49,
      longitude: 32
    }
  },
  {
    name: "United States Minor Outlying Islands",
    code: "UM",
    alpha3: "UMI",
    numericCode: "581",
    continent: "NA",
    phone: "268",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 19.3,
      longitude: 166.633333
    }
  },
  {
    name: "Uruguay",
    code: "UY",
    alpha3: "URY",
    numericCode: "858",
    continent: "SA",
    phone: "598",
    currency: [
      {
        code: "UYU",
        name: "Uruguayan peso",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -33,
      longitude: -56
    }
  },
  {
    name: "United States",
    code: "US",
    alpha3: "USA",
    numericCode: "840",
    continent: "NA",
    phone: "1201",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 38,
      longitude: -97
    }
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    alpha3: "UZB",
    numericCode: "860",
    continent: "AS",
    phone: "998",
    currency: [
      {
        code: "UZS",
        name: "Uzbekistani soʻm",
        symbol: "so'm"
      }
    ],
    geolocation: {
      latitude: 41,
      longitude: 64
    }
  },
  {
    name: "Vatican City",
    code: "VA",
    alpha3: "VAT",
    numericCode: "336",
    continent: "EU",
    phone: "3906698",
    currency: [
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      }
    ],
    geolocation: {
      latitude: 41.9,
      longitude: 12.45
    }
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    alpha3: "VCT",
    numericCode: "670",
    continent: "CB",
    phone: "1784",
    currency: [
      {
        code: "XCD",
        name: "Eastern Caribbean dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 13.25,
      longitude: -61.2
    }
  },
  {
    name: "Venezuela",
    code: "VE",
    alpha3: "VEN",
    numericCode: "862",
    continent: "SA",
    phone: "58",
    currency: [
      {
        code: "VES",
        name: "Venezuelan bolívar soberano",
        symbol: "Bs.S."
      }
    ],
    geolocation: {
      latitude: 8,
      longitude: -66
    }
  },
  {
    name: "British Virgin Islands",
    code: "VG",
    alpha3: "VGB",
    numericCode: "092",
    continent: "CB",
    phone: "1284",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 18.431383,
      longitude: -64.62305
    }
  },
  {
    name: "United States Virgin Islands",
    code: "VI",
    alpha3: "VIR",
    numericCode: "850",
    continent: "CB",
    phone: "1340",
    currency: [
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: 18.35,
      longitude: -64.933333
    }
  },
  {
    name: "Vietnam",
    code: "VN",
    alpha3: "VNM",
    numericCode: "704",
    continent: "AS",
    phone: "84",
    currency: [
      {
        code: "VND",
        name: "Vietnamese đồng",
        symbol: "₫"
      }
    ],
    geolocation: {
      latitude: 16.16666666,
      longitude: 107.83333333
    }
  },
  {
    name: "Vanuatu",
    code: "VU",
    alpha3: "VUT",
    numericCode: "548",
    continent: "OC",
    phone: "678",
    currency: [
      {
        code: "VUV",
        name: "Vanuatu vatu",
        symbol: "Vt"
      }
    ],
    geolocation: {
      latitude: -16,
      longitude: 167
    }
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    alpha3: "WLF",
    numericCode: "876",
    continent: "OC",
    phone: "681",
    currency: [
      {
        code: "XPF",
        name: "CFP franc",
        symbol: "₣"
      }
    ],
    geolocation: {
      latitude: -13.3,
      longitude: -176.2
    }
  },
  {
    name: "Samoa",
    code: "WS",
    alpha3: "WSM",
    numericCode: "882",
    continent: "OC",
    phone: "685",
    currency: [
      {
        code: "WST",
        name: "Samoan tālā",
        symbol: "T"
      }
    ],
    geolocation: {
      latitude: -13.58333333,
      longitude: -172.33333333
    }
  },
  {
    name: "Yemen",
    code: "YE",
    alpha3: "YEM",
    numericCode: "887",
    continent: "AS",
    phone: "967",
    currency: [
      {
        code: "YER",
        name: "Yemeni rial",
        symbol: "﷼"
      }
    ],
    geolocation: {
      latitude: 15,
      longitude: 48
    }
  },
  {
    name: "South Africa",
    code: "ZA",
    alpha3: "ZAF",
    numericCode: "710",
    continent: "AF",
    phone: "27",
    currency: [
      {
        code: "ZAR",
        name: "South African rand",
        symbol: "R"
      }
    ],
    geolocation: {
      latitude: -29,
      longitude: 24
    }
  },
  {
    name: "Zambia",
    code: "ZM",
    alpha3: "ZMB",
    numericCode: "894",
    continent: "AF",
    phone: "260",
    currency: [
      {
        code: "ZMW",
        name: "Zambian kwacha",
        symbol: "ZK"
      }
    ],
    geolocation: {
      latitude: -15,
      longitude: 30
    }
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    alpha3: "ZWE",
    numericCode: "716",
    continent: "AF",
    phone: "263",
    currency: [
      {
        code: "BWP",
        name: "Botswana pula",
        symbol: "P"
      },
      {
        code: "CNY",
        name: "Chinese yuan",
        symbol: "¥"
      },
      {
        code: "EUR",
        name: "Euro",
        symbol: "€"
      },
      {
        code: "GBP",
        name: "British pound",
        symbol: "£"
      },
      {
        code: "INR",
        name: "Indian rupee",
        symbol: "₹"
      },
      {
        code: "JPY",
        name: "Japanese yen",
        symbol: "¥"
      },
      {
        code: "USD",
        name: "United States dollar",
        symbol: "$"
      },
      {
        code: "ZAR",
        name: "South African rand",
        symbol: "Rs"
      },
      {
        code: "ZWB",
        name: "Zimbabwean bonds",
        symbol: "$"
      }
    ],
    geolocation: {
      latitude: -20,
      longitude: 30
    }
  }
]


export const getCountryByValue = (value) => {
  return countries.find(
    (country) => country.value === String(value).toUpperCase
  );
};
