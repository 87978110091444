import React from 'react'
import Style from "./ShippingLineCard.module.css"
const ShippingLineCard = ({
    logo,
    name
}) => {
    return (
        <>
            <div className={Style.main}>
                <img src={logo} alt={name} />
                <p>{name}</p>
            </div>
        </>
    )
}

export default ShippingLineCard