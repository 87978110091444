import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';

export default class RatingService {
  static async fetchForwarderRatings(id, token) {
    const url = `${getBaseUrl()}admin/fetch-forwarder-ratings/${id}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }

}
