import {
    SET_CHAT_ROOM,
    SET_CHAT_DATA
} from '../actions';

const initialState = {
    room_id: null,
    chat_data:[]
};

export default function chatRoom(state = initialState, action) {
    switch (action.type) {
        case SET_CHAT_ROOM:
            return {
                ...state,
                room_id: action.payload
            };
        case SET_CHAT_DATA:
            return {
                ...state,
                chat_data: action.payload
            };
        default:
            return state;
    }
}