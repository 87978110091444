import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { useHistory } from "react-router-dom";

const styles = () => ({});

const HeaderTitle = ({ classes }) => {
  const history = useHistory();

  return (
    <>
      <div className="app-page-title--heading" onClick={() => history.push("/")} style={{ cursor: 'pointer' }}>
        <h1>Support</h1>
      </div>
    </>
  );
};

export default withStyles(styles)(HeaderTitle);
