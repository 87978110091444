import React, { useEffect, useState } from 'react';
import Style from "./AddMemberForm.module.css";
import OutlineFieldSimple from 'components/Common/OutlineFieldSimple/OutlineFieldSimple';
import { Button, MenuItem, Select } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import AdminTeam from 'service/AdminTeam';
import { notify } from 'sites/shipper/components/Toast/toast';
import StyledSelect from 'components/StyledSelect';
import StyledInput from 'components/StyledInput';
import ModalSimple from 'components/ModalSimple';
import { userRoleArray } from 'util/adminPermissionRoles';
import { allowedUerRoleArray } from 'util/adminPermissionRoles';



const AddMemberForm = ({ onClose, open }) => {

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.session.auth)
  const [error, setError] = useState()
  const [data, setData] = useState({
    email: ""
  })

  const [teamRoles, setTeamRoles] = useState([]);

  // console.log(auth)
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData(previousData => ({
      ...previousData,
      [name]: value
    }))
  }


  const [roleType, setRoleType] = useState("");

  const handleRoleType = (e) => {
    setRoleType(e.target.value);
  };


  // useEffect(() => {
  //   AdminTeam.fetchTeamRoles(auth.token).then((result) => {
  //     if (result.error) {
  //       setError(result.error);
  //     } else {
  //       setError('');
  //       setTeamRoles(result?.data);
  //     }
  //   });
  // }, []);



  const handleSubmit = () => {
    if (data.email === auth.email) {
      return setError("You can not invite yourself as team member")
    }
    if (data.email.trim() === "") {
      return setError("Enter valid email id")
    }
    const data_to_send = {
      admin_id: auth.accountId,
      email: data.email,
      role_type: roleType,
      cmpyId: auth.cmpyId
    };
    AdminTeam.inviteTeam(data_to_send, auth.token).then(result => {
      if (result.error) {
        setError(result.error)
      } else {
        setError("")
        // window.alert(result.message)
        notify(dispatch, "success", result.message)
        onClose()
      }
    })
  }

  return (
    <>
      <ModalSimple
        modalTitle={'Add A member to your team'}
        open={open}
        action={handleSubmit}
        modalDescription={
          'Enter email id of the person whom you want to add to the platform'
        }
        maxWidth={'xl'}
        handleClose={onClose}
        actionButtonText={'Invite'}>
        <div className={Style.main}>
          <div className={Style.form_container}>
            <StyledInput
              type="email"
              label="Email*"
              id="email"
              placeholder="example@gmail.com"
              name="email"
              value={data.email}
              onChange={handleChange}
            />

            <StyledSelect
              label="Role Type*"
              name="roleType"
              margin="0"
              value={roleType}
              onChange={handleRoleType}
              size="small"
              options={allowedUerRoleArray(auth.accountCmpyLinkType)}
            />

            {error && (
              <p style={{ fontSize: '12px', color: 'red' }}>{error}</p>
            )}
          </div>
        </div>
      </ModalSimple>
    </>
  );
}

export default AddMemberForm