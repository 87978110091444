import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card } from '@material-ui/core';

const IconCardLink = ({ icon, title, description, label, link }) => {
  return (
    <Card>
      <div className="p-4">
        <Grid container spacing={0}>
          <Grid item md={3}>
            <img
              alt={`${title} Icon`}
              className="img-fluid"
              style={{ minHeight: '100px', maxHeight: '150px' }}
              src={icon}
            />
          </Grid>
          <Grid item md={9} className="d-flex align-items-center">
            <div>
              <div className="font-size-lg font-weight-bold mb-1">{title}</div>
              <p className="opacity-7 font-size-md mb-0">{description}</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="divider" />
      <a
        href={link}
        className="px-4 py-3 text-first btn btn-white shadow-none d-flex justify-content-between align-items-center">
        <div>{label}</div>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} />
      </a>
    </Card>
  );
};

IconCardLink.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default IconCardLink;
