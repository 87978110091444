import { getBaseUrl } from 'config/config';
// import { HttpMethods, HttpService } from './index';
import { httpService,HttpMethods } from './global';

export default class NotificationsService {
  static async fetchNotificationData(id, token) {
   
    const url = `admin/fetch-notification-data/${id}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'notifications_settings',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  
  }

  static async manageNotifications(data, token) {
    const url = `admin/manage-notification`;

     return httpService(HttpMethods.PUT, url, data, {
       token,
       type: 'notifications_settings',
       method: 'edit',
       platform: 'Admin'
     })
       .then((res) => {
         return res;
       })
       .catch((err) => console.log(err));
  
  }

  static async manageTurnOnNotificationsAndSubscription(data, token) {
    const url = `admin/manage-all-notifications-subscriptions`;

         return httpService(HttpMethods.PUT, url, data, {
           token,
           type: 'notifications_settings',
           method: 'edit',
           platform: 'Admin'
         })
           .then((res) => {
             return res;
           })
           .catch((err) => console.log(err));
  
  }

  static async manageNotificationMethod(data, token) {
    const url = `admin/update-notification-method`;

      return httpService(HttpMethods.PUT, url, data, {
        token,
        type: 'notifications_settings',
        method: 'edit',
        platform: 'Admin'
      })
        .then((res) => {
          return res;
        })
        .catch((err) => console.log(err));
   
  }
}
