import React from 'react'
import { OutlinedInput } from '@material-ui/core'
import Style from "./OutlineFieldSimple.module.css";

const OutlineFieldSimple = ({
    id = '',
    name = '',
    onChange,
    value = '',
    defaultValue,
    label = '',
    placeholder = '',
    type = 'text',
    isMultiline = false,
    maxRows = 2,
    rows = 1,
    params = [],
    startAdornment,
    endAdornment,
    labelAddon,
    classes
}) => {
    return (
        <div className={`${Style.container} ${classes}`}>
            {label !== '' &&
                <>
                    <label htmlFor={id} className={Style.label}>{label} &nbsp; {labelAddon}</label>
                </>
            }
            <OutlinedInput
                type={type}
                name={name}
                id={id}
                placeholder={placeholder}
                className={`${Style.input}`}
                onChange={(e) => onChange(e, ...params)}
                value={value}
                defaultValue={defaultValue}
                multiline={isMultiline}
                rowsMax={maxRows}
                rows={rows}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
            />
        </div>
    )
}

export default OutlineFieldSimple