// const AccountCmpyLinkType = Object.freeze({
//   SUSPENDED: 'SUSPENDED',
//   ADMIN: 'ADMIN',
//   MEMBER: 'MEMBER',
//   POWER: 'POWER'
// });

const AccountCmpyLinkType = Object.freeze({
  ROOT: 'ROOT',
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  POWER: 'POWER',
  MEMBER: 'MEMBER'
});




export default AccountCmpyLinkType;
