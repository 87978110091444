import { useEffect, useState } from 'react';
import Style from './filter.module.css';
import DropdownOptions from './DropdownOptions';
import { Button, Checkbox, TextField, Tooltip } from '@material-ui/core';
import ShippingRouteBox from './ShippingRouteBox/ShippingRouteBox';
import { useDispatch, useSelector } from 'react-redux';
import StyledInput from 'components/StyledInput';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { setFilterDataInRedux } from 'actions';
import { Autocomplete } from '@material-ui/lab';
import { ForwarderService } from 'service';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { TRANSPORT_MODES_LIST, fetchCargoCategories } from 'util/constants';
/* eslint-disable */
const Filters = ({ filters, setFilters, handlePageChange, handleFilter }) => {
  const auth = useSelector((state) => state.session.auth);

  const [services, setServices] = useState([]);

  const [addOnServices, setAddOnServices] = useState([]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setFilters((prev) => {
      return {
        ...prev,
        [name]: value
      };
    });
  };

  const dispatch = useDispatch();

  const resetFilter = () => {
    setFilters({
      ready_date_from: '',
      ready_date_to: '',
      origin: {
        country: '',
        state: '',
        city: '',
        port: ''
      },
      destination: {
        country: '',
        state: '',
        city: '',
        port: ''
      },
      request_id: '',
      loads: [],
      transportation_mode: [],
      services: [],
      carriers: []
    });
    handlePageChange('', 1);

    dispatch(setFilterDataInRedux(null));
  };

  return (
    <div className={`${Style.container}`}>
      <div className="d-flex align-items-center">
        <FilterAltOutlinedIcon />
        <span className="font-weight-bold font-size-lg">Filters</span>
      </div>
      <div style={{ margin: '0 0 -10px 0' }} className="divider" />
      {/* <StyledInput
        id="standard-basic"
        name="request_id"
        label="Request Id"
        value={filters?.request_id}
        onChange={handleChange}
      /> */}

      <TextField
        id="standard-basic"
        name="request_id"
        label="Request Id"
        value={filters?.request_id}
        onChange={handleChange}
      />

      <div className={`${Style.flex_col}`} style={{ gap: '1rem' }}>
        <span className="font-size-md font-weight-bold">Shipping Route</span>
        <ShippingRouteBox
          services={services}
          filters={filters}
          setFilters={setFilters}
          handlePageChange={handlePageChange}
          from=""
          to=""
        />
      </div>

      {/* <div className={Style.flex_col}>
                <span className={Style.label_head}> TYPE OF SHIPMENTS</span>
                <div>
                    <Checkbox id="all-filter" />
                    <label htmlFor="all-filter" className="c-font-title c-font-light">All</label>
                </div>

            </div> */}

      <span className="font-size-md font-weight-bold">
        Select Transporation Method
      </span>
      {TRANSPORT_MODES_LIST &&
        TRANSPORT_MODES_LIST.map((item, index) => (
          <DropdownOptions
            filters={filters}
            setFilters={setFilters}
            id={`basic-${index}`}
            header={item.label}
            iconSrc={`${item.label.toLowerCase()}`}
            options={fetchCargoCategories(item)}
          />
        ))}


      {/* <Button
        className="btn-link btn-link-success font-weight-bold p-0"
        // className={Style.resetButton}
        onClick={handleFilter}>
        Apply Filters
      </Button>

      <Button
        className="btn-link btn-link-danger font-weight-bold p-0"
        // className={Style.resetButton}
        onClick={resetFilter}>
        Reset Filters
      </Button> */}

      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <Button
          className="btn-link btn-link-primary p-0"
          onClick={handleFilter}>
          Apply Filters
        </Button>
        <Button
          className="btn-link btn-link-danger p-0"
          onClick={resetFilter}>
          Reset Filters
        </Button>
      </div>
      {/* 
            <TextField
                id="standard-basic"
                label="Request Id"
                variant="standard"
            />

            <div className={`${Style.flex_col}`} style={{ gap: '1rem' }}>
                <span className={Style.label_head}>Shipping Route</span>
                <ShippingRouteBox from="" to="" />
            </div>

            <div className={Style.flex_col}>
                <span className={Style.label_head}> TYPE OF SHIPMENTS</span>
                <div>
                    <Checkbox id="all-filter" />
                    <label htmlFor="all-filter" className="c-font-title c-font-light">All</label>
                </div>

            </div>



            <DropdownOptions
                header="Sea"
                iconSrc="/assets/forwarder/shipping_leads/sea.svg"
                options={["FCL", "LCL", "BULK"]}
            />
            <DropdownOptions
                header="Land"
                iconSrc="\assets\forwarder\shipping_leads\land.svg"
                options={["FCL", "FTL / LTL", "RAIL"]}
            />
            <DropdownOptions
                iconSrc="\assets\forwarder\shipping_leads\air.svg"
                header="Air"
                options={["PARCELS", "STANDARD CARGO", "ULD CONTAINER", "PROJECT CARGO"]}
            /> */}
    </div>
  );
};
/* eslint-enable */
export default Filters;
