import { connect } from 'react-redux';
import Footer from './Footer';

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
