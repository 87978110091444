import React from 'react'
import Style from "./ShipmentCard.module.css";
import ShipmentIcon from 'sites/shipper/pages/Shipments/Reusables/ShipmentIcon/ShipmentIcon';
import FromTo from 'sites/shipper/pages/Dashboard/RecentShipments/ShipmentSummaryCard/FromTo/FromTo';
import { Divider } from '@material-ui/core';


const dummyObj = {
    "label": "Hopper Wagon",
    "value": 304,
    "mode": {
        "label": "RAIL",
        "value": "RAIL",
        "icon": "train"
    },
    "icon": "/static/media/icon-rail-hopper-wagon.4dc7676051f4759bd4c0a9a993e210bf.svg",
    "category": {
        "label": "RAIL",
        "title": "Full Container Load",
        "value": "FRL",
        "icon": "/static/media/icon-rail-container-platform.39ab399d417481e4b177effbcd204a99.svg",
        "mode": {
            "label": "RAIL",
            "value": "RAIL",
            "icon": "train"
        }
    }
}

const ShipmentCard = () => {
    return (
        <div className={Style.container}>
            <div className={Style.top_header}>
                <div className={Style.detail_container}>
                    <span className={`${Style.request_id}`}>Request ID</span>
                    <span className={`${Style.request_id_value}`}># 0983638912</span>
                    <span className={`${Style.last_updated}`}>Last Updated</span>
                    <span className={`${Style.last_update_date}`}>12/03/2023</span>
                </div>
                <div className={Style.status}>
                    Transhipment Loaded
                </div>
            </div>
            <Divider />
            <div className={Style.bottom}>

                <div>
                    <FromTo from='Indore' to='Bhopal' currentStep={0} />
                </div>

                <ShipmentIcon
                    type={dummyObj}
                    count={100}
                />
            </div>
        </div>
    )
}

export default ShipmentCard