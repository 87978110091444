import React from 'react'
import PageContent from 'sites/shipper/components/layout/PageContent';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import Career from './Career/Career';

const Careers = () => {
  return (
    <>
      <PageTitle titleHeading={`Career Managment`}></PageTitle>
      <PageContent>
       
       <Career/>
      </PageContent>
    </>
  );
}

export default Careers