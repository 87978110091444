import { getBaseUrl } from 'config/config';
import { HttpMethods, httpService } from './global';

export default class TicketSystemService {
    static async fetchAssigneeList(token) {
        const url = `ticket-system/a/fetch-assignee-list`;
        return httpService(HttpMethods.GET, url, null, {
            token,
            type: "team",
            method: "read",
            platform: "Admin"
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }

    static async fetchAssignedTicket(data,token) {
        const url = `ticket-system/a/fetch-assigned-tickets`;
        return httpService(HttpMethods.POST, url, data, {
            token,
            type: "ticket_system",
            method: "read",
            platform: "Admin"
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }

    static async fetchTicketDetails(id, token) {
        const url = `ticket-system/a/fetch-ticket-details?id=${id}`;
        return httpService(HttpMethods.GET, url, null, {
            token,
            type: "ticket_system",
            method: "edit",
            platform: "Admin"
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }

    static async completeTransactionPayoutTicket(data, token) {
        const url = `ticket-system/a/ticket/transaction-payout`;
        return httpService(HttpMethods.POST, url, data, {
            token,
            type: "ticket_system",
            method: "edit",
            platform: "Admin"
        })
            .then((res) => {
                return res;
            })
            .catch((err) => console.log(err));
    }
}
