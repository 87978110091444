import React from 'react'
import Style from "./ProfilePopup.module.css";
import UserProfileBox from './UserProfileBox/UserProfileBox';
import ProfileActivityBox from './DetailBox/ProfileActivityBox';
import ProfileActionBox from './ProfileActionBox/ProfileActionBox';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ModalSimple from 'components/ModalSimple';
import ProfileRoleManagement from './ProfileRoleManagement/ProfileRoleManagement';
const ProfilePopup = ({ profileData, onClose, open, setRefresh, refresh }) => {

 
  return (
    <>
      <ModalSimple
        modalTitle={'Team Member Profile'}
        open={open}
        maxWidth={'xl'}
        fullWidth
        handleClose={onClose}>
        <div className={Style.mainContainer}>
          <div className={Style.UserProfileBox}>
            <UserProfileBox profileData={profileData} />
          </div>
          <div className={Style.ProfileActionBox}>
            {/* <ProfileActionBox profileData={profileData} refresh={refresh} setRefresh={setRefresh} /> */}

            <ProfileRoleManagement profileData={profileData} refresh={refresh} setRefresh={setRefresh} />
          </div>
          <div className={Style.ProfileActivityBox}>
            <ProfileActivityBox
              profileData={profileData}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </div>
        </div>
      </ModalSimple>
    </>
  );
}

export default ProfilePopup